import {isFeatureEnabled} from 'feature-flags';
import {Box, Center, Icon, ThemeColorPath} from 'platform/foundation';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {featureFlags} from '@omnetic-dms/feature-flags';

import {suffixTestId, TestIdProps} from 'shared';

interface DevSettingsHeaderButtonProps extends TestIdProps {
  isOpen: boolean;
  isAnyDevSettingEnabled: boolean;
}

export function DevSettingsHeaderButton(props: DevSettingsHeaderButtonProps) {
  if (isFeatureEnabled(featureFlags.SIDEBAR)) {
    return (
      <Center height={12}>
        <StyledDevSettingsHeaderButton
          $rounded
          $size="small"
          $isOpen={props.isOpen}
          data-testid={suffixTestId('devSettings-button', props)}
        >
          <Center height="100%">
            <Box
              width={6}
              height={6}
              backgroundColor={match<[boolean, boolean], ThemeColorPath>([
                props.isAnyDevSettingEnabled,
                props.isOpen,
              ])
                .with([false, false], () => 'palettes.yellow.30.100')
                .with([false, true], () => 'palettes.yellow.50.100')
                .with([true, false], () => 'palettes.red.30.100')
                .with([true, true], () => 'palettes.red.40.100')
                .exhaustive()}
              borderRadius="circular"
              data-testid={suffixTestId('devSettings-button-icon', props)}
            >
              <Center height="100%">
                <Icon size={3} value="action/build" cursor="pointer" />
              </Center>
            </Box>
          </Center>
        </StyledDevSettingsHeaderButton>
      </Center>
    );
  }

  return (
    <StyledDevSettingsHeaderButton
      $isOpen={props.isOpen}
      data-testid={suffixTestId('devSettings-button', props)}
    >
      <Center height="100%">
        <Box
          width={6}
          height={6}
          backgroundColor={
            props.isAnyDevSettingEnabled ? 'palettes.red.30.100' : 'palettes.yellow.30.100'
          }
          borderRadius="circular"
          data-testid={suffixTestId('devSettings-button-icon', props)}
        >
          <Center height="100%">
            <Icon size={3} value="action/build" cursor="pointer" />
          </Center>
        </Box>
      </Center>
    </StyledDevSettingsHeaderButton>
  );
}

const StyledDevSettingsHeaderButton = styled.div<{
  $isOpen: boolean;
  $size?: 'small' | 'default';
  $rounded?: boolean;
}>`
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  height: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  width: ${({theme, $size}) => theme.getSize($size === 'small' ? 6 : 12)};
  border-radius: ${({$rounded}) => ($rounded ? '50%' : '0')};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
  cursor: pointer;
`;
