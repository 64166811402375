import {Dropdown} from 'platform/components';
import {Box, Center, Heading, Hide, Icon, Scroll, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {omneticReleaseNotes} from '@omnetic-dms/releaseNotes';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {HeaderButtonWrapper} from '../../../HeaderButtonWrapper/HeaderButtonWrapper';
import {Release} from './Release';

interface ReleasePopupProps extends TestIdProps {}

export function ReleasePopup(props: ReleasePopupProps) {
  const [isOpen, setOpen, setClose] = useBoolean();

  return (
    <Dropdown
      onOpen={setOpen}
      onClose={setClose}
      closeOnBlur
      data-testid={suffixTestId('releasePopupDropdown', props)}
      dropdownControl={
        <ReleasePopupDropdownControl data-testid={props['data-testid']} isOpen={isOpen} />
      }
      placement="bottom-end"
      isLazy
      lazyBehavior="unmount"
    >
      <Box data-testid={suffixTestId('releasePopup', props)}>
        <Scroll width={120} maxWidth={200} maxHeight="85vh" auto>
          <VStack data-testid={suffixTestId('releasePopupInnerBox', props)}>
            <Box padding={2}>
              <Center justify="flex-start" minHeight={8}>
                <Heading size={4}>{i18n.t('general.labels.releaseNotesTitle')}</Heading>
              </Center>
            </Box>
            <VStack spacing={1}>
              {omneticReleaseNotes.map((release, index) => (
                <Hide whenFeatureDisabled={release.featureName} key={`release-[${release.url}]`}>
                  <Release
                    data-testid={`release-[${index}]`}
                    type={release.type}
                    title={release.title}
                    description={release.description}
                    dateTime={release.dateTime}
                    url={release.url}
                  />
                </Hide>
              ))}
            </VStack>
          </VStack>
        </Scroll>
      </Box>
    </Dropdown>
  );
}

interface ReleasePopupDropdownControlProps extends TestIdProps {
  isOpen: boolean;
}

const ReleasePopupDropdownControl = (props: ReleasePopupDropdownControlProps) => (
  <Center height={12}>
    <HeaderButtonWrapper
      $rounded
      $size="small"
      $isOpen={props.isOpen}
      data-testid={suffixTestId('releasePopup-button', props)}
    >
      <Box height={6} width={6} position="relative">
        <Center width="100%" height="100%">
          <Icon value="action/grade" size={4} />
        </Center>
      </Box>
    </HeaderButtonWrapper>
  </Center>
);
