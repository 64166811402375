import {
  Card,
  CustomRequestOption,
  DataStatus,
  DropdownItem,
  GridPhoto,
  isFile,
  openDeleteDialog,
  openDropdown,
  PhotoGrid,
  UploadState,
} from 'platform/components';
import {Show, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {MouseEvent, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {descend, isNil} from 'ramda';

import {
  PartialVehiclePhotosRoutes,
  useAddFilesToVehicleAlbumMutation,
  useDeletePhotoMutation,
  useGetAuditDataQuery,
  useGetMetadaServiceCaseQuery,
  useGetServiceCheckInQuery,
  useGetServiceHandOverQuery,
  useGetVehicleQuery,
  useUploadFileMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  getGridPhotos,
  getGridPhotosFromAudit,
  handleApiError,
  Main,
  useBatchDownload,
  workshopSearchParams,
} from '@omnetic-dms/shared';

import {Nullish, useBoolean} from 'shared';

import {HANDOVER_SECTION} from '../../../../constants/detailSections';
import {useWorkshopUrl} from '../../../../hooks/useWorkshopUrl';
import {HistoryPhotosCard} from './components/HistoryPhotosCard';
import {useHistoryAlbumPhotos} from './hooks/useHistoryAlbumPhotos';

const SC_ALBUM_NAME = 'PHOTOS_SERVICE_CASE';

export function Photos() {
  const {serviceCaseId} = useWorkshopUrl();
  const [, setSearchParams] = useSearchParams();
  const [isBulkSelectActive, , setBulkSelectFalse, toggleBulkSelect] = useBoolean();

  const {handleDownload} = useBatchDownload();

  const {
    data: serviceCase,
    isLoading: isServiceCaseLoading,
    isError: isServiceCaseError,
  } = useGetMetadaServiceCaseQuery({serviceCaseId});
  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery(
    {vehicleId: serviceCase?.vehicleId || ''},
    {skip: !serviceCase?.vehicleId, refetchOnMountOrArgChange: true}
  );
  const {
    data: checkin,
    isLoading: isCheckinLoading,
    isError: isCheckinError,
  } = useGetServiceCheckInQuery({
    serviceCaseId,
  });
  const {
    data: handover,
    isLoading: isHandoverLoading,
    isError: isHandoverError,
  } = useGetServiceHandOverQuery({
    serviceCaseId,
  });
  const {
    data: checkinAudit,
    isLoading: isCheckinAuditLoading,
    isError: isCheckinAuditError,
  } = useGetAuditDataQuery(
    {
      auditId: checkin?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(checkin?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: handoverAudit,
    isLoading: isHandoverAuditLoading,
    isError: isHandoverAuditError,
  } = useGetAuditDataQuery(
    {
      auditId: handover?.inspectionId?.[0] ?? '',
    },
    {
      skip: isNil(handover?.inspectionId?.[0]),
      refetchOnMountOrArgChange: true,
    }
  );

  const [addFilesToVehicleAlbum] = useAddFilesToVehicleAlbumMutation();
  const [uploadFile, {isLoading: isFileUploading}] = useUploadFileMutation();
  const [deletePhoto] = useDeletePhotoMutation();

  const serviceCaseAlbum = vehicle?.albums?.find(
    (album) => album.name === SC_ALBUM_NAME && album.serviceCaseId === serviceCaseId
  );
  const historyAlbums = useMemo(
    () =>
      vehicle?.albums
        ?.filter((album) => album.name === SC_ALBUM_NAME && album.serviceCaseId !== serviceCaseId)
        .sort(descend((album) => album.createdAt)),
    [serviceCaseId, vehicle?.albums]
  );

  const {isHistoryPhotosLoading, historyAlbumPhotosCount} = useHistoryAlbumPhotos(historyAlbums);

  const handleUpload = ({file}: CustomRequestOption) => {
    if (!isFile(file)) {
      return;
    }

    uploadFile({file})
      .unwrap()
      .then(({fileId}) => {
        addFilesToVehicleAlbum({
          vehicleAlbumId: serviceCaseAlbum?.id || '',
          vehicleId: serviceCase?.vehicleId || '',
          addFilesToVehicleAlbumRequestBody: {
            fileIds: [fileId],
          },
        })
          .unwrap()
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  const handleContextMenu = (event: MouseEvent, photos: GridPhoto[]) =>
    openDropdown(
      event,
      <>
        <DropdownItem
          label={i18n.t('general.actions.delete')}
          onClick={() =>
            openDeleteDialog({
              onConfirm: () =>
                deletePhoto({
                  albumId: serviceCaseAlbum?.id ?? '',
                  vehicleId: serviceCase?.vehicleId ?? '',
                  vehiclePhotoDeleteRequestBody: {
                    photos: photos.map((photo) => ({
                      fileId: photo.id,
                    })),
                  },
                })
                  .unwrap()
                  .then(() => setBulkSelectFalse())
                  .catch(handleApiError),
              'data-testid': testIds.workshop.serviceCaseDetail('deletePhoto'),
            })
          }
          data-testid={testIds.workshop.serviceCaseDetail('deletePhoto')}
        />
      </>
    );

  const handleVehicleGalleryBatchDownload = (albumId?: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.vehicle,
      vehicleId: serviceCase?.vehicleId ?? '',
      albumId,
    });

  const handleInspectionGalleryBatchDownload = (auditId?: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.inspections,
      vehicleId: serviceCase?.vehicleId ?? '',
      auditId,
    });

  const vehiclePhotos = getGridPhotos(serviceCaseAlbum?.id)(vehicle?.photos);
  const checkinPhotos = getGridPhotosFromAudit(checkinAudit?.[0]?.categories);
  const handoverPhotos = getGridPhotosFromAudit(handoverAudit?.[0]?.categories);

  const [vehicleLightboxControls, {onOpen: onVehiclePhotoOpen}] = useLightbox('vehiclePhotos');
  const [checkinPhotosLightboxControls, {onOpen: onCheckinPhotoOpen}] =
    useLightbox('checkinPhotos');
  const [handoverPhotosLightboxControls, {onOpen: onHandoverPhotoOpen}] =
    useLightbox('handoverPhotos');

  const historyPhotosCount =
    (vehicle?.photos?.filter((photo) => historyAlbums?.some((album) => album.id === photo.albumId))
      .length ?? 0) + historyAlbumPhotosCount;

  const isServiceCaseVehicleLoading = isServiceCaseLoading || isVehicleLoading;
  const isServiceCaseVehicleError = isServiceCaseError || isVehicleError;

  const isCheckinPhotosLoading =
    isServiceCaseVehicleLoading || isCheckinLoading || isCheckinAuditLoading;
  const isCheckinPhotosError = isServiceCaseVehicleError || isCheckinError || isCheckinAuditError;

  const isHandoverPhotosLoading =
    isServiceCaseVehicleLoading || isHandoverLoading || isHandoverAuditLoading;
  const isHandoverPhotosError =
    isServiceCaseVehicleError || isHandoverError || isHandoverAuditError;

  return (
    <Main>
      <VStack spacing={4}>
        <Card
          title={i18n.t('entity.photo.labels.photos')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleVehicleGalleryBatchDownload(serviceCaseAlbum?.id);
              },
              isDisabled: !vehiclePhotos.length,
            },
            {
              type: 'button',
              variant: 'link',
              title: isBulkSelectActive
                ? i18n.t('general.actions.cancel')
                : i18n.t('general.actions.bulkActions'),
              leftIcon: 'navigation/menu',
              onClick: toggleBulkSelect,
              isDisabled: !vehiclePhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
        >
          <DataStatus
            isLoading={isServiceCaseVehicleLoading}
            isError={isServiceCaseVehicleError}
            minHeight={43}
          >
            <PhotoGrid
              uploadProps={{
                customRequest: handleUpload,
                uploadState: isFileUploading ? UploadState.Uploading : UploadState.Idle,
                isMultiple: true,
              }}
              images={vehiclePhotos}
              onContextMenu={handleContextMenu}
              isBulkSelectActive={isBulkSelectActive}
              onSlideClick={(_, index) => onVehiclePhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('serviceCasePhotos')}
              controls={vehicleLightboxControls}
              images={makeLightboxPhotos(vehiclePhotos)}
            />
          </DataStatus>
        </Card>
        <Card
          title={i18n.t('entity.workshop.labels.checkIn')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('entity.workshop.actions.openCheckin'),
              leftIcon: 'image/remove_red_eye',
              onClick: () => {
                setSearchParams(
                  {section: workshopSearchParams.serviceDetail.checkinHandover},
                  {replace: true}
                );
              },
            },
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleInspectionGalleryBatchDownload(checkinAudit?.[0]?.id);
              },
              isDisabled: !checkinPhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
        >
          <DataStatus
            isLoading={isCheckinPhotosLoading}
            isError={isCheckinPhotosError}
            minHeight={24}
          >
            <PhotoGrid
              images={checkinPhotos}
              onSlideClick={(_, index) => onCheckinPhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('checkinPhotos')}
              controls={checkinPhotosLightboxControls}
              images={makeLightboxPhotos(checkinPhotos)}
            />
          </DataStatus>
        </Card>
        <Card
          title={i18n.t('entity.workshop.labels.handover')}
          actions={[
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('entity.workshop.actions.openHandover'),
              leftIcon: 'image/remove_red_eye',
              onClick: () => {
                setSearchParams(
                  {
                    section: workshopSearchParams.serviceDetail.checkinHandover,
                    checkinHandover: HANDOVER_SECTION,
                  },
                  {replace: true}
                );
              },
            },
            {
              type: 'button',
              variant: 'link',
              title: i18n.t('general.actions.downloadAll'),
              leftIcon: 'file/download',
              onClick: () => {
                handleInspectionGalleryBatchDownload(handoverAudit?.[0]?.id);
              },
              isDisabled: !handoverPhotos.length,
            },
          ]}
          data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
        >
          <DataStatus
            isLoading={isHandoverPhotosLoading}
            isError={isHandoverPhotosError}
            minHeight={24}
          >
            <PhotoGrid
              images={handoverPhotos}
              onSlideClick={(_, index) => onHandoverPhotoOpen(index)}
              data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
            />
            <Lightbox
              data-testid={testIds.workshop.serviceCaseDetail('handoverPhotos')}
              controls={handoverPhotosLightboxControls}
              images={makeLightboxPhotos(handoverPhotos)}
            />
          </DataStatus>
        </Card>
        <Show when={!isHistoryPhotosLoading && historyPhotosCount > 0}>
          <Card
            title={i18n.t('entity.photo.labels.photosHistory')}
            flags={[
              {
                label: historyPhotosCount.toString(),
                colorScheme: 'blue',
                isSubtle: true,
              },
            ]}
            isExpandable
            data-testid={testIds.workshop.serviceCaseDetail('historyPhotos')}
          >
            <DataStatus isLoading={isServiceCaseVehicleLoading} isError={isServiceCaseVehicleError}>
              <VStack spacing={4}>
                {historyAlbums?.map((album, index) => (
                  <HistoryPhotosCard
                    key={album.id}
                    photos={getGridPhotos(album.id)(vehicle?.photos)}
                    serviceCaseId={album.serviceCaseId}
                    onDownloadAlbum={() => handleVehicleGalleryBatchDownload(album.id)}
                    onDownloadInspection={handleInspectionGalleryBatchDownload}
                    data-testid={testIds.workshop.serviceCaseDetail(`historyPhotos-${index}`)}
                  />
                ))}
              </VStack>
            </DataStatus>
          </Card>
        </Show>
      </VStack>
    </Main>
  );
}

const makeLightboxPhotos = (photos: GridPhoto[] | Nullish) =>
  photos?.map((image) => ({
    id: image.id,
    url: image.originalUrl?.replace('resize', 'get') ?? image.url.replace('resize', 'get'),
    resizeUrl: image.resizeUrl,
    title: image.title,
    fileName: image.title || image.id ? `${image.title || image.id}.jpg` : undefined,
  }));
