import {ExcludeBranchesFromScope, RequestedValuesByScope, ScopeKeys} from '@omnetic-dms/api';

import {Either, Nullish} from 'shared';

import {accountingPermissions} from './permissionsDefinitions/accountingPermissions';
import {businessCasePermissions} from './permissionsDefinitions/businessCasePermissions';
import {carAuditPermissions} from './permissionsDefinitions/carAuditPermissions';
import {complaintPermissions} from './permissionsDefinitions/complaintPermissions';
import {corePermissions} from './permissionsDefinitions/corePermissions';
import {customerPermissions} from './permissionsDefinitions/customerPermissions';
import {dataGridPermissions} from './permissionsDefinitions/dataGridPermissions';
import {employeePermissions} from './permissionsDefinitions/employeePermissions';
import {interestPermissions} from './permissionsDefinitions/interestPermissions';
import {serviceCasePermissions} from './permissionsDefinitions/serviceCasePermissions';
import {settingsPermissions} from './permissionsDefinitions/settingsPermissions';
import {sourcingPermissions} from './permissionsDefinitions/sourcingPermissions';
import {vehiclePermissions} from './permissionsDefinitions/vehiclePermissions';
import {warehousePermissions} from './permissionsDefinitions/warehousePermissions';
import {workshopPermissions} from './permissionsDefinitions/workshopPermissions';

type PartialOrNullish<T> = {[P in keyof T]?: T[P] | Nullish};
type PermissionScopes<T extends PermissionsKeys[]> = Exclude<
  {
    [K in T[number]]: ExcludeBranchesFromScope<(typeof permissions)[K]['scopes'][number]>;
  },
  never
>;

type FilteredPermissionScopes<T> = {
  [K in keyof T as T[K] extends never ? never : K]: T[K];
};

type PermissionScopesArguments<T extends Partial<Record<PermissionsKeys, ScopeKeys>>> = {
  [K in keyof T as T[K] extends never ? never : K]: PartialOrNullish<
    RequestedValuesByScope[T[K] extends ScopeKeys ? T[K] : never]
  >;
};

export type Scopes<T extends PermissionsKeys[]> =
  FilteredPermissionScopes<PermissionScopes<T>> extends Record<string, never>
    ? {scopes?: never}
    : {scopes: PermissionScopesArguments<FilteredPermissionScopes<PermissionScopes<T>>>};

export type PermissionsRecordType = Record<
  string,
  {resourceId: string; scopes: ScopeKeys[]} & Either<{actionId: string}, {fieldId: string}>
>;
export type PermissionsKeys = keyof typeof permissions;

export const permissions = {
  ...corePermissions,
  ...accountingPermissions,
  ...businessCasePermissions,
  ...complaintPermissions,
  ...customerPermissions,
  ...dataGridPermissions,
  ...interestPermissions,
  ...settingsPermissions,
  ...vehiclePermissions,
  ...carAuditPermissions,
  ...warehousePermissions,
  ...employeePermissions,
  ...serviceCasePermissions,
  ...sourcingPermissions,
  ...workshopPermissions,
};
