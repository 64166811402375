import {Attributes, AttributesRow, Card, DataStatus} from 'platform/components';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  GetReceiveNoteItemNewResponse,
  GetReceiveNoteItemRefreshedResponse,
  GetReceiveNoteItemResponse,
  useGetManufacturerQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';

interface DeliveryItemDetailCardProps extends TestIdProps {
  manufacturerNumber: string | Nullish;
  deliveryNoteQuantity: number | Nullish;
  handlingUnit: string | Nullish;
  receiveNoteItem: GetReceiveNoteItemResponse | Nullish;
  receiveNoteItemNew: GetReceiveNoteItemNewResponse | Nullish;
  receiveNoteItemRefreshed: GetReceiveNoteItemRefreshedResponse | Nullish;
  isDisabled: boolean;
  isExpanded: boolean;
}

export function DeliveryItemDetailCard(props: DeliveryItemDetailCardProps) {
  const manufacturerId =
    props.receiveNoteItemNew?.deliveryNoteItem?.manufacturerId ??
    props.receiveNoteItem?.deliveryNoteItem?.manufacturerId ??
    props.receiveNoteItemRefreshed?.deliveryNoteItem?.manufacturerId;

  const supplierNumber =
    props.receiveNoteItemNew?.deliveryNoteItem?.supplierNumber ??
    props.receiveNoteItem?.deliveryNoteItem?.supplierNumber ??
    props.receiveNoteItemRefreshed?.deliveryNoteItem?.supplierNumber;

  const name =
    props.receiveNoteItemNew?.deliveryNoteItem?.name ??
    props.receiveNoteItem?.deliveryNoteItem?.name ??
    props.receiveNoteItemRefreshed?.deliveryNoteItem?.name;

  const description =
    props.receiveNoteItemNew?.deliveryNoteItem?.description ??
    props.receiveNoteItem?.deliveryNoteItem?.description ??
    props.receiveNoteItemRefreshed?.deliveryNoteItem?.description;

  const deliveryNoteQuantity = `${props.deliveryNoteQuantity ?? 0} ${props.handlingUnit?.toLowerCase() ?? FALLBACK_HANDLING_UNIT.toLowerCase()}`;

  const {data: manufacturer, isLoading: isManufacturerLoading} = useGetManufacturerQuery(
    {manufacturerId},
    {skip: isNilOrEmpty(manufacturerId)}
  );

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: props.manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: manufacturer?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.deliveryNoteQuantity'),
      value: deliveryNoteQuantity,
    },
    {
      label: i18n.t('entity.warehouse.labels.supplierNumber'),
      value: supplierNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: name,
    },
    {
      label: i18n.t('entity.warehouse.labels.description'),
      value: description,
    },
  ];

  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.deliveryItemDetail')}
      isClosedByDefault={props.isDisabled}
      isExpandable={props.isExpanded}
      data-testid={props['data-testid']}
    >
      <DataStatus isLoading={isManufacturerLoading} minHeight={45}>
        <Attributes rows={rows} data-testid={suffixTestId('attributes', props)} />
      </DataStatus>
    </Card>
  );
}
