import {Button, ButtonGroup} from 'platform/components';
import {Space} from 'platform/foundation';

import {useState} from 'react';

import {MenuItemProps, useOrderUserMenuPinsMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {SortableSidebarItems} from './SortableSidebarItems';

interface SidebarEditProps extends TestIdProps {
  menuItemsById: Record<string, MenuItemProps>;
  pinnedMenuItems: string[];
  onSave: () => void;
  onCancel: () => void;
}

export function SidebarEdit(props: SidebarEditProps) {
  const [orderMenuPins] = useOrderUserMenuPinsMutation();
  const [menuItems, setMenuItems] = useState(
    props.pinnedMenuItems.map((item) => ({id: item, isPinned: true}))
  );

  const handleOnSave = () => {
    orderMenuPins({orderedItems: menuItems.map((item) => item.id)})
      .unwrap()
      .catch(handleApiError);

    props.onSave();
    // TODO: handle unpinning once API is ready https://carvago.atlassian.net/browse/T20-60764
  };

  return (
    <>
      <SortableSidebarItems
        menuItems={menuItems}
        menuItemsById={props.menuItemsById}
        onChange={setMenuItems}
        data-testid={suffixTestId('sortableSidebarItems', props)}
      />
      <Space vertical={4} />
      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={i18n.t('general.actions.cancel')}
          onClick={props.onCancel}
          data-testid={suffixTestId('cancelButton', props)}
        />
        <Button
          title={i18n.t('general.actions.save')}
          onClick={handleOnSave}
          data-testid={suffixTestId('saveButton', props)}
        />
      </ButtonGroup>
    </>
  );
}
