import styled from 'styled-components';

import {ComponentProps, forwardRef} from 'react';

import {TestIdProps} from 'shared';

interface SidebarButtonProps extends ComponentProps<'button'>, TestIdProps {
  isActive: boolean;
}

export const SidebarButton = forwardRef<HTMLButtonElement, SidebarButtonProps>(
  ({children, isActive, ...props}, ref) => (
    <StyledButton ref={ref} {...props}>
      <StyledButtonContent $isActive={isActive}>{children}</StyledButtonContent>
    </StyledButton>
  )
);

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > * {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;

const StyledButtonContent = styled.span<{$isActive: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({theme}) => theme.getSize(8)};
  height: ${({theme}) => theme.getSize(8)};
  border-radius: ${({theme}) => theme.getSize(1)};
  background-color: ${(props) =>
    props.$isActive
      ? props.theme.colors.palettes.neutral[500]['100']
      : props.theme.colors.palettes.neutral[900]['100']};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
`;
