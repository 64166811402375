import {isBefore} from 'date-fns';
import {
  Card,
  DataStatus,
  Flag,
  Table,
  TableRow,
  TableRowAction,
  openDeleteDialog,
  openDialog,
} from 'platform/components';
import {Box, Icon, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {
  ConsentChannel,
  ConsentResponseBody,
  ConsentStatus,
  useAgreeConsentMutation,
  useDeleteConsentMutation,
  useGetConsentListQuery,
  useGetCustomerV2Query,
  useRejectConsentMutation,
  useWithdrawConsentMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, parseDate} from 'shared';

import {handleApiError} from '../../utils/handleApiError';
import {AddConsentForm} from './components/AddConsentForm';
import {ConsentsTableHeader} from './components/ConsentsTableHeader';

interface GDPRConsentsProps {
  customerId: string;
  businessCaseId?: string;
}

export function GdprConsentsList(props: GDPRConsentsProps) {
  return (
    <>
      <Card
        title={i18n.t('entity.gdpr.labels.consents')}
        actions={[
          {
            type: 'button',
            variant: 'link',
            isDisabled: !props.customerId,
            onClick: () =>
              openDialog(
                <AddConsentForm
                  businessCaseId={props.businessCaseId ?? null}
                  customerId={props.customerId}
                />,
                {
                  title: i18n.t('entity.gdpr.labels.addConsent'),
                  scrollBehavior: 'outside',
                }
              ),
            leftIcon: 'content/add_circle',
            title: i18n.t('entity.gdpr.actions.addNewConsent'),
            'data-testid': 'gdpr-consent-create-button',
          },
        ]}
      >
        <GdprConsentsTable businessCaseId={props?.businessCaseId} customerId={props?.customerId} />
      </Card>
    </>
  );
}

function GdprConsentsTable(props: GDPRConsentsProps) {
  const {isLoading: isCustomerLoading, isError: isCustomerErrored} = useGetCustomerV2Query({
    customerId: props.customerId,
  });
  const {
    data: consents,
    isLoading,
    isError,
  } = useGetConsentListQuery({customerId: props.customerId ?? ''});

  const [deleteConsent] = useDeleteConsentMutation();
  const [withdrawConsent] = useWithdrawConsentMutation();
  const [rejectConsent] = useRejectConsentMutation();
  const [agreeConsent] = useAgreeConsentMutation();

  const formatDateTime = useDateTimeFormatter();

  return (
    <DataStatus
      spacing={4}
      isEmpty={!consents?.length}
      isLoading={isLoading || isCustomerLoading}
      isError={isError || isCustomerErrored}
    >
      <Table data-testid="gdpr-consents-list-table" columns={columns}>
        {(consents ?? []).map((consent: ConsentResponseBody, index) => {
          const options = {consentId: consent.id, customerId: props.customerId};

          return (
            <TableRow
              data-testid={`gdpr-consent-row-${index}`}
              key={consent.id}
              actions={{
                secondary: buildArray<TableRowAction>()
                  .when(
                    consent.status === ConsentStatus.AGREED &&
                      (!consent.validUntil || isBefore(new Date(), parseDate(consent.validUntil))),
                    {
                      title: i18n.t('entity.gdpr.actions.markAsWithdrawn'),
                      icon: 'navigation/refresh',
                      onClick: () => withdrawConsent(options).unwrap().catch(handleApiError),
                    }
                  )
                  .when(consent.status === ConsentStatus.AGREED, {
                    title: i18n.t('entity.gdpr.actions.markAsRejected'),
                    icon: 'navigation/close',
                    onClick: () => rejectConsent(options).unwrap().catch(handleApiError),
                  })
                  .when(
                    consent.status === ConsentStatus.WITHDRAWN ||
                      consent.status === ConsentStatus.REJECTED,
                    {
                      title: i18n.t('entity.gdpr.actions.markAsAgreed'),
                      icon: 'action/check_circle',
                      onClick: () => agreeConsent(options).unwrap().catch(handleApiError),
                    }
                  )
                  .add({
                    title: i18n.t('general.actions.download'),
                    icon: 'action/delete',
                    onClick: () => window.open(consent.fileUri),
                  })
                  .add({
                    title: i18n.t('general.actions.delete'),
                    icon: 'file/download',
                    severity: 'danger',
                    onClick: () =>
                      openDeleteDialog({
                        onConfirm: () => deleteConsent(options).unwrap().catch(handleApiError),
                      }),
                  }),
              }}
              hoverColor="general.transparent"
            >
              <Box paddingHorizontal={2} paddingVertical={3}>
                <Text size="small">{consent.name}</Text>
              </Box>
              <Flag
                colorScheme={colors[consent.status]}
                label={i18n.t(`entity.gdpr.labels.constent.status.${consent.status}`)}
                data-testid={`gdpr-consent-status-${index}`}
              />
              <Text size="small">{formatDateTime('dateShort', parseDate(consent.createdAt))}</Text>
              <Text size="small">
                {consent.validUntil && formatDateTime('dateShort', parseDate(consent.validUntil))}
              </Text>
              <Link
                size="small"
                href={consent.fileUri}
                download={consent.fileName}
                title={consent.fileName}
                data-testid={`gdpr-consent-download-link-${index}`}
              />
              <Show when={consent.channels.includes(ConsentChannel.PHONE)}>
                <Icon value="AV/fiber_manual_record" size={4} />
              </Show>
              <Show when={consent.channels.includes(ConsentChannel.SMS)}>
                <Icon value="AV/fiber_manual_record" size={4} />
              </Show>
              <Show when={consent.channels.includes(ConsentChannel.EMAIL)}>
                <Icon value="AV/fiber_manual_record" size={4} />
              </Show>
              <Show when={consent.channels.includes(ConsentChannel.MAIL)}>
                <Icon value="AV/fiber_manual_record" size={4} />
              </Show>
            </TableRow>
          );
        })}
      </Table>
    </DataStatus>
  );
}

const colors = {
  [ConsentStatus.AGREED]: 'green',
  [ConsentStatus.EXPIRED]: 'orange',
  [ConsentStatus.REJECTED]: 'red',
  [ConsentStatus.WITHDRAWN]: 'yellow',
} as const;

const columns = [
  {
    element: (
      <Box paddingLeft={2}>
        <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.name')} />
      </Box>
    ),
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.status')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.created')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.validUntil')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('entity.gdpr.labels.consent.document')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.phone')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.sms')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.email')} />,
  },
  {
    element: <ConsentsTableHeader text={i18n.t('general.labels.mail')} />,
  },
];
