import {
  ButtonGroup,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, Heading, HStack, Space, VStack} from 'platform/foundation';
import {boolean, object} from 'yup';

import {
  PatchServiceOrderApiArg,
  PatchServiceOrderCommercialApiArg,
  useGetServiceOrderCommercialQuery,
  useGetServiceOrderQuery,
  usePatchServiceOrderCommercialMutation,
  usePatchServiceOrderMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, PredefinedNotes} from '@omnetic-dms/shared';

import {
  parseDate,
  PartialWithNull,
  suffixTestId,
  TestIdProps,
  yupDate,
  yupNumber,
  yupString,
} from 'shared';

import {useContactPersons} from '../../../../../hooks/useContactPersons';
import {relativeDates} from '../../../constants/relativeDates';
import {useOrderMandatoryFields} from '../hooks/useOrderMandatoryFields';
import {getCompletionAt} from '../utils/getCompletionAt';
import {getDefaultCompletionAtTime} from '../utils/getDefaultCompletionAtTime';
import {hasObjectNonEmptyValue} from '../utils/hasObjectNonEmptyValue';
import {CustomerContractSection} from './CustomerContractSection';
import {DiscountSection} from './DiscountSection';

const sliderTicks = [
  {value: 0, label: 'E'},
  {value: 0.25, label: '1/4'},
  {value: 0.5, label: '1/2'},
  {value: 0.75, label: '3/4'},
  {value: 1, label: 'F'},
];

interface OrderCommercialFormProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  isReadOnly?: boolean;
}

type FormType = PartialWithNull<{
  completionAtDate: Date;
  completionAtTime: string;
  totalPriceEstimated: number;
  note: string;
  approvalNumber: string;
  returnUsedParts: boolean;
  contactPerson: string;
  email?: string;
  phone?: string;
  mileage: number;
  fuelTank: number;
}>;

export function OrderCommercialForm(props: OrderCommercialFormProps) {
  const {
    contactPersons,
    isLoading: isContactPersonsLoading,
    isError: isContactPersonsError,
  } = useContactPersons(props.serviceCaseId);
  const {
    data: serviceOrderCommercial,
    isError: isServiceOrderCommercialError,
    isFetching: isServiceOrderCommercialFetching,
  } = useGetServiceOrderCommercialQuery(props);
  const {
    data: serviceOrder,
    isError: isServiceOrderError,
    isFetching: isServiceOrderFetching,
  } = useGetServiceOrderQuery(props);
  const {
    isFieldRequired,
    isLoading: isServiceOrderVariantMandatoryFieldsLoading,
    isError: isServiceOrderVariantMandatoryFieldsError,
  } = useOrderMandatoryFields(props.serviceOrderId);

  const [patchServiceOrder] = usePatchServiceOrderMutation();
  const [patchServiceOrderCommercial] = usePatchServiceOrderCommercialMutation();

  const onSubmit: FormSubmitHandler<FormType> = async (data) => {
    const phoneNumber = contactPersons
      ?.find((c) => c.value === data.contactPerson)
      ?.phoneNumbers?.find((phone) => phone.value === data.phone)?.phone;

    const serviceOrderData: PatchServiceOrderApiArg['body'] = {
      completionAt: getCompletionAt(data.completionAtDate, data.completionAtTime),
      recipientId: serviceOrder?.recipientId,
      technicanId: serviceOrder?.technicanId,
      totalPriceEstimated: data.totalPriceEstimated,
      note: data.note,
      contactInformation: {
        id: data.contactPerson,
        email: data.email,
        phoneNumber,
      },
      mileage: data.mileage,
      fuelTank: data.fuelTank,
    };

    const orderCommercialData: PatchServiceOrderCommercialApiArg['body'] = {
      approvalNumber: data.approvalNumber,
      returnUsedParts: data.returnUsedParts,
    };

    const patchServiceOrderMutation = hasObjectNonEmptyValue(serviceOrderData)
      ? patchServiceOrder({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.serviceOrderId,
          body: serviceOrderData,
        })
          .unwrap()
          .catch(handleApiError)
      : null;
    const patchServiceOrderCommercialMutation = hasObjectNonEmptyValue(orderCommercialData)
      ? patchServiceOrderCommercial({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.serviceOrderId,
          body: orderCommercialData,
        })
          .unwrap()
          .catch(handleApiError)
      : null;

    await Promise.all([patchServiceOrderMutation, patchServiceOrderCommercialMutation]).then(() =>
      showNotification.success()
    );
  };

  const defaultValues: PartialWithNull<FormType> = {
    completionAtDate: serviceOrder?.completionAt
      ? parseDate(serviceOrder?.completionAt)
      : undefined,
    completionAtTime: getDefaultCompletionAtTime(serviceOrder?.completionAt),
    note: serviceOrder?.note,
    totalPriceEstimated: serviceOrder?.totalPriceEstimated?.amount,
    approvalNumber: serviceOrderCommercial?.approvalNumber,
    returnUsedParts: serviceOrderCommercial?.returnUsedParts,
    contactPerson: serviceOrder?.contactInformation?.id,
    email: serviceOrder?.contactInformation?.email,
    phone: serviceOrder?.contactInformation?.phoneNumber?.number,
    mileage: serviceOrder?.mileage,
    fuelTank: serviceOrder?.fuelTank,
  };

  const isLoading =
    isServiceOrderFetching ||
    isServiceOrderCommercialFetching ||
    isServiceOrderVariantMandatoryFieldsLoading ||
    isContactPersonsLoading;

  const isError =
    isServiceOrderError ||
    isServiceOrderCommercialError ||
    isServiceOrderVariantMandatoryFieldsError ||
    isContactPersonsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={110}>
      <Form<FormType>
        onSubmit={onSubmit}
        schema={formSchema(isFieldRequired)}
        defaultValues={defaultValues}
        shouldWatchForUnsavedChanges
      >
        {(control, formApi) => {
          const contactEmailOptions =
            contactPersons?.find((c) => c.value === formApi.watch('contactPerson'))?.emails || [];
          const contactPhoneOptions =
            contactPersons?.find((c) => c.value === formApi.watch('contactPerson'))?.phoneNumbers ||
            [];

          return (
            <>
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="completionAtDate"
                      type="date"
                      label={i18n.t('entity.order.labels.completionDate')}
                      relativeDates={relativeDates}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('completionAtDate')}
                      data-testid={suffixTestId('completionAtDate', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="completionAtTime"
                      type="time"
                      label={i18n.t('entity.order.labels.completionTime')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('completionAtTime')}
                      data-testid={suffixTestId('completionAtTime', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="totalPriceEstimated"
                      type="number"
                      label={i18n.t('entity.order.labels.estimatedPrice')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('totalPriceEstimated')}
                      data-testid={suffixTestId('totalPriceEstimated', props)}
                    />
                  </Box>
                </HStack>
                <Grid columns={4}>
                  <Box flex={1}>
                    <FormField
                      label={i18n.t('entity.vehicle.labels.actualMileage')}
                      name="mileage"
                      type="number"
                      control={control}
                      minStepperValue={0}
                      data-testid={suffixTestId('mileage', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      label={i18n.t('entity.workshop.labels.fuelTank')}
                      name="fuelTank"
                      type="slider"
                      control={control}
                      max={1}
                      min={0}
                      step={0.25}
                      ticks={sliderTicks}
                      data-testid={suffixTestId('fuelTank', props)}
                    />
                  </Box>
                </Grid>
              </VStack>
              <Separator />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="approvalNumber"
                    type="text"
                    label={i18n.t('entity.order.labels.approvalNumber')}
                    isDisabled={props.isReadOnly}
                    isRequired={isFieldRequired('approvalNumber')}
                    data-testid={suffixTestId('approvalNumber', props)}
                  />
                </Box>
                <Box flex={1}>
                  <Space vertical={6} />
                  <FormField
                    control={control}
                    name="returnUsedParts"
                    type="checkbox"
                    label={i18n.t('entity.order.labels.returnUsedParts')}
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId('returnUsedParts', props)}
                  />
                </Box>
                <Box flexGrow={2} />
              </HStack>
              <Separator />
              <VStack spacing={1}>
                <HStack justify="space-between" align="flex-end">
                  <Label>{i18n.t('general.labels.note')}</Label>
                  <PredefinedNotes
                    note={formApi.watch('note') ?? null}
                    onPrefill={(note) => formApi.setValue('note', note)}
                    resource="SERVICE_CASE"
                    context="service_order"
                    isLinkVariant
                    data-testid={suffixTestId('predefinedNotes', props)}
                  />
                </HStack>
                <FormField
                  control={control}
                  name="note"
                  type="textarea"
                  isDisabled={props.isReadOnly}
                  data-testid={suffixTestId('note', props)}
                />
              </VStack>
              <Separator />
              <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
              <Space vertical={4} />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="contactPerson"
                    type="choice"
                    isNotClearable
                    label={i18n.t('entity.businessCase.labels.contactPerson')}
                    options={contactPersons}
                    isLoading={isContactPersonsLoading}
                    onChange={(value) => {
                      const contactPerson = contactPersons?.find((c) => c.value === value);
                      formApi.setValue('email', contactPerson?.emails?.[0]?.value);
                      formApi.setValue('phone', contactPerson?.phoneNumbers?.[0]?.value);
                    }}
                    data-testid={suffixTestId('contactPerson', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="email"
                    type="choice"
                    isNotClearable
                    label={i18n.t('general.labels.emailAddress')}
                    options={contactEmailOptions}
                    isLoading={isContactPersonsLoading}
                    data-testid={suffixTestId('email', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="phone"
                    type="choice"
                    isNotClearable
                    label={i18n.t('entity.person.labels.phoneNumber')}
                    options={contactPhoneOptions}
                    isLoading={isContactPersonsLoading}
                    data-testid={suffixTestId('phoneNumber', props)}
                  />
                </Box>
                <Box flexGrow={2} />
              </HStack>
              <ButtonGroup align="right">
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.saveChanges')}
                  data-testid={suffixTestId('save', props)}
                />
              </ButtonGroup>
              <Separator />
              <CustomerContractSection
                orderId={props.serviceOrderId}
                serviceCaseId={props.serviceCaseId}
                isReadOnly={props.isReadOnly}
                data-testid={suffixTestId('customerContract', props)}
              />
              <Separator />
              <DiscountSection
                serviceCaseId={props.serviceCaseId}
                serviceOrderId={props.serviceOrderId}
                isReadOnly={props.isReadOnly}
                data-testid={suffixTestId('discounts', props)}
              />
            </>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const formSchema = (isFieldRequired: (name: string) => boolean) =>
  object({
    completionAtDate: yupDate[isFieldRequired('completionAtDate') ? 'required' : 'nullable'](),
    completionAtTime: yupString[isFieldRequired('completionAtTime') ? 'required' : 'nullable'](),
    totalPriceEstimated:
      yupNumber[isFieldRequired('totalPriceEstimated') ? 'required' : 'nullable'](),
    note: yupString.nullable(),
    approvalNumber: yupString[isFieldRequired('approvalNumber') ? 'required' : 'nullable'](),
    returnUsedParts: boolean().nullable(),
  });
