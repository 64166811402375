import {isDefined} from 'shared';

import {
  AddFilesToVehicleAlbumApiArg,
  AddFilesToVehicleAlbumApiResponse,
  ArchiveVehicleApiArg,
  ArchiveVehicleApiResponse,
  BatchDownloadEventIdArg,
  BatchDownloadEventIdResponse,
  BulkCreateHighlightsApiArg,
  BulkCreateHighlightsApiResponse,
  BulkCreateShortcomingsApiArg,
  BulkCreateShortcomingsApiResponse,
  CreateVehicleApiArg,
  CreateVehicleApiResponse,
  CreateVehicleToBuyApiArg,
  CreateVehicleToBuyApiResponse,
  CreateVehicleV2ApiArg,
  DeleteHighlightApiArg,
  DeleteHighlightApiResponse,
  DeleteShortcomingApiArg,
  DeleteShortcomingApiResponse,
  GetAdditionalNoteApiArg,
  GetAdditionalNoteApiResponse,
  GetSimilarVehiclesApiArg,
  GetSimilarVehiclesApiResponse,
  GetSimilarVehiclesV2ApiArg,
  GetSimilarVehiclesV2ApiResponse,
  GetVehicleApiArg,
  GetVehicleApiResponse,
  GetVehicleApiResponseV2,
  GetVehicleCoverPhotoApiArg,
  GetVehicleCoverPhotoApiResponse,
  GetVehicleFinancialInstitutionApiArg,
  GetVehicleFinancialInstitutionResponse,
  GetVehicleInsightsApiArg,
  GetVehicleInsightsApiRes,
  GetVehiclePhotosApiArg,
  GetVehiclePhotosApiRes,
  ListHighlightsApiArg,
  ListHighlightsApiResponse,
  ListShortcomingsApiArg,
  ListShortcomingsApiResponse,
  ListVehicleAlbumsApiArg,
  ListVehicleAlbumsApiResponse,
  PartialUpdateVehicleApiArg,
  PartialUpdateVehicleApiResponse,
  SetAdditionalNoteApiArg,
  SetAdditionalNoteApiResponse,
  UpdateVehicleApiArg,
  UpdateVehicleApiResponse,
  UpdateVehicleFinancialInstitutionApiArg,
  UpdateVehicleFinancialInstitutionResponse,
  UpdateVehicleV2ApiArg,
} from '../types/api';
import {
  ChangeVehicleTypeApiArg,
  ChangeVehicleTypeApiResponse,
  CopyAuditPhotoToAlbumApiArg,
  CopyAuditPhotoToAlbumApiResponse,
  CreateBackgroundRemovalApiArg,
  CreateBackgroundRemovalApiResponse,
  CreatePriceReportRequestApiArg,
  CreatePriceReportRequestApiResponse,
  CreateVehicleAlbumApiArg,
  CreateVehicleAlbumApiResponse,
  CreateVehiclePriceReportApiArg,
  CreateVehiclePriceReportApiResponse,
  DeleteBackgroundRemovalApiArg,
  DeleteBackgroundRemovalApiResponse,
  CreateWatermarkApiArg,
  CreateWatermarkApiResponse,
  DeletePhotoApiArg,
  DeletePhotoApiResponse,
  DeleteVehicleAlbumApiArg,
  DeleteVehicleAlbumApiResponse,
  DeleteVehicleApiArg,
  DeleteVehicleApiResponse,
  DeleteWatermarkApiArg,
  DeleteWatermarkApiResponse,
  GetAuditsApiArg,
  GetAuditsApiResponse,
  GetAutofillFeaturesApiArg,
  GetAutofillFeaturesApiResponse,
  GetBackgroundRemovalSettingsApiResponse,
  GetBackgroundRemovalV2ApiArg,
  GetBackgroundRemovalV2ApiResponse,
  GetVehicleAlbumPhotosApiArg,
  GetVehicleAlbumPhotosApiResponse,
  GetVehicleHomeServiceArg,
  GetVehicleHomeServiceResponse,
  GetVehicleInsuranceApiArg,
  GetVehicleInsuranceApiResponse,
  GetVehiclePriceReportsApiArg,
  GetVehiclePriceReportsApiResponse,
  GetVehicleStatisticsApiArg,
  GetVehicleStatisticsApiResponse,
  GetWatermarkDetailApiArg,
  GetWatermarkDetailApiResponse,
  ListTestDriveApiArg,
  ListTestDriveApiResponse,
  PutAutofillFeaturesApiArg,
  PutAutofillFeaturesApiResponse,
  PutBackgroundRemovalSettingsApiArg,
  PutBackgroundRemovalSettingsApiResponse,
  PutVehicleHomeServiceArg,
  PutVehicleHomeServiceResponse,
  RemoveVehiclePhotosBackgroundApiArg,
  RemoveVehiclePhotosBackgroundApiResponse,
  RotateVehiclePhotoApiArg,
  RotateVehiclePhotoApiRes,
  SetFeatureAsHighlightedApiArg,
  SetFeatureAsHighlightedApiResponse,
  SetFeaturesHighlightedApiArg,
  SetFeaturesHighlightedApiRes,
  SetVehicleCustomIdApiArg,
  SetVehicleCustomIdApiResponse,
  SetVehicleInsuranceApiArg,
  UpdateBackgroundRemovalV2ApiArg,
  UpdateBackgroundRemovalV2ApiResponse,
  UpdateWatermarkDetailApiArg,
  UpdateWatermarkDetailApiResponse,
  GetWatermarkListApiResponse,
  GetBackgroundRemovalListApiResponse,
} from '../types/vehicle';
import {omneticApi} from './baseApi/omneticApi';

export const vehicleApi = omneticApi.injectEndpoints({
  endpoints: (build) => ({
    getVehicle: build.query<GetVehicleApiResponse, GetVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    getVehicleV2: build.query<GetVehicleApiResponseV2, GetVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/vehicle/${queryArg.vehicleId}`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    createVehicleV2: build.mutation<GetVehicleApiResponseV2, CreateVehicleV2ApiArg>({
      query: (body) => ({
        url: `/dms/v2/vehicle`,
        method: 'POST',
        body,
      }),
    }),
    updateVehicleV2: build.mutation<GetVehicleApiResponseV2, UpdateVehicleV2ApiArg>({
      query: ({id, ...body}) => ({
        url: `/dms/v2/vehicle/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.id}],
    }),
    getSimilarVehicles: build.query<GetSimilarVehiclesApiResponse, GetSimilarVehiclesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/similar`,
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          vin: queryArg.vin,
          vinContains: queryArg.vinContains,
          registrationPlateContains: queryArg.registrationPlateContains,
        },
      }),
    }),
    getSimilarVehiclesV2: build.query<GetSimilarVehiclesV2ApiResponse, GetSimilarVehiclesV2ApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/similar-vehicle`,
        params: {
          size: queryArg.size,
          offset: queryArg.offset,
          vin: queryArg.vin,
          registrationPlateContains: queryArg.registrationPlateContains,
          vinContains: queryArg.vinContains,
        },
      }),
    }),
    deleteVehicle: build.mutation<DeleteVehicleApiResponse, DeleteVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    createVehiclePriceReport: build.mutation<
      CreateVehiclePriceReportApiResponse,
      CreateVehiclePriceReportApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/vehicle/${queryArg.vehicleId}/price-report`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['vehiclePriceReport'],
    }),
    getVehiclePriceReports: build.query<
      GetVehiclePriceReportsApiResponse,
      GetVehiclePriceReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/vehicle/${queryArg.vehicleId}/price-report`,
      }),
      providesTags: ['vehiclePriceReport'],
    }),
    listTestDrive: build.query<ListTestDriveApiResponse, ListTestDriveApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/test-drive`,
      }),
    }),
    listShortcomings: build.query<ListShortcomingsApiResponse, ListShortcomingsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/shortcoming`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'shortcomings', id: queryArg.vehicleId}],
    }),
    bulkCreateShortcomings: build.mutation<
      BulkCreateShortcomingsApiResponse,
      BulkCreateShortcomingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/shortcoming/_bulk`,
        method: 'PUT',
        body: queryArg.shortcomingBulkRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'shortcomings', id: queryArg.vehicleId},
      ],
    }),
    deleteShortcoming: build.mutation<DeleteShortcomingApiResponse, DeleteShortcomingApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/shortcoming/${queryArg.shortcomingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'shortcomings', id: queryArg.vehicleId},
      ],
    }),
    listHighlights: build.query<ListHighlightsApiResponse, ListHighlightsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/highlight`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'highlights', id: queryArg.vehicleId}],
    }),
    bulkCreateHighlights: build.mutation<
      BulkCreateHighlightsApiResponse,
      BulkCreateHighlightsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/highlight/_bulk`,
        method: 'PUT',
        body: queryArg.highlightBulkRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'highlights', id: queryArg.vehicleId},
      ],
    }),
    deleteHighlight: build.mutation<DeleteHighlightApiResponse, DeleteHighlightApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/highlight/${queryArg.highlightId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'highlights', id: queryArg.vehicleId},
      ],
    }),

    getVehiclePhotos: build.query<GetVehiclePhotosApiRes, GetVehiclePhotosApiArg>({
      keepUnusedDataFor: 0,
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/photo`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehiclePhotos', id: queryArg.vehicleId},
      ],
    }),
    getVehicleCoverPhoto: build.query<GetVehicleCoverPhotoApiResponse, GetVehicleCoverPhotoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/cover-photo`,
      }),
    }),
    getVehicleAlbumPhotos: build.query<
      GetVehicleAlbumPhotosApiResponse,
      GetVehicleAlbumPhotosApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/album/${queryArg.vehicleAlbumId}/photo`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'VehicleAlbumPhotos', id: queryArg.vehicleAlbumId},
      ],
    }),

    downloadGallery: build.query<BatchDownloadEventIdResponse, BatchDownloadEventIdArg>({
      keepUnusedDataFor: 0,
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/download-gallery/${queryArg.vehiclePhotoLocation}`,
        method: 'POST',
        params: {
          auditId: queryArg.auditId,
          albumId: queryArg.albumId,
        },
      }),
    }),
    getVehicleInsights: build.query<GetVehicleInsightsApiRes, GetVehicleInsightsApiArg>({
      keepUnusedDataFor: 0,
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/insights`,
      }),
    }),
    getVehicleStatistics: build.query<GetVehicleStatisticsApiResponse, GetVehicleStatisticsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/statistics/vehicle/`,
        params: {branchId: queryArg.branchId},
      }),
      providesTags: ['SaleVehicle'],
    }),
    partialUpdateVehicle: build.mutation<
      PartialUpdateVehicleApiResponse,
      PartialUpdateVehicleApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}`,
        method: 'PATCH',
        body: queryArg.patchVehicleRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    createVehicle: build.mutation<CreateVehicleApiResponse, CreateVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle`,
        method: 'POST',
        body: queryArg.vehicleRequestBody,
      }),
    }),
    createVehicleToBuy: build.mutation<CreateVehicleToBuyApiResponse, CreateVehicleToBuyApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/to-buy`,
        method: 'POST',
        body: queryArg.vehicleRequestBody,
      }),
    }),
    updateVehicle: build.mutation<UpdateVehicleApiResponse, UpdateVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}`,
        method: 'PUT',
        body: queryArg.vehicleRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    getAdditionalNote: build.query<GetAdditionalNoteApiResponse, GetAdditionalNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/additional-note`,
      }),
      providesTags: (_result, _error, queryArg) => [{type: 'VehicleNote', id: queryArg.vehicleId}],
    }),
    setAdditionalNote: build.mutation<SetAdditionalNoteApiResponse, SetAdditionalNoteApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/additional-note`,
        method: 'PUT',
        body: queryArg.additionalNoteRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'VehicleNote', id: queryArg.vehicleId},
      ],
    }),
    getAutofillFeatures: build.query<GetAutofillFeaturesApiResponse, GetAutofillFeaturesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/autofill-features/${queryArg.vin}`,
      }),
    }),
    putAutofillFeatures: build.query<PutAutofillFeaturesApiResponse, PutAutofillFeaturesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/autofill-features/${queryArg.vehicleId}`,
        method: 'PUT',
      }),
    }),
    getVehicleFinancialInstitution: build.query<
      GetVehicleFinancialInstitutionResponse,
      GetVehicleFinancialInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/financial-institution`,
        method: 'GET',
      }),
      providesTags: (result) => [{type: 'VehicleFinancialInstitution', id: result?.vehicleId}],
    }),
    updateVehicleFinancialInstitution: build.mutation<
      UpdateVehicleFinancialInstitutionResponse,
      UpdateVehicleFinancialInstitutionApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/financial-institution`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result) => [{type: 'VehicleFinancialInstitution', id: result?.vehicleId}],
    }),
    archiveVehicle: build.mutation<ArchiveVehicleApiResponse, ArchiveVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/archive`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    unarchiveVehicle: build.mutation<ArchiveVehicleApiResponse, ArchiveVehicleApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/unarchive`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, _error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    listVehicleAlbums: build.query<ListVehicleAlbumsApiResponse, ListVehicleAlbumsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/vehicle-album`,
        params: {vehicleId: queryArg.vehicleId, serviceCaseId: queryArg.serviceCaseId},
      }),
    }),
    createVehicleAlbum: build.mutation<CreateVehicleAlbumApiResponse, CreateVehicleAlbumApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/vehicle-album`,
        method: 'POST',
        body: queryArg.createVehicleAlbumRequestBody,
      }),
      invalidatesTags: ['Vehicle', 'VehiclePhotos'],
    }),
    addFilesToVehicleAlbum: build.mutation<
      AddFilesToVehicleAlbumApiResponse,
      AddFilesToVehicleAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/vehicle-album/${queryArg.vehicleAlbumId}`,
        method: 'POST',
        body: queryArg.addFilesToVehicleAlbumRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'Vehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    deleteVehicleAlbum: build.mutation<DeleteVehicleAlbumApiResponse, DeleteVehicleAlbumApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/vehicle-album/${queryArg.vehicleAlbumId}`,
        method: 'DELETE',
      }),
    }),
    deletePhoto: build.mutation<DeletePhotoApiResponse, DeletePhotoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/photo/${queryArg.vehicleId}/${queryArg.albumId}`,
        method: 'DELETE',
        body: queryArg.vehiclePhotoDeleteRequestBody,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'Vehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicle', id: queryArg.vehicleId},
        {type: 'SaleVehicleActions', id: queryArg.vehicleId},
      ],
    }),
    removeVehiclePhotosBackground: build.mutation<
      RemoveVehiclePhotosBackgroundApiResponse,
      RemoveVehiclePhotosBackgroundApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/remove-photo-background`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'VehiclePhotos', id: queryArg.vehicleId},
      ],
    }),
    setFeaturesHighlighted: build.mutation<
      SetFeaturesHighlightedApiRes,
      SetFeaturesHighlightedApiArg
    >({
      query: ({vehicleId, ...body}) => ({
        url: `/dms/v1/vehicle/${vehicleId}/features/_bulk`,
        method: 'PUT',
        body,
      }),
    }),
    setFeatureAsHighlighted: build.mutation<
      SetFeatureAsHighlightedApiResponse,
      SetFeatureAsHighlightedApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/feature/${queryArg.featureKey}`,
        method: 'PUT',
      }),
      async onQueryStarted({vehicleId, featureKey}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          vehicleApi.util.updateQueryData('getVehicleV2', {vehicleId}, (draft) => {
            const features = draft.features?.map((item) => {
              if (item.key !== featureKey) {
                return item;
              }
              return {...item, isHighlighted: true};
            });

            return {
              ...draft,
              features,
            };
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    setFeatureAsNotHighlighted: build.mutation<
      SetFeatureAsHighlightedApiResponse,
      SetFeatureAsHighlightedApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/feature/${queryArg.featureKey}`,
        method: 'DELETE',
      }),
      async onQueryStarted({vehicleId, featureKey}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          vehicleApi.util.updateQueryData('getVehicleV2', {vehicleId}, (draft) => {
            const features = draft.features?.map((item) => {
              if (item.key !== featureKey) {
                return item;
              }

              return {...item, isHighlighted: false};
            });

            return {
              ...draft,
              features,
            };
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    rotateVehiclePhoto: build.mutation<RotateVehiclePhotoApiRes, RotateVehiclePhotoApiArg>({
      query: ({vehicleAlbumId, vehicleId, ...body}) => ({
        url: `/dms/v1/vehicle/${vehicleId}/album/${vehicleAlbumId}/rotate-photo`,
        body,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'VehiclePhotos', id: queryArg.vehicleId},
      ],
      transformResponse(_response, _meta, arg) {
        return {vehiclePhotoIds: arg.vehiclePhotoIds};
      },
    }),
    getBackgroundRemovalSettings: build.query<GetBackgroundRemovalSettingsApiResponse, void>({
      query: () => ({
        url: `/dms/v1/settings/background-removal`,
      }),
      providesTags: ['backgroundRemovalSettings'],
    }),
    getBackgroundRemovalList: build.query<GetBackgroundRemovalListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/settings/background-removal/list`,
      }),
      providesTags: ['backgroundRemovalSettings'],
    }),
    putBackgroundRemovalSettings: build.mutation<
      PutBackgroundRemovalSettingsApiResponse,
      PutBackgroundRemovalSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/background-removal`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['backgroundRemovalSettings'],
    }),
    createPriceReportRequest: build.mutation<
      CreatePriceReportRequestApiResponse,
      CreatePriceReportRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/price-report-request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: ['vehiclePriceReport'],
    }),
    getVehicleInsurance: build.query<GetVehicleInsuranceApiResponse, GetVehicleInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/insurance`,
      }),
      providesTags: (_result, _error, queryArg) => [
        {type: 'vehicleInsurance', id: queryArg.vehicleId},
      ],
    }),
    setVehicleInsurance: build.mutation<void, SetVehicleInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/insurance`,
        method: 'PUT',
        body: queryArg.data,
      }),
      invalidatesTags: (_result, _error, queryArg) => [
        {type: 'vehicleInsurance', id: queryArg.vehicleId},
      ],
    }),
    getAudits: build.query<GetAuditsApiResponse, GetAuditsApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/audit`,
      }),
    }),
    setVehicleCustomId: build.mutation<SetVehicleCustomIdApiResponse, SetVehicleCustomIdApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/custom-id`,
        method: 'PUT',
        body: queryArg.setVehicleCustomIdRequestBody,
      }),
      invalidatesTags: (result, error, queryArg) => [{type: 'Vehicle', id: queryArg.vehicleId}],
    }),
    copyAuditPhotoToAlbum: build.mutation<
      CopyAuditPhotoToAlbumApiResponse,
      CopyAuditPhotoToAlbumApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/copy-audit-photo`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (_result, _error) => [
        {type: 'SaleVehicleAlbumPhotos'},
        {type: 'VehiclePhotos'},
        {type: 'Vehicle'},
      ],
    }),
    getServiceVehicleHomeBranch: build.query<
      GetVehicleHomeServiceResponse,
      GetVehicleHomeServiceArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/home-service`,
      }),
      providesTags: (_response, _error, args) => [
        {type: 'ServiceVehicleHomeService', id: args.vehicleId},
      ],
    }),
    putServiceVehicleHomeBranch: build.mutation<
      PutVehicleHomeServiceResponse,
      PutVehicleHomeServiceArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/vehicle/${queryArg.vehicleId}/home-service`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (_response, error, args) =>
        isDefined(error) ? [] : [{type: 'ServiceVehicleHomeService', id: args.vehicleId}],
    }),
    changeVehicleType: build.mutation<ChangeVehicleTypeApiResponse, ChangeVehicleTypeApiArg>({
      query: ({vehicleId, ...body}) => ({
        url: `/dms/v1/vehicle/${vehicleId}/vehicle-type`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_response, _error, args) => [
        {type: 'Vehicle', id: args.vehicleId},
        'AdGeneralSettings',
        'AdPlatformSettings',
        'AdWindshieldSettings',
      ],
    }),
    createBackgroundRemoval: build.mutation<
      CreateBackgroundRemovalApiResponse,
      CreateBackgroundRemovalApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/background-removal`,
        method: 'POST',
        body: queryArg.createBackgroundRemovalRequestBody,
      }),
    }),
    getBackgroundRemovalV2: build.query<
      GetBackgroundRemovalV2ApiResponse,
      GetBackgroundRemovalV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/settings/background-removal/${queryArg.settingId}`,
      }),
    }),
    updateBackgroundRemovalV2: build.mutation<
      UpdateBackgroundRemovalV2ApiResponse,
      UpdateBackgroundRemovalV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v2/settings/background-removal/${queryArg.settingId}`,
        method: 'PUT',
        body: queryArg.updateBackgroundRemovalRequestBody,
      }),
      invalidatesTags: ['backgroundRemovalSettings'],
    }),
    deleteBackgroundRemoval: build.mutation<
      DeleteBackgroundRemovalApiResponse,
      DeleteBackgroundRemovalApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/background-removal/${queryArg.settingId}`,
        method: 'DELETE',
      }),
    }),
    createWatermark: build.mutation<CreateWatermarkApiResponse, CreateWatermarkApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/settings/watermark`,
        method: 'POST',
        body: queryArg.createWatermarkRequestBody,
      }),
      invalidatesTags: (_response, _error, _args) => ['WatermarkDetial', 'WatermarkList'],
    }),
    deleteWatermark: build.mutation<DeleteWatermarkApiResponse, DeleteWatermarkApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/settings/watermark/${queryArg.watermarkId}`,
        method: 'DELETE',
        body: queryArg.deleteWatermarkRequestBody,
      }),
      invalidatesTags: (_response, _error, args) => [
        {type: 'WatermarkDetial', id: args.watermarkId},
        'WatermarkList',
      ],
    }),
    getWatermarkDetail: build.query<GetWatermarkDetailApiResponse, GetWatermarkDetailApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/settings/watermark/${queryArg.watermarkId}`,
      }),
      providesTags: (_response, _error, args) => [{type: 'WatermarkDetial', id: args.watermarkId}],
    }),
    updateWatermarkDetail: build.mutation<
      UpdateWatermarkDetailApiResponse,
      UpdateWatermarkDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/settings/watermark/${queryArg.watermarkId}`,
        method: 'PUT',
        body: queryArg.updateWatermarkRequestBody,
      }),
      invalidatesTags: (_response, _error, args) => [
        {type: 'WatermarkDetial', id: args.watermarkId},
        'WatermarkList',
      ],
    }),
    getWatermarkList: build.query<GetWatermarkListApiResponse, void>({
      query: () => ({
        url: `/dms/v1/settings/watermark`,
      }),
      providesTags: () => ['WatermarkList'],
    }),
  }),
});

export const {
  useChangeVehicleTypeMutation,
  useGetSimilarVehiclesQuery,
  useLazyGetSimilarVehiclesQuery,
  useGetSimilarVehiclesV2Query,
  useLazyGetSimilarVehiclesV2Query,
  useSetFeatureAsNotHighlightedMutation,
  useLazyGetVehicleQuery,
  useGetVehicleQuery,
  useRotateVehiclePhotoMutation,
  useDeleteVehicleMutation,
  useSetFeatureAsHighlightedMutation,
  useLazyDownloadGalleryQuery,
  useGetVehicleInsightsQuery,
  useGetVehicleStatisticsQuery,
  usePartialUpdateVehicleMutation,
  useCreateVehicleMutation,
  useCreateVehicleToBuyMutation,
  useSetFeaturesHighlightedMutation,
  useUpdateVehicleMutation,
  useGetAdditionalNoteQuery,
  useSetAdditionalNoteMutation,
  useLazyGetAutofillFeaturesQuery,
  useLazyPutAutofillFeaturesQuery,
  useGetVehicleFinancialInstitutionQuery,
  useUpdateVehicleFinancialInstitutionMutation,
  useListVehicleAlbumsQuery,
  useListTestDriveQuery,
  useCreateVehicleAlbumMutation,
  useBulkCreateHighlightsMutation,
  useDeleteHighlightMutation,
  useListHighlightsQuery,
  useBulkCreateShortcomingsMutation,
  useGetVehiclePhotosQuery,
  useDeleteShortcomingMutation,
  useListShortcomingsQuery,
  useGetVehicleV2Query,
  useLazyGetVehicleV2Query,
  useCreateVehicleV2Mutation,
  useUpdateVehicleV2Mutation,
  useAddFilesToVehicleAlbumMutation,
  useArchiveVehicleMutation,
  useUnarchiveVehicleMutation,
  useDeletePhotoMutation,
  useRemoveVehiclePhotosBackgroundMutation,
  useDeleteVehicleAlbumMutation,
  useCreateVehiclePriceReportMutation,
  useGetVehiclePriceReportsQuery,
  useGetBackgroundRemovalSettingsQuery,
  usePutBackgroundRemovalSettingsMutation,
  useCreatePriceReportRequestMutation,
  useGetVehicleInsuranceQuery,
  useLazyGetVehicleInsuranceQuery,
  useSetVehicleInsuranceMutation,
  useGetAuditsQuery,
  useSetVehicleCustomIdMutation,
  useCopyAuditPhotoToAlbumMutation,
  useGetServiceVehicleHomeBranchQuery,
  usePutServiceVehicleHomeBranchMutation,
  useCreateBackgroundRemovalMutation,
  useGetBackgroundRemovalV2Query,
  useUpdateBackgroundRemovalV2Mutation,
  useDeleteBackgroundRemovalMutation,
  useCreateWatermarkMutation,
  useDeleteWatermarkMutation,
  useGetWatermarkDetailQuery,
  useGetBackgroundRemovalListQuery,
  useUpdateWatermarkDetailMutation,
  useGetVehicleCoverPhotoQuery,
  useGetWatermarkListQuery,
  useLazyGetWatermarkListQuery,
} = vehicleApi;
