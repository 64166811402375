import {Card, DataStatus} from 'platform/components';
import {Grid, VStack} from 'platform/foundation';

import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {isNil} from 'ramda';
import {isFalse} from 'ramda-adjunct';

import {
  businessCaseApi,
  useGetBusinessCaseQuery,
  usePutBusinessCaseContactPersonMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  CustomerInformationCard,
  handleApiError,
  useBusinessCaseActions,
  usePermissions,
} from '@omnetic-dms/shared';

import {ContactPersonForm} from '../ContactPersonForm';

interface CustomerPamelProps {
  businessCaseId: string;
  openCustomerWidget: () => void;
}

export function CustomerPanel(props: CustomerPamelProps) {
  const {
    data: businessCase,
    isLoading: isLoadingBusinessCase,
    isError: isErrorBusinessCase,
  } = useGetBusinessCaseQuery({businessCaseId: props.businessCaseId});
  const [putBusinessCaseContactPerson] = usePutBusinessCaseContactPersonMutation();

  const {isActionEnabled} = useBusinessCaseActions(businessCase?.id ?? '');

  const customerId = businessCase?.customerId;

  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();

  const [
    hasChangeBusinessCasePurchaseSellerPermission,
    hasSelectBusinessCasePurchaseSellerPermission,
    hasChangeBusinessCaseSellingBuyerPermission,
    hasSelectBusinessCaseSellingBuyerPermission,
  ] = usePermissions({
    permissionKeys: [
      'changeBusinessCasePurchaseSeller',
      'selectBusinessCasePurchaseSellerContactPerson',
      'changeBusinessCaseSellingBuyer',
      'selectBusinessCaseSellingBuyerContactPerson',
    ],
  });

  const canSelectContactPerson =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? hasSelectBusinessCasePurchaseSellerPermission
      : hasSelectBusinessCaseSellingBuyerPermission;

  const canChangeCustomer =
    businessCase?.businessCaseType === 'BUYING' ||
    businessCase?.businessCaseType === 'PURCHASE_BROKERAGE'
      ? hasChangeBusinessCasePurchaseSellerPermission
      : hasChangeBusinessCaseSellingBuyerPermission;

  useEffect(() => {
    setShowForm(!!businessCase?.contactPersonId);
  }, [businessCase?.contactPersonId]);

  const toggleContactPerson = (value: boolean) => {
    putBusinessCaseContactPerson({
      businessCaseId: businessCase!.id,
      putBusinessCaseContactPersonRequestBody: {
        contactId: null,
      },
    })
      .unwrap()
      .then(() => {
        dispatch(
          businessCaseApi.util.invalidateTags([
            {type: 'BusinessCaseDetail', id: businessCase!.id},
            {type: 'BusinessCaseActions', id: businessCase!.id},
          ])
        );
        setShowForm(value);
      })
      .catch(handleApiError);
  };

  return (
    <DataStatus
      isLoading={isLoadingBusinessCase}
      isError={isErrorBusinessCase}
      isEmpty={isNil(customerId)}
    >
      <VStack spacing={4}>
        <Grid columns={2} align="flex-start">
          <CustomerInformationCard
            customerId={customerId!}
            onChange={props.openCustomerWidget}
            isDetailLinkVisible
            isCustomerChangeDisabled={businessCase?.businessCaseState === 'CLOSED'}
            isChangeCustomerForbidden={isFalse(canChangeCustomer)}
            data-testid={testIds.businessCase.customer('customerInformation')}
          />
          <Card
            data-testid={testIds.businessCase.customer('contactPerson')}
            variant="default"
            isExpanded={showForm}
            control={{
              type: 'switch',
              value: showForm,
              isDisabled:
                !canSelectContactPerson || !isActionEnabled('SELECT_CUSTOMER_CONTACT_PERSON'),
              onChange: toggleContactPerson,
              'data-testid': testIds.businessCase.customer('toggleContactPerson'),
            }}
            title={i18n.t('entity.businessCase.labels.contactPerson')}
          >
            <Card data-testid={testIds.businessCase.customer('contactPerson')} variant="inlineGrey">
              <ContactPersonForm
                customerId={customerId!}
                isDisabled={
                  !canSelectContactPerson || !isActionEnabled('SELECT_CUSTOMER_CONTACT_PERSON')
                }
                contactPersonId={businessCase?.contactPersonId}
              />
            </Card>
          </Card>
        </Grid>
      </VStack>
    </DataStatus>
  );
}
