import {isFeatureEnabled} from 'feature-flags';
import {Menu} from 'platform/components';

import {useLocation, useNavigate} from 'react-router-dom';

import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {usePermissions} from '@omnetic-dms/shared';

import {buildArray} from 'shared';

import {NavigationItem} from '../../../types';

export function SettingsNavigation() {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [
    canReadVehicleWarehouse,
    canReadEmployee,
    canReadPneuhotel,
    canReadServiceCase,
    canReadSourcingOffers,
    canReadCustomFieldsDefinitions,
  ] = usePermissions({
    permissionKeys: [
      'vehicleWarehouseRead',
      'employeeRead',
      'pneuhotelRead',
      'serviceCaseRead',
      'sourcingOffersRead',
      'readCustomFieldsDefinitions',
    ],
  });

  const isWorkShopEnabled = isFeatureEnabled(featureFlags.ACL_WORKSHOP) && canReadServiceCase;

  const menuItems = buildArray<NavigationItem>([
    {
      id: 'sales',
      label: i18n.t('page.salesSettings.title'),
      leftIcon: 'action/assessment',
      items: buildArray<NavigationItem>([
        {
          id: 'advertising',
          label: i18n.t('page.salesSettings.labels.advertising'),
          href: settingsRoutes.advertising,
        },
        {
          id: 'businessCases',
          label: i18n.t('page.businessCases.title'),
          href: settingsRoutes.businessCase,
        },
        {
          id: 'interest',
          label: i18n.t('entity.interest.labels.interest'),
          href: settingsRoutes.interest,
        },
        {
          id: 'productCatalogue',
          label: i18n.t('entity.productCatalog.labels.productCatalog'),
          href: settingsRoutes.productCatalog,
        },
        {
          id: 'codelists',
          label: i18n.t('page.codelists.pageTitle'),
          href: settingsRoutes.codelists,
        },
      ])
        .when(
          isFeatureEnabled(featureFlags.SALES_VEHICLE_WAREHOUSE_MANAGEMENT) &&
            canReadVehicleWarehouse,
          {
            id: 'vehicleWarehouseManagement',
            label: i18n.t('page.salesSettings.labels.vehicleWarehouseManagement'),
            href: settingsRoutes.vehicleWarehouseManagement,
          }
        )
        .whenFeatureEnabled(featureFlags.SALES_AUTOCANCEL_RESERVATION, {
          id: 'reservation',
          label: i18n.t('page.reservationSettings.labels.pageTitle'),
          href: settingsRoutes.reservation,
        }),
    },
    {
      id: 'vehicle',
      label: i18n.t('entity.vehicle.labels.vehicle'),
      leftIcon: 'maps/directions_car',
      items: [
        {
          id: 'topMakes',
          label: i18n.t('general.labels.topMakes'),
          href: settingsRoutes.vehicleTopMakes,
        },
        {
          id: 'vehicleSeriesDefinition',
          label: i18n.t('page.salesSettings.labels.vehicleSeriesDefinitionTitle'),
          href: settingsRoutes.vehicleSeriesDefinition,
        },
        {
          id: 'inspections',
          label: i18n.t('entity.vehicle.labels.inspections'),
          href: settingsRoutes.vehicleInspections,
        },
        {
          id: 'condition',
          label: i18n.t('page.vehicleSettings.labels.condition'),
          href: settingsRoutes.vehicleCondition,
        },
        {
          id: 'parking',
          label: i18n.t('page.vehicleSettings.labels.parking'),
          href: settingsRoutes.vehicleParking,
        },
      ],
    },
    {
      id: 'customer',
      label: i18n.t('entity.customer.labels.customer'),
      leftIcon: 'social/person',
      items: [
        {
          id: 'customerCodeDefinition',
          label: i18n.t('entity.seriesDefinition.labels.customerCodeDefinition'),
          href: settingsRoutes.customerCodeDefinition,
        },
      ],
    },
  ])
    .whenFeatureEnabled(featureFlags.SETTINGS_MANAGEMENT_SPLIT, {
      id: 'userManagement',
      label: i18n.t('page.settings.labels.userManagement'),
      leftIcon: 'social/people',
      items: [
        {
          id: 'users',
          label: i18n.t('entity.user.labels.users'),
          href: settingsRoutes.userManagement,
        },
        {
          id: 'roles',
          label: i18n.t('entity.person.labels.roles'),
          href: settingsRoutes.roleManagement,
        },
      ],
    })
    .whenFeatureNotEnabled(featureFlags.SETTINGS_MANAGEMENT_SPLIT, {
      id: 'userManagement',
      label: i18n.t('page.settings.labels.userManagement'),
      leftIcon: 'social/people',
      href: settingsRoutes.userManagement,
    })
    .add({
      id: 'documentTemplates',
      label: i18n.t('entity.document.labels.documentTemplates'),
      leftIcon: 'action/description',
      href: settingsRoutes.documentTemplates,
    })
    .add({
      id: 'gdrpConsentTypes',
      label: i18n.t('entity.gdpr.labels.gdprConsentTypes'),
      leftIcon: 'content/ballot',
      href: settingsRoutes.gdprConsentTypes,
      hasSeparator: !isWorkShopEnabled,
    })
    .when(isWorkShopEnabled, {
      id: 'workshop',
      label: i18n.t('module.workshop.title'),
      leftIcon: 'action/build',
      items: [
        {
          id: 'dictionaries',
          label: i18n.t('page.settings.labels.dictionaries'),
          items: [
            {
              id: 'workType',
              label: i18n.t('entity.order.labels.workType'),
              href: settingsRoutes.workType,
            },
            {
              id: 'vehicleGroup',
              label: i18n.t('entity.workshop.labels.vehicleGroups'),
              href: settingsRoutes.vehicleGroups,
            },
          ],
        },
        {
          id: 'labourRates',
          label: i18n.t('entity.workshop.labels.labourRates'),
          href: settingsRoutes.labourRates,
        },
        {
          id: 'labourCatalogues',
          label: i18n.t('entity.workshop.labels.labourCatalogues'),
          href: settingsRoutes.labourCatalogues,
        },
        {
          id: 'serviceOrderVariants',
          label: i18n.t('entity.order.labels.serviceOrderVariants'),
          href: settingsRoutes.serviceOrderVariants,
        },
        {
          id: 'serviceCaseType',
          label: i18n.t('entity.workshopPresetsCategory.labels.serviceCaseType'),
          href: settingsRoutes.serviceCaseType,
        },
        {
          id: 'servicePackages',
          label: i18n.t('entity.workshopServicePackages.labels.title'),
          href: settingsRoutes.workshopServicePackages,
        },
        {
          id: 'checkinInspection',
          label: i18n.t('entity.inspection.labels.checkinInspection'),
          href: settingsRoutes.checkinInspection,
        },
        {
          id: 'handoverInspection',
          label: i18n.t('entity.inspection.labels.handoverInspection'),
          href: settingsRoutes.handoverInspection,
        },
        {
          id: 'workshopCustomerGroups',
          label: i18n.t('entity.rabatDiscounts.labels.rabatDiscounts'),
          href: settingsRoutes.workshopCustomerGroups,
        },
        {
          id: 'workshopSmallUnitMaterial',
          label: i18n.t('entity.workshopMinorUnitMaterial.labels.minorUnitMaterial'),
          href: settingsRoutes.workshopSmallUnitMaterial,
        },
        {
          id: 'customerContracts',
          label: i18n.t('entity.customerContract.labels.customerContracts'),
          href: settingsRoutes.customerContracts,
        },
        {
          id: 'workshopMeasurementUnits',
          label: i18n.t('page.settings.labels.measurementUnits'),
          href: settingsRoutes.workshopMeasurementUnits,
        },
      ],
    })
    .when(isFeatureEnabled(featureFlags.ACL_WAREHOUSE) && canReadSourcingOffers, {
      id: 'warehouseManagement',
      label: i18n.t('page.warehouseSettings.labels.warehouseSettings'),
      leftIcon: 'custom/warehouse',
      items: [
        {
          id: 'dictionaries',
          label: i18n.t('page.settings.labels.dictionaries'),
          items: [
            {
              id: 'suppliers',
              label: i18n.t('entity.warehouse.labels.suppliers'),
              href: settingsRoutes.suppliers,
            },
            {
              id: 'manufacturers ',
              label: i18n.t('entity.warehouse.labels.manufacturers'),
              href: settingsRoutes.warehousesManufacturers,
            },
            {
              id: 'measurementUnits ',
              label: i18n.t('entity.warehouse.labels.measurementUnits'),
              href: settingsRoutes.warehousesMeasurementUnits,
            },
            {
              id: 'marketingCode',
              label: i18n.t('entity.warehouse.labels.marketingCode'),
              href: settingsRoutes.warehousesMarketingCode,
            },
            {
              id: 'warehouses/discount-group',
              label: i18n.t('entity.warehouse.labels.discountGroup'),
              href: settingsRoutes.warehousesDiscountGroup,
            },
            {
              id: 'warehouses/article-category',
              label: i18n.t('entity.warehouse.labels.category'),
              href: settingsRoutes.warehousesArticleCategory,
            },
          ],
        },
        {
          id: 'warehouses',
          label: i18n.t('page.warehouses.labels.title'),
          href: settingsRoutes.warehousesList,
        },
        {
          id: 'warehouses/supplierOrderVariants',
          label: i18n.t('page.warehouses.labels.supplierOrderVariants'),
          href: settingsRoutes.warehousesSupplierOrderVariants,
        },
        {
          id: 'warehouses/direct-sale-variants',
          label: i18n.t('entity.warehouse.labels.directSaleVariants'),
          href: settingsRoutes.warehousesDirectSaleVariants,
        },
        {
          id: 'account',
          label: i18n.t('entity.warehouse.labels.account'),
          href: settingsRoutes.warehousesAccount,
        },
        {
          id: 'warehouses/service-order-issue-variants',
          label: i18n.t('entity.warehouse.labels.serviceOrderIssueVariants'),
          href: settingsRoutes.warehousesServiceOrderIssueVariants,
        },
      ],
    })
    .when(isFeatureEnabled(featureFlags.ACL_EMPLOYEE_MANAGEMENT) && canReadEmployee, {
      id: 'employee',
      label: i18n.t('module.employee.title'),
      leftIcon: 'custom/employee',
      hasSeparator: !isFeatureEnabled(featureFlags.PNEUHOTEL_BASIC_VIEW) && canReadPneuhotel,
      items: [
        {
          id: 'professions',
          label: i18n.t('entity.employeeProfessions.labels.title'),
          href: settingsRoutes.employeeProfessions,
        },
        {
          id: 'competences',
          label: i18n.t('entity.employeeCompetences.labels.title'),
          href: settingsRoutes.employeeCompetences,
        },
        {
          id: 'costCenters',
          label: i18n.t('entity.employeeCostCenters.labels.title'),
          href: settingsRoutes.employeeCostCenters,
        },
        {
          id: 'workTeams',
          label: i18n.t('entity.employeeWorkTeams.labels.title'),
          href: settingsRoutes.employeeWorkTeams,
        },
      ],
    })
    .when(isFeatureEnabled(featureFlags.PNEUHOTEL_BASIC_VIEW) && canReadPneuhotel, {
      id: 'tiresInventory',
      label: i18n.t('module.tiresInventory.title'),
      leftIcon: 'modules/pneu_hotel',
      hasSeparator: true,
      items: [
        {
          id: 'tiresInventory-general',
          label: i18n.t('general.labels.general'),
          href: settingsRoutes.tiresInventory,
        },
        {
          id: 'tiresInventory-manufacturers',
          label: i18n.t('entity.tiresInventory.labels.tireManufacturers'),
          href: settingsRoutes.tireManufacturers,
        },
        {
          id: 'tiresInventory-locations',
          label: i18n.t('entity.tiresInventory.labels.tireLocations'),
          href: settingsRoutes.tireLocations,
        },
        {
          id: 'tiresInventory-storageLocation',
          label: i18n.t('entity.tiresInventory.labels.storageLocation'),
          href: settingsRoutes.tireStorageLocation,
        },
      ],
    })
    .whenFeatureNotEnabled(featureFlags.TENANT_V2, {
      id: 'tenantProfile',
      label: i18n.t('page.settings.labels.tenantProfile'),
      leftIcon: 'places/business_center',
      href: settingsRoutes.tenant,
    })
    .whenFeatureNotEnabled(featureFlags.TENANT_V2, {
      id: 'billingInformation',
      label: i18n.t('entity.checkout.labels.billingInformation'),
      leftIcon: 'action/credit_card',
      href: settingsRoutes.billingInformation,
    })
    .whenFeatureEnabled(featureFlags.TENANT_V2, {
      id: 'tenantManagement',
      label: i18n.t('entity.checkout.labels.tenantManagement'),
      leftIcon: 'places/business_center',
      items: [
        {
          id: 'tenantProfile',
          label: i18n.t('page.settings.labels.tenantProfile'),
          leftIcon: 'places/business_center',
          href: settingsRoutes.tenant,
        },
        {
          id: 'billingInformation',
          label: i18n.t('entity.checkout.labels.billingInformation'),
          leftIcon: 'action/credit_card',
          href: settingsRoutes.billingInformation,
        },
      ],
    })
    .add({
      id: 'general',
      label: i18n.t('page.generalSettings.labels.title'),
      leftIcon: 'action/dns',
      items: buildArray<NavigationItem>()
        .add({
          id: 'seriesDefinition',
          label: i18n.t('page.generalSettings.labels.seriesDefinitionLink'),
          href: settingsRoutes.seriesDefinition,
        })
        .when(
          isFeatureEnabled(featureFlags.VEHICLE_CUSTOM_FIELDS) && canReadCustomFieldsDefinitions,
          {
            id: 'customFields',
            label: i18n.t('page.generalSettings.labels.customFields'),
            href: settingsRoutes.customFields,
          }
        ),
    })
    .add({
      id: 'branches',
      label: i18n.t('entity.branch.labels.branches'),
      leftIcon: 'social/domain',
      href: settingsRoutes.branches,
    })
    .add({
      id: 'accountingDocuments',
      label: i18n.t('page.accounting.labels.accounting'),
      leftIcon: 'editor/insert_drive_file',
      hasSeparator: true,
      items: buildArray<NavigationItem>([
        {
          id: 'cashRegisters',
          label: i18n.t('page.accounting.labels.cashRegisters'),
          href: settingsRoutes.cashRegisters,
        },
        {
          id: 'cashOperationsLimits',
          label: i18n.t('page.accounting.labels.cashRegisterLimitsTitle'),
          href: settingsRoutes.cashLimits,
        },
        {
          id: 'invoiceDefinition',
          label: i18n.t('page.accounting.labels.invoiceDefinition'),
          href: settingsRoutes.invoiceDefinition,
        },
        {
          id: 'documentRoundingDefinitions',
          label: i18n.t('page.accounting.labels.documentRoundingDefinitions'),
          href: settingsRoutes.documentRoundingDefinitions,
        },
      ])
        .whenFeatureEnabled(featureFlags.ACCOUNTING_CANCEL_V2, {
          id: 'invoiceCanceling',
          label: i18n.t('page.accounting.labels.invoiceCanceling'),
          href: settingsRoutes.invoiceCanceling,
        })
        .whenFeatureEnabled(featureFlags.SALES_PRE_ACCOUNTING, {
          id: 'accountingErrorLog',
          label: i18n.t('page.accounting.labels.accountingErrorLog'),
          href: settingsRoutes.accountingErrorLog,
        })
        .whenFeatureEnabled(featureFlags.SALES_PRE_ACCOUNTING, {
          id: 'accountingMatrixManagement',
          label: i18n.t('page.accounting.labels.accountingMatrixManagement'),
          href: settingsRoutes.accountingMatrixManagement,
        })
        .add({
          id: 'exportParameters',
          label: i18n.t('page.accountingSettings.labels.exportParameters'),
          href: settingsRoutes.exportParameters,
        })
        .whenFeatureEnabled(featureFlags.SALES_PRE_ACCOUNTING, {
          id: 'accountingActivityLog',
          label: i18n.t('page.accounting.labels.accountingActivityLog'),
          href: settingsRoutes.accountingActivityLog,
        })
        .whenFeatureEnabled(featureFlags.ACCOUNTING_MARGIN_INVOICES, {
          id: 'invoiceMargin',
          label: i18n.t('page.accountingSettings.labels.invoiceMargin'),
          href: settingsRoutes.invoiceMarginOverview,
        })
        .add({
          id: 'proformaGeneration',
          label: i18n.t('page.accountingSettings.labels.proformaGeneration'),
          href: settingsRoutes.proformaGeneration,
        })
        .add({
          id: 'proformaCreation',
          label: i18n.t('page.accountingSettings.labels.proformaCreation'),
          href: settingsRoutes.ProformaCreationOverview,
        })
        .whenFeatureEnabled(featureFlags.SALES_VAT_CHECK_TOTALS, {
          id: 'vatTotalAndCalculation',
          label: i18n.t('page.accountingSettings.labels.vatTotalAndCalculation'),
          href: settingsRoutes.vatTotalAndCalculationOverview,
        })
        .add({
          id: 'taxDocumentForPayment',
          label: i18n.t('entity.accounting.labels.taxDocumentForPayment'),
          items: [
            {
              id: 'taxDocumentForPayment-issuedType',
              label: i18n.t('page.accountingSettings.labels.issuedType'),
              href: settingsRoutes.taxDocumentIssuedType,
            },
            {
              id: 'taxDocumentForPayment-importType',
              label: i18n.t('page.accountingSettings.labels.importType'),
              href: settingsRoutes.taxDocumentImportType,
            },
          ],
        }),
    })
    .add({
      id: 'integratedServices',
      label: i18n.t('page.integrations.labels.title'),
      leftIcon: 'action/extension',
      items: buildArray<NavigationItem>([
        {
          id: 'vehicleServices',
          label: i18n.t('page.integrations.labels.vehicleServices'),
          href: settingsRoutes.vehicleServices,
        },
        {
          id: 'smsGateways',
          label: i18n.t('page.integrations.labels.smsGateways'),
          href: settingsRoutes.smsGateways,
        },
        {
          id: 'emailServices',
          label: i18n.t('page.integrations.labels.emailServices'),
          href: settingsRoutes.emailServices,
        },
        {
          id: 'customerVerificationSerivces',
          label: i18n.t('page.integrations.labels.customerVerification'),
          href: settingsRoutes.customerVerificationServices,
        },
      ]).whenFeatureEnabled(featureFlags.AUTOSTACJA_CUSTOMER_WIDGET, {
        id: 'externalSystems',
        label: i18n.t('page.integrations.labels.externalSystems'),
        items: [
          {
            id: 'autostacjaWidget',
            label: i18n.t('page.integrations.labels.autostacja'),
            href: settingsRoutes.autostacjaSettings,
          },
        ],
      }),
    })
    .add({
      id: 'localization',
      label: i18n.t('general.localeConfig.localization'),
      leftIcon: 'action/language',
      href: settingsRoutes.locale,
    });

  const allItems = getMenuItems(menuItems);
  const activeItem = allItems.find((item) => item.href && pathname.includes(item.href));

  const handleItemChange = (id: string) => {
    const item = allItems.find((item) => item.id === id);
    if (item?.href) {
      navigate(item.href);
    }
  };

  return (
    <Menu
      items={menuItems}
      activeItemId={activeItem?.id}
      onItemChange={handleItemChange}
      isSearchable
      data-testid="menu"
    />
  );
}

const getMenuItems = (items: NavigationItem[]): NavigationItem[] =>
  items.flatMap((item) => (item.items ? getMenuItems(item.items) : item));
