import {Action, Attributes, AttributesRow, Card, DataStatus, Separator} from 'platform/components';
import {VStack} from 'platform/foundation';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  GetSupplierOrderItemResponse,
  useGetTreeFolderPathQuery,
  useGetWarehouseAccountQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {warehouseRoutes} from '@omnetic-dms/routes';

import {Nullish, RequiredTestIdProps, composePath} from 'shared';

import {ArticleDetailCardPrices} from '../../../../../components/ArticleDetailCardPrices';
import {getArticleDetailCardPrices} from '../../../../../utils/getArticleDetailCardPrices';

interface ArticleDetailCardProps extends RequiredTestIdProps {
  articleId: string | Nullish;
  manufacturerName: string | Nullish;
  manufacturerNumber: string | Nullish;
  supplierOrderItem: GetSupplierOrderItemResponse | Nullish;
  currency: string | Nullish;
  // onRefresh: () => Promise<void>; // TODO once we have refreshing endpoint
}

export function ArticleDetailCard(props: ArticleDetailCardProps) {
  const warehouseId = props.supplierOrderItem?.article?.warehouseId;

  const name = props.supplierOrderItem?.article?.name;

  const warehouseAccountId = props.supplierOrderItem?.article?.warehouseAccount;

  const storageLocation = props.supplierOrderItem?.article?.storageLocation;

  const leafId = props.supplierOrderItem?.article?.treeFolder?.leafId;

  const {data: warehouseAccount, isLoading: isWarehouseAccountLoading} =
    useGetWarehouseAccountQuery(
      {warehouseAccountId: warehouseAccountId as string},
      {skip: isNilOrEmpty(warehouseAccountId)}
    );

  const {data: category, isLoading: isCategoryLoading} = useGetTreeFolderPathQuery(
    {leafId: leafId as string},
    {skip: isNilOrEmpty(leafId)}
  );

  const isLoading = isWarehouseAccountLoading || isCategoryLoading;

  const isItemExisting = isNotNil(props.supplierOrderItem?.article);

  const handleEditArticle = () => {
    window.open(
      composePath(warehouseRoutes.articleDetailOverview, {
        params: {warehouseId, id: props.articleId},
      }),
      '_blank'
    );
  };

  const prices = getArticleDetailCardPrices(props.supplierOrderItem?.article);

  const actions: Action[] = [
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('general.actions.refresh'),
      leftIcon: 'navigation/refresh',
      isDisabled: true, // TODO enable once BE is ready
      // onClick: props.onRefresh, // TODO
    },
    {
      type: 'button',
      variant: 'link',
      title: i18n.t('entity.warehouse.actions.editArticle'),
      leftIcon: 'action/open_in_new',
      onClick: handleEditArticle,
    },
  ];

  const rows: AttributesRow[] = [
    {
      label: i18n.t('entity.warehouse.labels.name'),
      value: name,
    },
    {
      label: i18n.t('entity.warehouse.labels.catalogueNumber'),
      value: props.manufacturerNumber,
    },
    {
      label: i18n.t('entity.warehouse.labels.manufacturer'),
      value: props.manufacturerName,
    },
    {
      label: i18n.t('entity.warehouse.labels.warehouseAccount'),
      value: warehouseAccount?.name,
    },
    {
      label: i18n.t('entity.warehouse.labels.storageLocation'),
      value: storageLocation,
    },
    {
      label: i18n.t('entity.warehouse.labels.category'),
      value: category?.treeFolder?.pathName,
    },
  ];
  return (
    <Card
      variant="inlineGrey"
      title={i18n.t('entity.warehouse.labels.articleDetail')}
      actions={isItemExisting ? actions : undefined}
      isExpandable
      data-testid={props['data-testid']}
    >
      <VStack>
        <DataStatus isLoading={isLoading} minHeight={49}>
          <Attributes rows={rows} />
        </DataStatus>
        <Separator spacing={3} />
        <ArticleDetailCardPrices prices={prices} currency={props.currency} />
      </VStack>
    </Card>
  );
}
