import {
  CustGetCkkConsentStateApiArg,
  CustGetCkkConsentStateApiResponse,
  CustGetExternalCustomersApiArg,
  CustGetExternalCustomersApiResponse,
  CustPostCkkConsentApiArg,
  CustPostCkkConsentApiResponse,
} from '../types/teasCustomer';
import {omneticApi as api} from './baseApi/omneticApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    custGetExternalCustomers: build.query<
      CustGetExternalCustomersApiResponse,
      CustGetExternalCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/esb/teas/api/customer/v1/external-search`,
        params: {
          name: queryArg.name,
          phoneNumber: queryArg.phoneNumber,
          email: queryArg.email,
          legalNumber: queryArg.legalNumber,
          make: queryArg.make,
          vehicleType: queryArg.vehicleType,
        },
      }),
    }),
    custPostCkkConsent: build.mutation<CustPostCkkConsentApiResponse, CustPostCkkConsentApiArg>({
      query: (queryArg) => ({
        url: `/esb/teas/api/customer/v1/ckkCreateConsent`,
        method: 'POST',
        headers: {
          'x-tenant': queryArg['x-tenant'] ?? undefined,
          'x-branch': queryArg['x-branch'] ?? undefined,
        },
        params: {
          customerId: queryArg.customerId,
          externalId: queryArg.externalId,
          vehicleType: queryArg.vehicleType,
          vehicleMake: queryArg.vehicleMake,
        },
      }),
    }),
    custGetCkkConsentState: build.query<
      CustGetCkkConsentStateApiResponse,
      CustGetCkkConsentStateApiArg
    >({
      query: (queryArg) => ({
        url: `/esb/teas/api/customer/v1/getCKKConsentState`,
        headers: {
          'x-tenant': queryArg['x-tenant'] ?? undefined,
          'x-branch': queryArg['x-branch'] ?? undefined,
        },
        params: {
          customerId: queryArg.customerId,
          ckkId: queryArg.ckkId,
          vehicleType: queryArg.vehicleType,
          vehiclemake: queryArg.vehiclemake,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCustGetExternalCustomersQuery,
  useCustPostCkkConsentMutation,
  useLazyCustGetCkkConsentStateQuery,
} = injectedRtkApi;
