import {isFeatureEnabled} from 'feature-flags';

import {useGetVehiclePhotosQuery, useListSaleVehiclePhotosByVehicleQuery} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';

export function useVehicleCoverPhoto(vehicleId: string) {
  const {data: vehiclePhotos} = useGetVehiclePhotosQuery(
    {vehicleId},
    {
      skip: isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS),
    }
  );

  const {data: saleVehiclePhotos} = useListSaleVehiclePhotosByVehicleQuery(
    {
      vehicleId,
    },
    {
      skip: !isFeatureEnabled(featureFlags.ACL_VEHICLE_PHOTOS),
    }
  );

  const photos = saleVehiclePhotos?.photos ?? vehiclePhotos ?? [];

  const coverPhoto = photos?.find((photo) => photo.isCoverPhoto) ?? photos?.[0];

  return {
    photos,
    coverPhoto,
  };
}
