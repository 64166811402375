import {useAnimatedPopper} from 'platform/components';
import {Box, Icon, Text, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {MenuItemProps} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {suffixTestId, TestIdProps, useOutsideClick} from 'shared';

import {MenuLink} from './MenuLink';
import {SidebarButton} from './SidebarButton';

interface SidebarCreateEntityShortcutProps extends TestIdProps {
  menuItems: MenuItemProps[];
  menuItemsById: Record<string, MenuItemProps>;
}

export function SidebarCreateEntityShortcut(props: SidebarCreateEntityShortcutProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: 'right-end',
    isOutsideClickIgnored: true,
    gutter: 8,
  });

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <Box ref={ref}>
      <SidebarButton
        ref={referenceRef}
        isActive={isOpen}
        onClick={togglePopper}
        data-testid={suffixTestId('sidebar-create-entity-shortcut', props)}
      >
        <Icon color="text.white" size={5} value="sidebar/add" />
      </SidebarButton>
      <Popper {...popperProps}>
        <Box
          padding={2}
          borderRadius="medium"
          overflowY="auto"
          maxHeight="100vh"
          backgroundColor="palettes.neutral.900.100"
        >
          <VStack>
            {props.menuItems.map((menuItem) => (
              <MenuLink to={menuItem.path} key={menuItem.id} onClick={closePopper}>
                <Text size="small" color="white">
                  {i18n.t(menuItem.layoutProps.title)}
                </Text>
              </MenuLink>
            ))}
          </VStack>
        </Box>
      </Popper>
    </Box>
  );
}
