import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {useState} from 'react';

import {descend, isEmpty, isNil, reject} from 'ramda';

import {
  PartialVehiclePhotosRoutes,
  useGetVehicleQuery,
  useLazyGetAuditDataQuery,
  useLazyGetMetadaServiceCaseQuery,
  useLazyGetServiceCheckInQuery,
  useLazyGetServiceHandOverQuery,
  VehicleAlbums,
} from '@omnetic-dms/api';
import {getGridPhotos, getGridPhotosFromAudit, useBatchDownload} from '@omnetic-dms/shared';
import {DownloadDialog} from '@omnetic-dms/teas';

import {useOnMount, useRequiredParams} from 'shared';

import {ServiceCasePhotosCard, ServiceCasePhotosCardProps} from './ServiceCasePhotosCard';

export function ServiceCasePhotos() {
  const {id} = useRequiredParams();

  const [serviceCaseCards, setServiceCaseCards] = useState<ServiceCasePhotosCardProps[]>([]);
  const [isPhotosLoading, setIsPhotosLoading] = useState(true);
  const [isPhotosError, setIsPhotosError] = useState(false);

  const {handleDownload, eventData, isLoading, isStatePending} = useBatchDownload();

  const {
    data: vehicle,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
  } = useGetVehicleQuery({vehicleId: id});

  const [lazyGetMetadaServiceCase] = useLazyGetMetadaServiceCaseQuery();
  const [lazyGetServiceCheckin] = useLazyGetServiceCheckInQuery();
  const [lazyGetServiceHandover] = useLazyGetServiceHandOverQuery();
  const [lazyGetAuditData] = useLazyGetAuditDataQuery();

  const handleBatchDownload = (albumId: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.vehicle,
      vehicleId: id,
      albumId,
    });

  const handleInspectionGalleryBatchDownload = (auditId?: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.inspections,
      vehicleId: id,
      auditId,
    });

  const albums = vehicle?.albums
    ?.filter(({name}) => name === VehicleAlbums.PHOTOS_SERVICE_CASE)
    .sort(descend((album) => album.createdAt));

  useOnMount(() => {
    const promises = albums?.map(async (album) => {
      if (!album.serviceCaseId) {
        return;
      }

      const serviceCase = await lazyGetMetadaServiceCase({
        serviceCaseId: album.serviceCaseId,
      }).unwrap();

      const checkin = await lazyGetServiceCheckin({
        serviceCaseId: album.serviceCaseId,
      }).unwrap();
      const handover = await lazyGetServiceHandover({
        serviceCaseId: album.serviceCaseId,
      }).unwrap();

      const checkinAudit = checkin?.inspectionId?.[0]
        ? await lazyGetAuditData({auditId: checkin.inspectionId?.[0]}).unwrap()
        : null;
      const handoverAudit = handover?.inspectionId?.[0]
        ? await lazyGetAuditData({
            auditId: handover.inspectionId?.[0],
          }).unwrap()
        : null;

      const serviceCasePhotos = getGridPhotos(album.id)(vehicle?.photos);
      const checkinPhotos = getGridPhotosFromAudit(checkinAudit?.[0]?.categories);
      const handoverPhotos = getGridPhotosFromAudit(handoverAudit?.[0]?.categories);

      return isEmpty(serviceCasePhotos) && isEmpty(checkinPhotos) && isEmpty(handoverPhotos)
        ? null
        : {
            serviceCase,
            serviceCasePhotos,
            checkinPhotos,
            handoverPhotos,
            onDownloadAll: () => handleBatchDownload(album.id),
            onDownloadCheckin: () => handleInspectionGalleryBatchDownload(checkinAudit?.[0]?.id),
            onDownloadHandover: () => handleInspectionGalleryBatchDownload(handoverAudit?.[0]?.id),
          };
    });

    Promise.all(promises ?? [])
      .then((data) => {
        setServiceCaseCards(reject(isNil, data ?? []));
        setIsPhotosLoading(false);
      })
      .catch(() => {
        setIsPhotosError(true);
      });
  });

  return (
    <>
      <DataStatus
        isLoading={isVehicleLoading || isPhotosLoading}
        isError={isVehicleError || isPhotosError}
        isEmpty={isEmpty(serviceCaseCards)}
      >
        <VStack spacing={4}>
          {serviceCaseCards.map((card) => (
            <ServiceCasePhotosCard key={card.serviceCase?.id} {...card} />
          ))}
        </VStack>
      </DataStatus>
      <DownloadDialog
        withWrapper
        isOpen={isLoading || isStatePending}
        fileName={eventData?.data?.bulkName}
        fileCount={eventData?.data?.objects.length}
      />
    </>
  );
}
