import {
  PostSmallUnitMaterialApiResponse,
  PostSmallUnitMaterialApiArg,
  GetSmallUnitMaterialApiResponse,
  GetSmallUnitMaterialApiArg,
  DeleteSmallUnitMaterialApiResponse,
  DeleteSmallUnitMaterialApiArg,
  PatchSmallUnitMaterialApiResponse,
  PatchSmallUnitMaterialApiArg,
} from '../types/metadaWorkshopMinorUnitMaterial';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWorkshopSmallUnitMaterialApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    postSmallUnitMaterialApi: build.mutation<
      PostSmallUnitMaterialApiResponse,
      PostSmallUnitMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/small-unit-material`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSmallUnitMaterialApi: build.query<
      GetSmallUnitMaterialApiResponse,
      GetSmallUnitMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/small-unit-material/${queryArg.smallUnitMaterialId}`,
      }),
    }),
    deleteSmallUnitMaterialApi: build.mutation<
      DeleteSmallUnitMaterialApiResponse,
      DeleteSmallUnitMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/small-unit-material/${queryArg.smallUnitMaterialId}`,
        method: 'DELETE',
      }),
    }),
    patchSmallUnitMaterialApi: build.mutation<
      PatchSmallUnitMaterialApiResponse,
      PatchSmallUnitMaterialApiArg
    >({
      query: (queryArg) => ({
        url: `/service/v1/small-unit-material/${queryArg.smallUnitMaterialId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
  }),
});

export const {
  useDeleteSmallUnitMaterialApiMutation,
  useGetSmallUnitMaterialApiQuery,
  usePatchSmallUnitMaterialApiMutation,
  usePostSmallUnitMaterialApiMutation,
} = metadaWorkshopSmallUnitMaterialApi;
