import {diff} from 'deep-object-diff';
import {
  Button,
  ButtonGroup,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
  Segment,
  Separator,
  closeCurrentDialog,
  showNotification,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {object} from 'yup';

import {useEffect, useRef, useState} from 'react';
import {DeepPartial, UseFormReturn} from 'react-hook-form';

import {
  assocPath,
  clone,
  concat,
  defaultTo,
  flip,
  head,
  isEmpty,
  isNil,
  last,
  not,
  path,
  pipe,
} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  DeliveryQuantityMappingRequest,
  GetSupplierOrderItemResponse,
  PatchSupplierOrderItemRequest,
  SupplierOrderItemChangedField,
  useGetHandlingUnitQuery,
  useGetHandlingUnitsQuery,
  useGetManufacturerQuery,
  useGetSupplierOrderItemQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
  usePatchSupplierOrderItemMutation,
  usePutDeliveryQuantityMappingMutation,
  usePutSupplierOrderItemCalculationMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {DEFAULT_CURRENCY, handleApiError} from '@omnetic-dms/shared';

import {
  Nullish,
  RequiredTestIdProps,
  sanitizeObject,
  suffixTestId,
  yupNumber,
  yupString,
} from 'shared';

import {AddSupplierOrderItemForm} from '../../../../../types/AddSupplierOrderItemForm';
import {filterElementsBySuffixMatch} from '../../../../../utils/filterElementsBySuffixMatch';
import {getVatRatesByTenantCountry} from '../../../../../utils/getVatRatesByTenantCountry';
import {objectPaths} from '../../../../../utils/objectPaths';
import {removeKeys} from '../../../../../utils/removeKeys';
import {ArticleDetailCard} from './ArticleDetailCard';
import {AvailabilitySegment} from './AvailabilitySegment';
import {DeliveriesSegment} from './DeliveriesSegment';
import {OrderDetailForm} from './OrderDetailForm';
import {RequestDetailForm} from './RequestDetailForm';
import {RequestsSegment} from './RequestsSegment';
import {SupplierToWarehouseMappingForm} from './SupplierToWarehouseMappingForm';

const ABORT_ERROR = 'AbortError';

enum Segments {
  AVAILABILITY = 'availability',
  REQUESTS = 'requests',
  DELIVERIES = 'deliveries',
}

interface EditItemProps extends RequiredTestIdProps {
  supplierOrderId: string;
  supplierOrderItemId: string;
  onSubmit?: VoidFunction;
}

export function EditItem(props: EditItemProps) {
  const [activeSegment, setActiveSegment] = useState<Segments>(Segments.AVAILABILITY);

  const {
    data: supplierOrderItem,
    isLoading: isSupplierOrderItemLoading,
    isError: hasSupplierOrderItemError,
  } = useGetSupplierOrderItemQuery(
    {
      orderId: props.supplierOrderId,
      itemId: props.supplierOrderItemId,
    },
    {skip: isNilOrEmpty(props.supplierOrderItemId)}
  );

  const [recalculateSupplierOrderItemPrices] = usePutSupplierOrderItemCalculationMutation();

  const [saveMapping, {isLoading: isSaveMappingLoading}] = usePutDeliveryQuantityMappingMutation();

  const [patchSupplierOrderItem, {isLoading: isPatchSupplierOrderItemLoading}] =
    usePatchSupplierOrderItemMutation();

  const {data: handlingUnits, isLoading: areHandlingUnitsLoading} = useGetHandlingUnitsQuery();

  const {data: handlingUnit} = useGetHandlingUnitQuery(
    {id: supplierOrderItem?.article?.handlingUnit as string},
    {skip: isNil(supplierOrderItem?.article?.handlingUnit)}
  );

  const {data: manufacturer} = useGetManufacturerQuery(
    {manufacturerId: supplierOrderItem?.orderDetail?.manufacturerId},
    {skip: isNil(supplierOrderItem?.orderDetail?.manufacturerId)}
  );

  const {data: tenant} = useGetTenantQuery();

  const {data: vatRates, isLoading: areVatRatesLoading} = useGetVatRatesQuery();

  const vatRatesByTenantCountry = getVatRatesByTenantCountry(vatRates, tenant?.country)?.rates;

  const articleId = supplierOrderItem?.article?.id;

  const manufacturerNumber = supplierOrderItem?.article?.manufacturerNumber;

  const currency = defaultTo(DEFAULT_CURRENCY, supplierOrderItem?.orderDetail?.currency);

  const defaultValues: DeepPartial<AddSupplierOrderItemForm> = {
    orderDetail: {
      supplierQuantity: defaultTo(1, supplierOrderItem?.orderDetail?.supplierQuantity),
      supplierUnit: supplierOrderItem?.orderDetail?.supplierUnit,
      supplierUnitPrice: defaultTo(0, supplierOrderItem?.orderDetail?.supplierUnitPrice),
      supplierTotalPrice: defaultTo(0, supplierOrderItem?.orderDetail?.supplierTotalPrice),
      name: supplierOrderItem?.orderDetail?.name,
      catalogueNumber: supplierOrderItem?.orderDetail?.catalogueNumber,
      manufacturerId: supplierOrderItem?.orderDetail?.manufacturerId,
      supplierOrderingNumber: supplierOrderItem?.orderDetail?.supplierOrderingNumber,
      vat: defaultTo('S', supplierOrderItem?.orderDetail?.vat),
      vin: supplierOrderItem?.orderDetail?.vin,
      keyCode: supplierOrderItem?.orderDetail?.keyCode,
      isIntelligentPart: supplierOrderItem?.orderDetail?.isInteligentPart,
    },
    requestDetail: {
      warehouseRequestedQuantity: defaultTo(
        1,
        supplierOrderItem?.requestDetail?.warehouseRequestedQuantity
      ),
      warehouseUnitPurchasePrice: defaultTo(
        0,
        supplierOrderItem?.requestDetail?.warehouseUnitPurchasePrice
      ),
    },
    supplierToWarehouseMapping: {
      supplierQuantityMapping: defaultTo(
        1,
        supplierOrderItem?.supplierToWarehouseMapping?.supplierQuantityMapping
      ),
      supplierUnitMapping: supplierOrderItem?.supplierToWarehouseMapping?.supplierUnitMapping,
      warehouseQuantityMapping: defaultTo(
        1,
        supplierOrderItem?.supplierToWarehouseMapping?.warehouseQuantityMapping
      ),
      warehouseUnitMapping: supplierOrderItem?.supplierToWarehouseMapping?.warehouseUnitMapping,
    },
  };

  // Needed for proper recalculations
  const prevFormValues = useRef<DeepPartial<AddSupplierOrderItemForm>>(defaultValues);
  // Needed for cancelling recalculating requests from the form onChange event
  const abortControllerInstance = useRef<AbortController | Nullish>();

  useEffect(() => {
    if (isNil(supplierOrderItem)) {
      return;
    }

    // Set the prevFormValues, we need them for proper price calculations
    handleMapPrevFormValues(supplierOrderItem);
  }, [supplierOrderItem]);

  const handleSubmit: FormSubmitHandler<AddSupplierOrderItemForm> = async (values) => {
    const patchRequest: PatchSupplierOrderItemRequest = {
      orderId: props.supplierOrderId,
      itemId: defaultTo('', props.supplierOrderItemId),
      body: {
        ...values,
        article: {
          id: defaultTo('', articleId),
        },
        orderDetail: {
          supplierQuantity: values.orderDetail.supplierQuantity,
          supplierUnit: values.orderDetail.supplierUnit,
          supplierUnitPrice: values.orderDetail.supplierUnitPrice,
          supplierTotalPrice: values.orderDetail.supplierTotalPrice,
          supplierOrderingNumber: values.orderDetail.supplierOrderingNumber,
          vat: values.orderDetail.vat,
          vin: values.orderDetail.vin,
          keyCode: values.orderDetail.keyCode,
          isIntelligentPart: values.orderDetail.isIntelligentPart,
          currency,
        },
      },
    };

    await patchSupplierOrderItem(patchRequest)
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('entity.warehouse.notifications.supplierOrderItemUpdated'))
      )
      .then(props.onSubmit)
      .then(closeCurrentDialog)
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<AddSupplierOrderItemForm>,
    // We don't know the correct type here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
    formApi: UseFormReturn<AddSupplierOrderItemForm>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values));
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of the changed field
    const changedField = last<string>(change.name?.split('.') ?? []);
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_OBJECTS_KEYS.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(difference, concat(ALLOWED_OBJECTS_KEYS, ALLOWED_OBJECTS));

    const isOrderDetailDiffEmpty = allowedDifference?.orderDetail
      ? isEmpty(allowedDifference?.orderDetail)
      : false;
    const isRequestDetailDiffEmpty = allowedDifference?.requestDetail
      ? isEmpty(allowedDifference?.requestDetail)
      : false;
    const isSupplierToWarehouseMappingDiffEmpty = allowedDifference?.supplierToWarehouseMapping
      ? isEmpty(allowedDifference?.supplierToWarehouseMapping)
      : false;

    const isDiffEmpty =
      isOrderDetailDiffEmpty && isRequestDetailDiffEmpty && isSupplierToWarehouseMappingDiffEmpty;

    if (not(shouldTriggerRecalculation) || isNilOrEmpty(allowedDifference) || isDiffEmpty) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split('.') : asString;
    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_OBJECTS_KEYS)
    )?.split('.') as string[];
    const changedValue = path(pathToChangedValues ?? [], values);
    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const supplierOrderItemCalculateController = new AbortController();

    abortControllerInstance.current = supplierOrderItemCalculateController;

    recalculateSupplierOrderItemPrices({
      orderItemId: defaultTo('', props.supplierOrderItemId),
      body: {
        changedField: {field: changedField as SupplierOrderItemChangedField},
        orderDetail: {
          supplierQuantity: values.orderDetail?.supplierQuantity,
          supplierUnitPrice: values.orderDetail?.supplierUnitPrice,
        },
        requestDetail: {
          warehouseRequestedQuantity: values.requestDetail?.warehouseRequestedQuantity,
          warehouseUnitPurchasePrice: values.requestDetail?.warehouseUnitPurchasePrice,
        },
        supplierToWarehouseMapping: {
          supplierQuantityMapping: values.supplierToWarehouseMapping?.supplierQuantityMapping,
          warehouseQuantityMapping: values.supplierToWarehouseMapping?.warehouseQuantityMapping,
        },
      },
      signal: supplierOrderItemCalculateController.signal,
    })
      .unwrap()
      .then((response) => {
        // New values must have exactly the same structure as prevFormValues in handleMapPrevFormValues
        // for recalculations to work properly!
        const newValues = clone(
          sanitizeObject({
            orderDetail: {
              supplierQuantity: response.orderDetail.supplierQuantity,
              supplierUnit: values?.orderDetail?.supplierUnit,
              supplierUnitPrice: response.orderDetail.supplierUnitPrice,
              supplierTotalPrice: response.orderDetail.supplierTotalPrice,
              name: values?.orderDetail?.name,
              catalogueNumber: values?.orderDetail?.catalogueNumber,
              manufacturerId: values?.orderDetail?.manufacturerId,
              supplierOrderingNumber: values?.orderDetail?.supplierOrderingNumber,
              vat: defaultTo('S', values.orderDetail?.vat),
              vin: values?.orderDetail?.vin,
              keyCode: values?.orderDetail?.keyCode,
              isIntelligentPart: values?.orderDetail?.isIntelligentPart,
            },
            requestDetail: {
              warehouseRequestedQuantity: response.requestDetail.warehouseRequestedQuantity,
              warehouseUnitPurchasePrice: response.requestDetail.warehouseUnitPurchasePrice,
            },
            supplierToWarehouseMapping: {
              supplierQuantityMapping: response.supplierToWarehouseMapping.supplierQuantityMapping,
              supplierUnitMapping: values?.supplierToWarehouseMapping?.supplierUnitMapping,
              warehouseQuantityMapping:
                response.supplierToWarehouseMapping.warehouseQuantityMapping,
              warehouseUnitMapping: values?.supplierToWarehouseMapping?.warehouseUnitMapping,
            },
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((e) => {
        // If the request is aborted, then we don't want to show error notification
        if (e && e.error && isString(e.error) && e.error.includes(ABORT_ERROR)) {
          return;
        }
        handleApiError(e);
      });
  };

  const handleMapPrevFormValues = (data: GetSupplierOrderItemResponse | Nullish) => {
    prevFormValues.current = {
      orderDetail: {
        supplierQuantity: defaultTo(1, data?.orderDetail?.supplierQuantity),
        supplierUnit: data?.orderDetail?.supplierUnit,
        supplierUnitPrice: defaultTo(0, data?.orderDetail?.supplierUnitPrice),
        supplierTotalPrice: defaultTo(0, data?.orderDetail?.supplierTotalPrice),
        name: data?.orderDetail?.name,
        catalogueNumber: data?.orderDetail?.catalogueNumber,
        manufacturerId: data?.orderDetail?.manufacturerId,
        supplierOrderingNumber: data?.orderDetail?.supplierOrderingNumber,
        vat: defaultTo('S', data?.orderDetail?.vat),
        vin: data?.orderDetail?.vin,
        keyCode: data?.orderDetail?.keyCode,
        isIntelligentPart: data?.orderDetail?.isInteligentPart,
      },
      requestDetail: {
        warehouseRequestedQuantity: defaultTo(1, data?.requestDetail?.warehouseRequestedQuantity),
        warehouseUnitPurchasePrice: defaultTo(0, data?.requestDetail?.warehouseUnitPurchasePrice),
      },
      supplierToWarehouseMapping: {
        supplierQuantityMapping: defaultTo(
          1,
          data?.supplierToWarehouseMapping.supplierQuantityMapping
        ),
        supplierUnitMapping: data?.supplierToWarehouseMapping?.supplierUnitMapping,
        warehouseQuantityMapping: defaultTo(
          1,
          data?.supplierToWarehouseMapping?.warehouseQuantityMapping
        ),
        warehouseUnitMapping: data?.supplierToWarehouseMapping?.warehouseUnitMapping,
      },
    };
  };

  const handleSaveMapping = async (
    supplierToWarehouseMapping: GetSupplierOrderItemResponse['supplierToWarehouseMapping']
  ) => {
    if (
      isNil(articleId) ||
      isNil(supplierOrderItem?.article?.supplierId) ||
      isNil(manufacturerNumber)
    ) {
      throw new Error("Couldn't save mapping");
    }

    const requestBody: DeliveryQuantityMappingRequest = {
      body: {
        articleId,
        supplierId: supplierOrderItem?.article?.supplierId,
        manufacturer_number: manufacturerNumber,
        supplierOrderingNumber: supplierOrderItem?.orderDetail?.supplierOrderingNumber,
        supplierToWarehouseMapping: {
          deliveryQuantity: supplierToWarehouseMapping.supplierQuantityMapping,
          deliveryUnit: supplierToWarehouseMapping.supplierUnitMapping,
          receiveQuantity: supplierToWarehouseMapping.warehouseQuantityMapping,
        },
      },
    };

    await saveMapping(requestBody)
      .unwrap()
      .then(() => showNotification.success(i18n.t('entity.warehouse.notifications.mappingSaved')))
      .catch(handleApiError);
  };

  const handleDiscard = () => {
    closeCurrentDialog();
  };

  const segments = [
    {
      value: Segments.AVAILABILITY,
      label: i18n.t('entity.warehouse.labels.availability'),
      content: (
        <AvailabilitySegment
          articleId={articleId}
          manufacturerId={supplierOrderItem?.orderDetail?.manufacturerId}
          manufacturerNumber={supplierOrderItem?.orderDetail?.catalogueNumber}
          data-testid={suffixTestId('segments.availability', props)}
        />
      ),
    },
    {
      value: Segments.REQUESTS,
      label: i18n.t('entity.warehouse.labels.requests'),
      content: (
        <RequestsSegment
          orderItemId={props.supplierOrderItemId}
          data-testid={suffixTestId('segments.requests', props)}
        />
      ),
    },
    {
      value: Segments.DELIVERIES,
      label: i18n.t('entity.warehouse.labels.deliveries'),
      content: (
        <DeliveriesSegment
          orderItemId={props.supplierOrderItemId}
          data-testid={suffixTestId('segments.deliveries', props)}
        />
      ),
    },
  ];

  const segmentContent = segments.find((segment) => segment.value === activeSegment)?.content;

  return (
    <Form<AddSupplierOrderItemForm>
      key={defaultValues.orderDetail?.catalogueNumber}
      schema={formSchema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        // For controlling recalculations of prices
        formApi.watch((data, change) => handleChange(data, change, formApi));
        const supplierToWarehouseMapping = formApi.watch('supplierToWarehouseMapping');

        return (
          <VStack spacing={4}>
            <DataStatus
              isLoading={isSupplierOrderItemLoading}
              isError={hasSupplierOrderItemError}
              minHeight={290}
            >
              <OrderDetailForm
                control={control}
                manufacturer={manufacturer?.name}
                handlingUnit={handlingUnit?.name}
                handlingUnits={handlingUnits}
                areHandlingUnitsLoading={areHandlingUnitsLoading}
                vatRates={vatRatesByTenantCountry}
                areVatRatesLoading={areVatRatesLoading}
                currency={currency}
                data-testid={suffixTestId('sections.orderDetail', props)}
              />

              <Separator spacing={0} />

              <RequestDetailForm
                control={control}
                handlingUnit={handlingUnit?.name}
                currency={currency}
                data-testid={suffixTestId('sections.requestDetail', props)}
              />

              <Separator spacing={0} />

              <SupplierToWarehouseMappingForm
                control={control}
                handlingUnit={handlingUnit?.name}
                handlingUnits={handlingUnits}
                areHandlingUnitsLoading={areHandlingUnitsLoading}
                onSaveMapping={() => handleSaveMapping(supplierToWarehouseMapping)}
                isSaveMappingLoading={isSaveMappingLoading}
                data-testid={suffixTestId('sections.supplierToWarehouseMapping', props)}
              />

              <ArticleDetailCard
                articleId={articleId}
                manufacturerName={manufacturer?.name}
                manufacturerNumber={manufacturerNumber}
                supplierOrderItem={supplierOrderItem}
                currency={currency}
                data-testid={suffixTestId('sections.articleDetailCard', props)}
              />

              <HStack>
                <Segment<Segments>
                  value={activeSegment}
                  onChange={setActiveSegment}
                  options={segments}
                />
              </HStack>

              <Box>{segmentContent}</Box>

              <DialogFooter>
                <ButtonGroup align="right">
                  <Button
                    variant="secondary"
                    title={i18n.t('general.labels.discard')}
                    onClick={handleDiscard}
                    data-testid={suffixTestId('actions.discard', props)}
                  />
                  <FormButton
                    control={control}
                    type="submit"
                    variant="primary"
                    title={i18n.t('general.labels.saveChanges')}
                    isDisabled={isPatchSupplierOrderItemLoading}
                    data-testid={suffixTestId('actions.submit', props)}
                  />
                </ButtonGroup>
              </DialogFooter>
            </DataStatus>
          </VStack>
        );
      }}
    </Form>
  );
}

const formSchema = object({
  orderDetail: object({
    supplierQuantity: yupNumber.positive().required(),
    supplierUnit: yupString.required(),
    supplierUnitPrice: yupNumber.positive().required(),
    supplierTotalPrice: yupNumber.positive().required(),
    vat: yupString.required(),
    vin: yupString.max(50),
    keyCode: yupString.max(50),
  }),
  requestDetail: object({
    warehouseRequestedQuantity: yupNumber.positive().min(0.0001).required(),
  }),
  supplierToWarehouseMapping: object({
    supplierQuantityMapping: yupNumber.positive().required(),
    warehouseQuantityMapping: yupNumber.positive().required(),
  }),
});

const ALLOWED_OBJECTS = ['orderDetail', 'requestDetail', 'supplierToWarehouseMapping'];

// Nested keys from ALLOWED_OBJECTS
const ALLOWED_OBJECTS_KEYS = [
  'supplierQuantity',
  'supplierUnitPrice',
  'warehouseRequestedQuantity',
  'supplierQuantityMapping',
  'warehouseQuantityMapping',
];
