import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  isCurrency,
} from 'platform/components';
import {Box, Grid, Hide, HStack, Show, Space, VStack} from 'platform/foundation';
import * as Yup from 'yup';

import {useCallback, useMemo, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {isFalse, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  PatchVehiclePricesRequestBody,
  SaleVehicleResponseBody,
  selectTenant,
  useGetCurrenciesQuery,
  usePatchVehiclePricesMutation,
  VatRateType,
  VehiclePrice,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';

import {buildObject, CurrencyCodeType, suffixTestId, TestIdProps, yupString} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {useAdPlatformsVehicleUpdateDialog} from '../../hooks/useAdPlatformsVehicleUpdateDialog';
import {useGetComplexPermissions} from '../../hooks/useGetComplexPermissions';
import {useGetVatRatesOptions} from '../../hooks/useGetVatRatesOptions';
import {usePermissions} from '../../hooks/usePermissions/usePermissions';
import {handleApiError} from '../../utils/handleApiError';
import {NoPermissionTooltip} from '../NoPermissionTooltip/NoPermissionTooltip';
import {CalculatePrice} from '../VatDeductibleFormFields/_deprecated/CalculatePrice';
import {areFormFieldsDifferent} from './../../utils/areFormFieldsDifferent';

interface EditVehiclePriceFormProps extends TestIdProps {
  businessCaseId: string | null;
  saleVehicle?: SaleVehicleResponseBody;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  isPurchasePriceHidden?: boolean;
}

export function EditVehiclePriceForm(props: EditVehiclePriceFormProps) {
  const {data: tenant} = useSelector(selectTenant);
  const countryCode = tenant?.country;
  const currency = isCurrency(tenant?.currency) ? tenant?.currency : DEFAULT_CURRENCY;
  const {
    data: currencyOptions,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrenciesQuery();
  const [canEditSalePrice, canEditPurchasePrice] = usePermissions({
    permissionKeys: ['vehicleEditSalePrice', 'vehicleEditPurchasePrice'],
  });

  const {canReadSalePrice, canReadPurchasePrice} = useGetComplexPermissions();

  const [vatRates] = useGetVatRatesOptions();

  const [updateVehiclePrice] = usePatchVehiclePricesMutation();

  const [updateVehicleAds] = useAdPlatformsVehicleUpdateDialog();

  const [lastTouchedSellingField, setLastTouchedSellingField] = useState<
    'withVat' | 'withoutVat'
  >();
  const [lastTouchedPurchaseField, setLastTouchedPurchaseField] = useState<
    'withVat' | 'withoutVat'
  >();

  const [isCurrencyCardOpen, setCurrencyCardOpen] = useState<boolean>(
    isNotNilOrEmpty(props.saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat) ||
      isNotNilOrEmpty(props.saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat)
  );

  const defaultValues = useMemo(
    () => ({
      isVatDeductible: props.saleVehicle?.isVatDeductible || false,
      purchasePrice: props.saleVehicle?.purchasePrice?.withVat?.amount
        ? {
            ...props.saleVehicle?.purchasePrice,
            vatRate: undefined,
            vatCode: props.saleVehicle?.purchasePriceVatCode,
          }
        : {
            withVat: {amount: '', currency},
            withoutVat: {amount: '', currency},
            vatCode:
              props.saleVehicle?.purchasePriceVatCode ??
              (props.saleVehicle?.isVatDeductible ? 'S' : 'Z'),
          },
      sellingPrice: props.saleVehicle?.sellingPrice?.withVat?.amount
        ? {
            ...props.saleVehicle?.sellingPrice,
            vatRate: undefined,
            vatCode: props.saleVehicle?.salePriceVatCode,
          }
        : {
            withVat: {amount: '', currency},
            withoutVat: {amount: '', currency},
            vatCode:
              props.saleVehicle?.salePriceVatCode ??
              (props.saleVehicle?.isVatDeductible ? 'S' : 'Z'),
          },
      purchasePriceInForeignCurrency: {
        currency: props.saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode,
        withoutVat: props.saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat,
        withVat: props.saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat,
      },
      sellingPriceInForeignCurrency: {
        currency: props.saleVehicle?.sellingPriceIsForeignCurrency?.foreignCurrencyCode,
        withoutVat: props.saleVehicle?.sellingPriceIsForeignCurrency?.priceWithoutVat,
        withVat: props.saleVehicle?.sellingPriceIsForeignCurrency?.priceWithVat,
      },
      originalCarPrice: props.saleVehicle?.originalCarPrice?.amount ?? null,
      b2bSellingPrice: props.saleVehicle?.b2bSellingPrice?.withVat?.amount
        ? {
            ...props.saleVehicle?.b2bSellingPrice,
            vatCode: props.saleVehicle?.b2bSalePriceVatCode,
          }
        : {
            withVat: {amount: '', currency},
            withoutVat: {amount: '', currency},
            vatCode: 'Z',
          },
    }),
    [props.saleVehicle, currency]
  );

  const handleChangeVatCode = () => {
    if (!lastTouchedSellingField) {
      setLastTouchedSellingField('withVat');
    }
    if (!lastTouchedPurchaseField) {
      setLastTouchedPurchaseField('withVat');
    }
  };

  const handleDeductionChange = (formApi: UseFormReturn<Schema>) => (isChecked: boolean) => {
    if (!lastTouchedSellingField) {
      setLastTouchedSellingField('withVat');
    }
    if (!lastTouchedPurchaseField) {
      setLastTouchedPurchaseField('withVat');
    }

    if (isChecked) {
      formApi.setValue('purchasePrice.vatCode', 'S');
      formApi.setValue('sellingPrice.vatCode', 'S');
      formApi.setValue('b2bSellingPrice.vatCode', 'S');
    } else {
      formApi.setValue('purchasePrice.vatCode', 'Z');
      formApi.setValue('sellingPrice.vatCode', 'Z');
      formApi.setValue('b2bSellingPrice.vatCode', 'Z');
    }
  };

  const handleSubmit = async (values: Schema) => {
    const vehicleId = props.saleVehicle?.vehicleId ?? '';

    const purchasePrice =
      (values?.purchasePrice?.withoutVat?.amount === null &&
        values?.purchasePrice?.withVat?.amount !== null) ||
      (values?.purchasePrice?.withoutVat?.amount !== null &&
        values?.purchasePrice?.withVat?.amount === null) ||
      (values?.purchasePrice?.withoutVat?.amount && values?.purchasePrice?.withVat?.amount);

    const purchasePriceVatRate =
      vatRates?.find((rate) => rate?.type === values?.purchasePrice?.vatCode)?.rate ?? '0';

    const sellingPrice =
      (values.sellingPrice?.withoutVat?.amount === null &&
        values.sellingPrice?.withVat?.amount !== null) ||
      (values.sellingPrice?.withoutVat?.amount !== null &&
        values.sellingPrice?.withVat?.amount === null) ||
      (values.sellingPrice?.withoutVat?.amount && values.sellingPrice?.withVat?.amount);
    const sellingPriceVatRate =
      vatRates?.find((rate) => rate?.type === values?.sellingPrice?.vatCode)?.rate ?? '0';

    const hasB2bSellingPrice =
      !!values?.b2bSellingPrice?.withoutVat?.amount && !!values?.b2bSellingPrice?.withVat?.amount;
    const b2bSellingPriceVatRate =
      vatRates?.find((rate) => rate?.type === values?.b2bSellingPrice?.vatCode)?.rate ?? '0';

    const isVatDeductibleDifferent = areFormFieldsDifferent(
      values.isVatDeductible,
      defaultValues.isVatDeductible
    );

    const isPurchasePriceDifferent =
      areFormFieldsDifferent(
        values.purchasePrice?.withVat?.amount?.toString(),
        defaultValues.purchasePrice.withVat?.amount
      ) ||
      areFormFieldsDifferent(
        values.purchasePrice?.withoutVat?.amount?.toString(),
        defaultValues.purchasePrice.withoutVat?.amount
      ) ||
      areFormFieldsDifferent(values.purchasePrice?.vatCode, defaultValues.purchasePrice.vatCode);

    const isSellingPriceDifferent =
      areFormFieldsDifferent(
        values.sellingPrice?.withVat?.amount?.toString(),
        defaultValues.sellingPrice.withVat?.amount
      ) ||
      areFormFieldsDifferent(
        values.sellingPrice?.withoutVat?.amount?.toString(),
        defaultValues.sellingPrice.withoutVat?.amount
      ) ||
      areFormFieldsDifferent(values.sellingPrice?.vatCode, defaultValues.sellingPrice.vatCode);

    const isPurchasePriceInForeignCurrencyDifferent =
      areFormFieldsDifferent(
        values.purchasePriceInForeignCurrency?.currency,
        defaultValues.purchasePriceInForeignCurrency.currency
      ) ||
      areFormFieldsDifferent(
        values.purchasePriceInForeignCurrency?.withoutVat?.toString(),
        defaultValues.purchasePriceInForeignCurrency.withoutVat
      ) ||
      areFormFieldsDifferent(
        values.purchasePriceInForeignCurrency?.withVat?.toString(),
        defaultValues.purchasePriceInForeignCurrency.withVat
      );

    const isSellingPriceInForeignCurrencyDifferent =
      areFormFieldsDifferent(
        values.sellingPriceInForeignCurrency?.currency,
        defaultValues.sellingPriceInForeignCurrency.currency
      ) ||
      areFormFieldsDifferent(
        values.sellingPriceInForeignCurrency?.withoutVat?.toString(),
        defaultValues.sellingPriceInForeignCurrency.withoutVat
      ) ||
      areFormFieldsDifferent(
        values.sellingPriceInForeignCurrency?.withVat?.toString(),
        defaultValues.sellingPriceInForeignCurrency.withVat
      );

    const isOriginalCarPriceDifferent = areFormFieldsDifferent(
      values.originalCarPrice,
      defaultValues.originalCarPrice
    );

    const isB2bSellingPriceDifferent =
      areFormFieldsDifferent(
        values.b2bSellingPrice?.withVat?.amount?.toString(),
        defaultValues.b2bSellingPrice.withVat?.amount
      ) ||
      areFormFieldsDifferent(
        values.b2bSellingPrice?.withoutVat?.amount?.toString(),
        defaultValues.b2bSellingPrice.withoutVat?.amount
      ) ||
      areFormFieldsDifferent(
        values.b2bSellingPrice?.vatCode,
        defaultValues.b2bSellingPrice.vatCode
      );

    const patchVehiclePricesData = buildObject<PatchVehiclePricesRequestBody>()
      .isVatDeductible(values.isVatDeductible, isVatDeductibleDifferent)
      .purchasePrice(
        {
          vatCode: values.purchasePrice?.vatCode ?? 'Z',
          withoutVat: values.purchasePrice?.withoutVat?.amount?.toString() ?? null,
          withVat: values.purchasePrice?.withVat?.amount?.toString() ?? null,
          vatRate: purchasePriceVatRate,
        },
        purchasePrice && isPurchasePriceDifferent
      )
      .sellingPrice(
        {
          vatCode: values.sellingPrice?.vatCode ?? 'Z',
          withoutVat: values.sellingPrice?.withoutVat?.amount?.toString() ?? null,
          withVat: values.sellingPrice?.withVat?.amount?.toString() ?? null,
          vatRate: sellingPriceVatRate,
        },
        sellingPrice && isSellingPriceDifferent
      )
      .purchasePriceInForeignCurrency(
        {
          foreignCurrencyCode: values.purchasePriceInForeignCurrency?.currency ?? DEFAULT_CURRENCY,
          priceWithoutVat: values.purchasePriceInForeignCurrency?.withoutVat?.toString() ?? '',
          priceWithVat: values.purchasePriceInForeignCurrency?.withVat?.toString() ?? '',
        },
        isCurrencyCardOpen && isPurchasePriceInForeignCurrencyDifferent
      )
      .sellingPriceInForeignCurrency(
        {
          foreignCurrencyCode: values.sellingPriceInForeignCurrency?.currency ?? DEFAULT_CURRENCY,
          priceWithoutVat: values.sellingPriceInForeignCurrency?.withoutVat?.toString() ?? '',
          priceWithVat: values.sellingPriceInForeignCurrency?.withVat?.toString() ?? '',
        },
        isCurrencyCardOpen && isSellingPriceInForeignCurrencyDifferent
      )
      .originalCarPrice(values.originalCarPrice, isOriginalCarPriceDifferent)
      .updateReasonNote(values.updateReasonNote, values.updateReasonNote)
      .b2bSellingPrice(
        hasB2bSellingPrice
          ? {
              vatCode: values.b2bSellingPrice?.vatCode ?? 'Z',
              withoutVat: values.b2bSellingPrice?.withoutVat?.amount?.toString() ?? null,
              withVat: values.b2bSellingPrice?.withVat?.amount?.toString() ?? null,
              vatRate: b2bSellingPriceVatRate,
            }
          : {
              vatCode: values.isVatDeductible ? 'S' : 'Z',
              withoutVat: null,
              withVat: null,
              vatRate: b2bSellingPriceVatRate,
            },
        isB2bSellingPriceDifferent
      )
      .build();

    await updateVehiclePrice({
      vehicleId,
      patchVehiclePricesRequestBody: patchVehiclePricesData,
    })
      .unwrap()
      .then(() => {
        props.onClose();
        props.onSubmit?.();
      })
      .then(() => {
        updateVehicleAds(vehicleId);
      })
      .catch(handleApiError);
  };

  const handleCalculationChange = useCallback(
    (
      formApi: UseFormReturn<Schema>,
      name:
        | 'purchasePrice'
        | 'sellingPrice'
        | 'purchasePriceInForeignCurrency'
        | 'sellingPriceInForeignCurrency'
        | 'b2bSellingPrice'
    ) =>
      (res: {withVat?: string} | {withoutVat?: string}) => {
        if ('withVat' in res) {
          if (name === 'purchasePrice' || name === 'sellingPrice' || name === 'b2bSellingPrice') {
            // @ts-ignore - if it is sellingPrice || purchasePrice the format will always be {name}.withVat.amount
            formApi.setValue(`${name}.withVat.amount`, res?.withVat || '');
          } else {
            formApi.setValue(`${name}.withVat`, res?.withVat || '');
          }
        }

        if ('withoutVat' in res) {
          if (name === 'purchasePrice' || name === 'sellingPrice' || name === 'b2bSellingPrice') {
            // @ts-ignore - if it is sellingPrice || purchasePrice the format will always be {name}.withVat.amount
            formApi.setValue(`${name}.withoutVat.amount`, res?.withoutVat || '');
          } else {
            formApi.setValue(`${name}.withoutVat`, res?.withoutVat || '');
          }
        }
      },
    []
  );

  return (
    /**
     * TODO: Refacotor this part - [T20-17714]
     */
    <Form<Schema> schema={schema} defaultValues={defaultValues} onSubmit={handleSubmit}>
      {(control, formApi) => {
        const purchasePriceVatCode = formApi.watch('purchasePrice.vatCode');
        const sellingPriceVatCode = formApi.watch('sellingPrice.vatCode');
        const isVatDeductible = formApi.watch('isVatDeductible');
        const [
          purchasePriceWithVat,
          purchasePriceWithoutVat,
          sellingPriceWithVat,
          sellingPriceWithoutVat,
          purchaseCurrencyWithVat,
          purchaseCurrencyWithoutVat,
          sellingCurrencyWithVat,
          sellingCurrencyWithoutVat,
          b2bSellingPriceWithVat,
          b2bSellingPriceWithoutVat,
          b2bSellingPriceVatCode,
        ] = formApi.watch([
          'purchasePrice.withVat.amount',
          'purchasePrice.withoutVat.amount',
          'sellingPrice.withVat.amount',
          'sellingPrice.withoutVat.amount',
          'purchasePriceInForeignCurrency.withVat',
          'purchasePriceInForeignCurrency.withoutVat',
          'sellingPriceInForeignCurrency.withVat',
          'sellingPriceInForeignCurrency.withoutVat',
          'b2bSellingPrice.withVat.amount',
          'b2bSellingPrice.withoutVat.amount',
          'b2bSellingPrice.vatCode',
        ]);

        return (
          <VStack spacing={4}>
            {/* PURCHASE PRICE CALCULATION */}
            <CalculatePrice
              amount={
                lastTouchedPurchaseField === 'withVat'
                  ? purchasePriceWithVat
                  : purchasePriceWithoutVat
              }
              countryCode={countryCode}
              type={lastTouchedPurchaseField}
              vatCode={purchasePriceVatCode as VatRateType}
              onChange={handleCalculationChange(formApi, 'purchasePrice')}
            />
            {/* SELLING PRICE CALCULATION */}
            <CalculatePrice
              amount={
                lastTouchedSellingField === 'withVat' ? sellingPriceWithVat : sellingPriceWithoutVat
              }
              countryCode={countryCode}
              type={lastTouchedSellingField}
              vatCode={sellingPriceVatCode as VatRateType}
              onChange={handleCalculationChange(formApi, 'sellingPrice')}
            />
            {/* PURCHASE PRICE IN FOREIGN CURRENCY CALCULATION */}
            <CalculatePrice
              amount={
                lastTouchedPurchaseField === 'withVat'
                  ? (purchaseCurrencyWithVat ?? '')
                  : (purchaseCurrencyWithoutVat ?? '')
              }
              countryCode={countryCode}
              type={lastTouchedPurchaseField}
              vatCode={purchasePriceVatCode as VatRateType}
              onChange={handleCalculationChange(formApi, 'purchasePriceInForeignCurrency')}
            />
            {/* SELLING PRICE IN FOREIGN CURRENCY PRICE CALCULATION */}
            <CalculatePrice
              amount={
                lastTouchedSellingField === 'withVat'
                  ? (sellingCurrencyWithVat ?? '')
                  : (sellingCurrencyWithoutVat ?? '')
              }
              countryCode={countryCode}
              type={lastTouchedSellingField}
              vatCode={sellingPriceVatCode as VatRateType}
              onChange={handleCalculationChange(formApi, 'sellingPriceInForeignCurrency')}
            />
            {/* SELLING PRICE B2B CALCULATION */}
            <CalculatePrice
              amount={
                lastTouchedSellingField === 'withVat'
                  ? b2bSellingPriceWithVat
                  : b2bSellingPriceWithoutVat
              }
              countryCode={countryCode}
              type={lastTouchedSellingField}
              vatCode={b2bSellingPriceVatCode as VatRateType}
              onChange={handleCalculationChange(formApi, 'b2bSellingPrice')}
            />
            <FormField
              data-testid={suffixTestId('isVatDeductible', props)}
              type="switch"
              name="isVatDeductible"
              control={control}
              isDisabled={props.saleVehicle?.isClosedPurchaseBusinessCase}
              label={i18n.t('entity.vehicle.labels.deductionVehicle')}
              onChange={handleDeductionChange(formApi)}
            />
            <Hide when={props.isPurchasePriceHidden}>
              <HStack spacing={4}>
                <Show when={canReadPurchasePrice}>
                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        data-testid={suffixTestId('purchasePriceAmountWithoutVat', props)}
                        type="currency"
                        currency={currency}
                        decimalPlaces={2}
                        isDisabled={
                          props.saleVehicle?.isClosedPurchaseBusinessCase ||
                          isFalse(canEditPurchasePrice)
                        }
                        control={control}
                        name="purchasePrice.withoutVat.amount"
                        label={i18n.t('general.labels.purchasePriceWithoutVat')}
                        onChange={() => setLastTouchedPurchaseField('withoutVat')}
                      />
                    </NoPermissionTooltip>
                  </Box>

                  <Box width={25}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        data-testid={suffixTestId('purchasePriceVatCode', props)}
                        type="choice"
                        name="purchasePrice.vatCode"
                        options={vatRates}
                        control={control}
                        label={i18n.t('general.labels.vat')}
                        isDisabled={
                          props.saleVehicle?.isClosedPurchaseBusinessCase ||
                          !isVatDeductible ||
                          isFalse(canEditPurchasePrice)
                        }
                        onChange={handleChangeVatCode}
                        isNotClearable
                        menuInPortal
                      />
                    </NoPermissionTooltip>
                  </Box>

                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        data-testid={suffixTestId('purchasePriceAmountWithVat', props)}
                        type="currency"
                        currency={currency}
                        decimalPlaces={2}
                        control={control}
                        isDisabled={
                          props.saleVehicle?.isClosedPurchaseBusinessCase ||
                          isFalse(canEditPurchasePrice)
                        }
                        name="purchasePrice.withVat.amount"
                        label={i18n.t('general.labels.purchasePriceWithVat')}
                        onChange={() => setLastTouchedPurchaseField('withVat')}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </Show>
              </HStack>
            </Hide>
            <HStack spacing={4}>
              <Show when={canReadSalePrice}>
                <Box flex={1}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceAmountWithoutVat', props)}
                      type="currency"
                      currency={currency}
                      decimalPlaces={2}
                      control={control}
                      name="sellingPrice.withoutVat.amount"
                      label={i18n.t('general.labels.sellingPriceWithoutVat')}
                      onChange={() => setLastTouchedSellingField('withoutVat')}
                      isDisabled={isFalse(canEditSalePrice)}
                    />
                  </NoPermissionTooltip>
                </Box>

                <Box width={25}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceVatCode', props)}
                      type="choice"
                      name="sellingPrice.vatCode"
                      options={vatRates}
                      control={control}
                      label={i18n.t('general.labels.vat')}
                      isDisabled={!isVatDeductible || isFalse(canEditSalePrice)}
                      onChange={handleChangeVatCode}
                      isNotClearable
                      menuInPortal
                    />
                  </NoPermissionTooltip>
                </Box>

                <Box flex={1}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceAmountWithVat', props)}
                      type="currency"
                      currency={currency}
                      decimalPlaces={2}
                      control={control}
                      name="sellingPrice.withVat.amount"
                      label={i18n.t('general.labels.sellingPriceWithVat')}
                      onChange={() => setLastTouchedSellingField('withVat')}
                      isDisabled={isFalse(canEditSalePrice)}
                    />
                  </NoPermissionTooltip>
                </Box>
              </Show>
            </HStack>
            <HStack spacing={4}>
              <Show when={canReadSalePrice}>
                <Box flex={1}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceB2BAmountWithoutVat', props)}
                      type="currency"
                      currency={currency}
                      decimalPlaces={2}
                      control={control}
                      name="b2bSellingPrice.withoutVat.amount"
                      label={i18n.t('general.labels.sellingPriceB2BWithoutVat')}
                      onChange={() => setLastTouchedSellingField('withoutVat')}
                      isDisabled={isFalse(canEditSalePrice)}
                      tooltip={i18n.t('general.labels.sellingPriceB2BTooltip')}
                    />
                  </NoPermissionTooltip>
                </Box>

                <Box width={25}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceB2BVatCode', props)}
                      type="choice"
                      name="b2bSellingPrice.vatCode"
                      options={vatRates}
                      control={control}
                      label={i18n.t('general.labels.vat')}
                      isDisabled={!isVatDeductible || isFalse(canEditSalePrice)}
                      onChange={handleChangeVatCode}
                      isNotClearable
                      menuInPortal
                    />
                  </NoPermissionTooltip>
                </Box>

                <Box flex={1}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                    <FormField
                      data-testid={suffixTestId('sellingPriceB2BAmountWithVat', props)}
                      type="currency"
                      currency={currency}
                      decimalPlaces={2}
                      control={control}
                      name="b2bSellingPrice.withVat.amount"
                      label={i18n.t('general.labels.sellingPriceB2BWithVat')}
                      onChange={() => setLastTouchedSellingField('withVat')}
                      isDisabled={isFalse(canEditSalePrice)}
                      tooltip={i18n.t('general.labels.sellingPriceB2BTooltip')}
                    />
                  </NoPermissionTooltip>
                </Box>
              </Show>
            </HStack>
            <Show whenFeatureEnabled={featureFlags.SALES_VEHICLE_ORIGINAL_PRICE}>
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    data-testid={suffixTestId('vehicleOriginalPrice', props)}
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    control={control}
                    name="originalCarPrice"
                    label={i18n.t('general.labels.vehicleOriginalPrice')}
                    tooltip={i18n.t('general.labels.vehicleOriginalPriceTooltip')}
                  />
                </Box>
                <Box width={25} />
                <Space fillAvailable />
              </HStack>
            </Show>
            <Card
              variant="inlineGrey"
              isExpanded={isCurrencyCardOpen}
              control={{
                type: 'switch',
                value: isCurrencyCardOpen,
                onChange: () => setCurrencyCardOpen(!isCurrencyCardOpen),
                'data-testid': suffixTestId('vehicle-foreign-price', props),
              }}
              title={i18n.t('page.vehicle.labels.foreignCurrencyPrices')}
            >
              <Grid columns={3}>
                <Show when={canReadPurchasePrice}>
                  <DataStatus isLoading={isCurrenciesLoading} isError={isCurrenciesError}>
                    <Box flex={1}>
                      <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                        <FormField
                          data-testid={suffixTestId('purchaseCurrency', props)}
                          type="choice"
                          options={currencyOptions
                            ?.filter((option) => option.code !== currency)
                            .map((option) => ({value: option.code, label: option.code}))}
                          control={control}
                          name="purchasePriceInForeignCurrency.currency"
                          label={i18n.t('page.vehicle.labels.buyingCurrency')}
                          isDisabled={isFalse(canEditPurchasePrice)}
                        />
                      </NoPermissionTooltip>
                    </Box>
                  </DataStatus>
                </Show>

                <Show when={canReadPurchasePrice}>
                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        data-testid={suffixTestId('currencyPurchasePriceAmountWithoutVat', props)}
                        type="currency"
                        currency={
                          formApi.watch(
                            'purchasePriceInForeignCurrency.currency'
                          ) as CurrencyCodeType
                        }
                        decimalPlaces={2}
                        control={control}
                        name="purchasePriceInForeignCurrency.withoutVat"
                        label={i18n.t('page.vehicle.labels.buyingForeignPriceWithoutVat')}
                        onChange={() => setLastTouchedPurchaseField('withoutVat')}
                        isDisabled={isFalse(canEditPurchasePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </Show>

                <Show when={canReadPurchasePrice}>
                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        data-testid={suffixTestId('currencyPurchasePriceAmountWithVat', props)}
                        type="currency"
                        currency={
                          formApi.watch(
                            'purchasePriceInForeignCurrency.currency'
                          ) as CurrencyCodeType
                        }
                        decimalPlaces={2}
                        control={control}
                        name="purchasePriceInForeignCurrency.withVat"
                        label={i18n.t('page.vehicle.labels.buyingForeignPriceWithVat')}
                        onChange={() => setLastTouchedPurchaseField('withVat')}
                        isDisabled={isFalse(canEditPurchasePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </Show>

                <Show when={canReadSalePrice}>
                  <DataStatus isLoading={isCurrenciesLoading} isError={isCurrenciesError}>
                    <Box flex={1}>
                      <NoPermissionTooltip
                        shouldShowTooltip={isFalse(canEditSalePrice) && isFalse(canReadSalePrice)}
                      >
                        <FormField
                          data-testid={suffixTestId('sellingCurrency', props)}
                          type="choice"
                          options={currencyOptions
                            ?.filter((option) => option.code !== currency)
                            .map((option) => ({value: option.code, label: option.code}))}
                          control={control}
                          name="sellingPriceInForeignCurrency.currency"
                          label={i18n.t('page.vehicle.labels.sellingCurrency')}
                        />
                      </NoPermissionTooltip>
                    </Box>
                  </DataStatus>
                </Show>

                <Show when={canReadSalePrice}>
                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                      <FormField
                        data-testid={suffixTestId('currencySellingPriceAmountWithoutVat', props)}
                        type="currency"
                        currency={
                          formApi.watch(
                            'sellingPriceInForeignCurrency.currency'
                          ) as CurrencyCodeType
                        }
                        decimalPlaces={2}
                        control={control}
                        name="sellingPriceInForeignCurrency.withoutVat"
                        label={i18n.t('page.vehicle.labels.sellingForeignPriceWithoutVat')}
                        onChange={() => setLastTouchedSellingField('withoutVat')}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </Show>

                <Show when={canReadSalePrice}>
                  <Box flex={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                      <FormField
                        data-testid={suffixTestId('currencysellingPriceAmountWithVat', props)}
                        type="currency"
                        currency={
                          formApi.watch(
                            'sellingPriceInForeignCurrency.currency'
                          ) as CurrencyCodeType
                        }
                        decimalPlaces={2}
                        control={control}
                        name="sellingPriceInForeignCurrency.withVat"
                        label={i18n.t('page.vehicle.labels.sellingForeignPriceWithVat')}
                        onChange={() => setLastTouchedSellingField('withVat')}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </Show>
              </Grid>
            </Card>
            <FormField
              data-testid={suffixTestId('updateReasonNote', props)}
              name="updateReasonNote"
              type="textarea"
              minRows={4}
              control={control}
              label={i18n.t('general.labels.reasonForChange')}
            />
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={props.onClose}
                title={i18n.t('general.actions.discard')}
                data-testid={suffixTestId('discard', props)}
              />

              <FormButton
                type="submit"
                variant="primary"
                control={control}
                title={i18n.t('general.actions.save')}
                data-testid={suffixTestId('save', props)}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}

const MINIMUM_ALLOWED_NUMBER = 0.01;

const schema = Yup.object().shape(
  {
    isVatDeductible: Yup.bool().required(i18n.t`general.notifications.fieldIsRequired`),
    purchasePrice: Yup.object().shape({
      vatCode: Yup.string().required(),
      withVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPricePurchasing',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
      withoutVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPricePurchasing',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
    }),
    sellingPrice: Yup.object().shape({
      vatCode: Yup.string().required(),
      withVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPriceSelling',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
      withoutVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPriceSelling',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
    }),
    purchasePriceInForeignCurrency: Yup.object().shape({
      currency: yupString,
      withVat: Yup.mixed().test(
        'minPriceSelling',
        i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
        (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
      ),
      withoutVat: Yup.mixed().test(
        'minPriceSelling',
        i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
        (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
      ),
    }),
    sellingPriceInForeignCurrency: Yup.object().shape({
      currency: yupString,
      withVat: Yup.mixed().test(
        'minPriceSelling',
        i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
        (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
      ),
      withoutVat: Yup.mixed().test(
        'minPriceSelling',
        i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
        (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
      ),
    }),
    originalCarPrice: Yup.string().nullable(),
    updateReasonNote: Yup.string().nullable(),
    b2bSellingPrice: Yup.object().shape({
      vatCode: Yup.string().required(),
      withVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPriceSelling',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
      withoutVat: Yup.object().shape({
        amount: Yup.mixed().test(
          'minPriceSelling',
          i18n.t('general.notifications.priceMin', {min: MINIMUM_ALLOWED_NUMBER}),
          (value) => (isNotNilOrEmpty(value) ? parseFloat(value) >= MINIMUM_ALLOWED_NUMBER : true)
        ),
      }),
    }),
  },
  [['purchasePrice', 'sellingPrice']]
);

type Schema = {
  isVatDeductible: boolean;
  purchasePrice?: VehiclePrice | null;
  sellingPrice?: VehiclePrice | null;
  purchasePriceInForeignCurrency?: {
    currency?: string | null;
    withoutVat?: string | null;
    withVat?: string | null;
  };
  sellingPriceInForeignCurrency?: {
    currency?: string | null;
    withoutVat?: string | null;
    withVat?: string | null;
  };
  originalCarPrice?: string | null;
  updateReasonNote?: string | null;
  b2bSellingPrice?: VehiclePrice | null;
};
