import {closeCurrentDialog, openDialog, TabProps, Tabs} from 'platform/components';
import {Box, Center} from 'platform/foundation';

import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSearchParams} from 'react-router-dom';

import {isNilOrEmpty, isNotNilOrEmpty, noop} from 'ramda-adjunct';

import {
  CustomerResponseBodyV2,
  useCreateBusinessCaseMutation,
  useCreateSaleBusinessCaseMutation,
  useGetVehicleQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, testIds, vehiclesRoutes} from '@omnetic-dms/routes';
import {
  CustomerWidgetCard,
  DetailTemplateHeader,
  EntityHeader,
  EntityHeaderFlag,
  getBusinessCaseStateFlag,
  handleApiError,
  Main,
  NavigationItem,
  Page,
  SnippetProps,
  useLazyGetAuthorizationProfilesAndCKKPermissions,
  usePermissions,
} from '@omnetic-dms/shared';

import {buildObject, composePath, useNavigate} from 'shared';

import {businessCaseSearchParams} from '../../businessCaseSearchParams';
import {SelectVehicleDialogDataGrid} from '../../components/BusinessCaseOffers/BusinessCaseOffersSelectVehicleDatagrid';

export function CreateBusinessCase() {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [vehicleId, setVehicleId] = useState(params.get('vehicleId') ?? '');

  const {data: vehicleDetail} = useGetVehicleQuery({vehicleId}, {skip: isNilOrEmpty(vehicleId)});

  const [createBusinessCaseMutation] = useCreateBusinessCaseMutation();
  const [createSaleBusinessCaseMutation] = useCreateSaleBusinessCaseMutation();
  const {handleSearchAuthorizationProfiles} = useLazyGetAuthorizationProfilesAndCKKPermissions();

  const [canCreateBusinessCaseSelling] = usePermissions({
    permissionKeys: ['createBusinessCaseSelling'],
  });

  const onCustomerSelected = (customer: CustomerResponseBodyV2) => {
    if (isNotNilOrEmpty(vehicleDetail)) {
      createSaleBusinessCaseMutation({
        createSaleBusinessCaseRequestBody: {
          customerId: customer.id,
          vehicleToSaleId: vehicleId,
        },
      })
        .unwrap()
        .then((businessCase) => {
          navigate(composePath(businessCaseRoutes.selling, {params: {id: businessCase.id}}), {
            state: {
              ignoreUnmountEvents: true,
            },
          });
        })
        .catch(handleApiError);
      handleSearchAuthorizationProfiles(
        {
          vehicleMake: vehicleDetail?.make ?? '',
          vehicleType: vehicleDetail?.type ?? null,
          vehicleModelFamily: vehicleDetail?.vehicle?.modelFamily ?? null,
          vehicleModelFamilyGroup: vehicleDetail?.vehicle?.modelFamilyGroup ?? null,
        },
        customer.id
      );
    } else {
      createBusinessCaseMutation({
        createBusinessCaseRequestBody: {
          customerId: customer?.id,
          vehicleToSaleId: vehicleId || undefined,
        },
      })
        .unwrap()
        .then((businessCase) => {
          navigate(
            composePath(
              businessCaseRoutes[
                businessCase.businessCaseType === 'SELLING' ? 'selling' : 'buying'
              ],
              {params: {id: businessCase.id}}
            ),
            {
              state: {
                ignoreUnmountEvents: true,
              },
            }
          );
        })
        .catch(handleApiError);
    }
  };

  const handleSelectVehicle = () => {
    openDialog(
      <SelectVehicleDialogDataGrid
        data-testid={testIds.businessCase.detail('addSaleVehicle')}
        onRowSelectionChange={(rows) => {
          setVehicleId(rows[0]?.id);
        }}
      />,
      {
        title: i18n.t('page.businessCase.labels.selectVehicle'),
        size: 'large',
        buttons: [
          {
            title: i18n.t('general.actions.cancel'),
            variant: 'secondary',
            onClick: closeCurrentDialog,
          },
          {
            title: i18n.t('general.labels.select'),
            variant: 'primary',
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.customer.labels.customer'),
      id: 'customer',
      'data-testid': testIds.businessCase.customer('tabs-customer'),
      content: (
        <Center>
          <Box width={170}>
            <CustomerWidgetCard
              customTitle={i18n.t('entity.customer.labels.customer')}
              secondStepComponentType="BUSINESS_CASE"
              onCustomer={onCustomerSelected}
              data-testid={testIds.businessCase.concept('customerWidget')}
              vehicleId={vehicleId}
            />
          </Box>
        </Center>
      ),
    },
    {
      title: i18n.t('entity.gdpr.labels.consents'),
      id: 'consents',
      'data-testid': testIds.businessCase.customer('tabs-consents'),
      isDisabled: true,
      content: null,
    },
  ];

  function CreateBusinessCaseContent() {
    return (
      <Main>
        <Tabs data-testid={testIds.businessCase.customer('tabs')} variant="condensed" tabs={tabs} />
      </Main>
    );
  }

  const navigationItems: NavigationItem[] = [
    {
      id: businessCaseSearchParams.businessCaseDetail.overview,
      label: i18n.t('page.businessCase.labels.overview'),
      href: '',
      content: null,
      isDisabled: true,
      hasSeparator: true,
    },
    {
      id: businessCaseSearchParams.create,
      label: i18n.t('entity.customer.labels.customer'),
      href: businessCaseRoutes.concept,
      content: <CreateBusinessCaseContent />,
      hasCounter: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.buying,
      label: i18n.t('entity.businessCase.labels.buyingOrCommission'),
      href: '',
      content: null,
      isDisabled: true,
      hasCounter: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.selling,
      label: i18n.t('page.businessCase.labels.selling'),
      href: '',
      content: null,
      isDisabled: true,
      hasCounter: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.testDrive,
      label: i18n.t('entity.businessCase.labels.testDrive'),
      href: '',
      content: null,
      isDisabled: true,
      hasCounter: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.reservations,
      label: i18n.t('entity.vehicle.labels.reservations'),
      href: '',
      content: null,
      isDisabled: true,
      hasCounter: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.checkout,
      label: i18n.t('entity.businessCase.labels.checkout'),
      href: '',
      content: null,
      isDisabled: true,
      hasCounter: true,
      hasSeparator: true,
    },
    {
      id: businessCaseSearchParams.businessCaseDetail.documents,
      label: i18n.t('page.businessCase.documents.title'),
      href: '',
      content: null,
      isDisabled: true,
    },
  ];

  const getHeader: DetailTemplateHeader = {
    title: i18n.t('page.businessCase.labels.newBusinessCase'),
    icon: 'places/business_center',
    flags: [getBusinessCaseStateFlag('CONCEPT') as EntityHeaderFlag],
    parameters: [i18n.t('general.labels.noIdentification')],
    actions: [
      {
        title: i18n.t('general.actions.discard'),
        onClick: () => {
          navigate(businessCaseRoutes.home);
        },
        type: 'button',
        variant: 'secondary',
        'data-testid': testIds.businessCase.concept('discard'),
      },
    ],
    snippets: [
      {
        label: i18n.t('entity.businessCase.labels.contactPerson'),
        placeholder: {
          title: i18n.t(`entity.businessInfo.labels.noContactPerson`),
        },
        isDisabled: true,
      },
      buildObject<SnippetProps>()
        .label(i18n.t('page.businessCase.labels.selling'))
        .placeholder({
          title: i18n.t('entity.vehicle.actions.addVehicleToSell'),
          onClick: handleSelectVehicle,
        })
        .title(vehicleDetail ? vehicleDetail?.title : undefined)
        .parameters([
          vehicleDetail?.state?.registrationPlate ||
            i18n.t('entity.vehicle.notifications.noLicencePlate'),
          vehicleDetail?.vin || i18n.t('entity.vehicle.notifications.noVIN'),
        ])
        .href(
          vehicleDetail
            ? composePath(vehiclesRoutes.detail, {params: {id: vehicleDetail?.id}})
            : undefined
        )
        .isDisabled(!canCreateBusinessCaseSelling)
        .build(),
      {
        label: i18n.t('entity.businessCase.labels.buying'),
        placeholder: {
          title: i18n.t('entity.vehicle.actions.addVehicleToBuy'),
          onClick: noop,
        },
        isDisabled: true,
      },
    ],
  };

  return (
    <>
      <Helmet title={i18n.t('pages.businessCaseConcept.title')} />
      <Page
        navigation={navigationItems}
        data-testid={testIds.businessCase.concept('concept')}
        header={
          <EntityHeader
            {...getHeader}
            data-testid={testIds.businessCase.concept('concept')}
            isTagDeletable
            isTagUpdatable
            isTagReadable
            isTagCreatable
            isTagAssignable
            isTagUnassignable
          />
        }
      />
    </>
  );
}
