import {
  DeliveryQuantityMappingRequest,
  DeliveryQuantityMappingResponse,
} from '../types/metadaDeliveryQuantityMapping';
import {metadaApi} from './baseApi/metadaApi';

export const metadaDeliveryQuantityMappingApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    putDeliveryQuantityMapping: build.mutation<
      DeliveryQuantityMappingResponse,
      DeliveryQuantityMappingRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/delivery-quantity-mapping`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
  }),
});

export const {usePutDeliveryQuantityMappingMutation} = metadaDeliveryQuantityMappingApi;
