import {parseISO} from 'date-fns';
import {DataStatus, Flag, Label, Table, TableRow} from 'platform/components';
import {Box, Hide, Link, Show, Text} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';

import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  useGetCorrectiveTaxDocumentPaymentListQuery,
  useRenderExpenseCashReceiptDocumentMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER, handleApiError} from '@omnetic-dms/shared';

import {TestIdProps, convertStringToCamelCase, openFile} from 'shared';

type PaymentsListDetailProps = TestIdProps & {
  correctiveTaxDocumentId: string;
};

export function CorrectiveTaxDocumentPaymentList(props: PaymentsListDetailProps) {
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const [renderExpenseCashReceipt, {isLoading: isRendering}] =
    useRenderExpenseCashReceiptDocumentMutation();
  const {
    data: paymentList,
    isLoading,
    isError,
  } = useGetCorrectiveTaxDocumentPaymentListQuery({
    correctiveTaxDocumentId: props.correctiveTaxDocumentId,
  });

  const openCashReceipt = (cashRegisterDocumentId: string) => {
    renderExpenseCashReceipt({
      renderExpenseCashReceiptDocumentRequestBody: {cashRegisterDocumentId},
    })
      .unwrap()
      .then((data) => data.pdfUrl)
      .then(openFile)
      .catch(handleApiError);
  };

  const isEmpty = isNilOrEmpty(paymentList);

  return (
    <DataStatus isEmpty={isEmpty} isError={isError} isLoading={isLoading} minHeight={50}>
      <Table
        tableLayout="fixed"
        columns={[
          {
            width: 20,
            element: (
              <Box paddingHorizontal={2}>
                <Label>{i18n.t('general.labels.type')}</Label>
              </Box>
            ),
          },
          {
            width: 35,
            element: (
              <Box paddingHorizontal={2}>
                <Label>{i18n.t('entity.bank.labels.paymentType')}</Label>
              </Box>
            ),
          },
          {
            width: 35,
            element: (
              <Box paddingHorizontal={2}>
                <Label>{i18n.t('entity.checkout.labels.amount')}</Label>
              </Box>
            ),
          },
          {
            width: 30,
            element: (
              <Box paddingHorizontal={2}>
                <Label>{i18n.t('entity.invoice.labels.dateOfPayment')}</Label>
              </Box>
            ),
          },
          {
            element: (
              <Box paddingHorizontal={2}>
                <Label>{i18n.t('entity.checkout.labels.file')}</Label>
              </Box>
            ),
          },
        ]}
      >
        {paymentList?.map((item) => (
          <TableRow key={item.paymentId}>
            <Box padding={2}>
              <Flag
                label={i18n.t(`entity.checkout.labels.${item.source}`)}
                colorScheme={item.source === 'external' ? 'yellow' : 'green'}
                isSubtle
              />
            </Box>
            <Box padding={2}>
              <Text size="small">
                {i18n.t(
                  `entity.invoice.paymentMethod.${convertStringToCamelCase(item.paymentType)}`
                )}
              </Text>
            </Box>
            <Box padding={2}>
              <Text size="small">
                {formatCurrency(parseFloat(item.amount.amount), item.amount.currency)}
              </Text>
            </Box>
            <Box padding={2}>
              <Text size="small">{formatDateTime('dateShort', parseISO(item.paymentDate))}</Text>
            </Box>

            <Box padding={2}>
              <Show when={isNotNil(item.cashRegisterDocumentId)}>
                <Link
                  isDisabled={isRendering}
                  size="small"
                  title={`${i18n.t('entity.checkout.labels.cashReceipt')} ${
                    item.cashRegisterDocumentNumber
                  }`}
                  onClick={() => openCashReceipt(item.cashRegisterDocumentId!)}
                />
              </Show>

              <Hide when={isNotNil(item.cashRegisterDocumentId)}>{EMPTY_PLACEHOLDER}</Hide>
            </Box>
          </TableRow>
        ))}
      </Table>
    </DataStatus>
  );
}
