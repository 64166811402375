import {Button, ButtonGroup, DataStatus, Dialog} from 'platform/components';
import {Box, HStack, Space} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useMemo} from 'react';

import {last} from 'ramda';

import {
  useCreateDocumentMutation,
  useGetCheckoutOffsettingOfLiabilitiesAndReceivablesQuery,
  useGetOffsettingOfLiabilitiesAndReceivablesTemplatesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {EMPTY_PLACEHOLDER} from '@omnetic-dms/shared';
import {PriceBox, SimpleTable, ColumnType} from '@omnetic-dms/teas';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

type CreditDifferenceRow = {
  name: string;
  amountWithoutVat: string;
  vat: string;
  amount: string;
};

interface CheckoutCreditSettlementProps extends TestIdProps {
  checkoutId: string;
  orderId?: string;
  isOpen: boolean;
  toggleOpen: () => void;
}

export function CheckoutCreditSettlement({
  checkoutId,
  orderId,
  isOpen,
  toggleOpen,
  ...props
}: CheckoutCreditSettlementProps) {
  const formatCurrency = useFormatCurrency();
  const [loadingGenerate, startLoadingGenerate, stopLoadingGenerate] = useBoolean(false);

  const {
    data: offsettingOfLiabilitiesAndReceivables,
    isLoading: isLoadingLiabilitiesAndReceivables,
    isError: isErrorLiabilitiesAndReceivables,
  } = useGetCheckoutOffsettingOfLiabilitiesAndReceivablesQuery({checkoutId});

  const {
    data: offsettingOfLiabilitiesAndReceivablesTemplates,
    isLoading: isLoadingLiabilitiesAndReceivablesTemplates,
    isError: isErrorLiabilitiesAndReceivablesTemplates,
  } = useGetOffsettingOfLiabilitiesAndReceivablesTemplatesQuery();

  const isLoading =
    isLoadingLiabilitiesAndReceivables || isLoadingLiabilitiesAndReceivablesTemplates;
  const isError = isErrorLiabilitiesAndReceivables || isErrorLiabilitiesAndReceivablesTemplates;

  const templateIds = (offsettingOfLiabilitiesAndReceivablesTemplates ?? [])
    .filter((template) => template.primary)
    .map((template) => template.id);

  const liabilities = useMemo(
    () => [
      {
        data: offsettingOfLiabilitiesAndReceivables?.liabilities
          ? [
              ...offsettingOfLiabilitiesAndReceivables.liabilities.map((liability) => ({
                id: liability.id,
                name: liability.name,
                amountWithoutVat: liability.price.withoutVat
                  ? (formatCurrency(
                      parseFloat(liability.price.withoutVat.amount),
                      liability.price.withoutVat.currency
                    ) ?? EMPTY_PLACEHOLDER)
                  : EMPTY_PLACEHOLDER,
                vat: liability.price.vatRate ? `${liability.price.vatRate}%` : '–',
                amount:
                  formatCurrency(
                    parseFloat(liability.price.withVat.amount),
                    liability.price.withVat.currency
                  ) ?? EMPTY_PLACEHOLDER,
              })),
            ]
          : [],
      },
    ],
    [offsettingOfLiabilitiesAndReceivables?.liabilities, formatCurrency]
  );

  const claims = useMemo(
    () => [
      {
        data: offsettingOfLiabilitiesAndReceivables?.claim
          ? [
              ...offsettingOfLiabilitiesAndReceivables.claim.map((claim) => ({
                id: claim.id,
                name: claim.name,
                amountWithoutVat: claim.price.withoutVat
                  ? (formatCurrency(
                      parseFloat(claim.price.withoutVat.amount),
                      claim.price.withoutVat.currency
                    ) ?? EMPTY_PLACEHOLDER)
                  : EMPTY_PLACEHOLDER,
                vat: claim.price.vatRate ? `${claim.price.vatRate}%` : '–',
                amount:
                  formatCurrency(
                    parseFloat(claim.price.withVat.amount),
                    claim.price.withVat.currency
                  ) ?? EMPTY_PLACEHOLDER,
              })),
            ]
          : [],
      },
    ],
    [offsettingOfLiabilitiesAndReceivables?.claim, formatCurrency]
  );

  const [createCheckoutDocuments] = useCreateDocumentMutation();

  const createCheckoutOffsettingOfLiabilitiesAndReceivables = async () => {
    startLoadingGenerate();

    const checkout = await createCheckoutDocuments({
      checkoutId,
      orderId: orderId || '',
      templateIds,
    }).unwrap();

    const lastDocument = last(checkout.orders[0]?.files)?.fileUri;
    if (lastDocument) {
      window.location.href = lastDocument;
    }

    stopLoadingGenerate();
    toggleOpen();
  };

  return (
    <Dialog
      title={i18n.t('entity.checkout.labels.creditDifference')}
      data-testid={props['data-testid']}
      isOpen={isOpen}
      onClose={toggleOpen}
    >
      <Box minHeight={10}>
        <DataStatus isLoading={isLoading} isError={isError}>
          <>
            <SimpleTable
              data-testid={suffixTestId('liabilities', props)}
              noZebra
              columns={columns('liabilities')}
              rows={liabilities}
            />
            <SimpleTable
              data-testid={suffixTestId('claims', props)}
              noZebra
              columns={columns('claims')}
              rows={claims}
            />
            <HStack justify="flex-end">
              <Box paddingTop={2} paddingBottom={4}>
                <PriceBox
                  data-testid={suffixTestId('gap', props)}
                  align="right"
                  title={i18n.t('general.labels.gap')}
                  price={offsettingOfLiabilitiesAndReceivables?.credit}
                />
              </Box>
            </HStack>
          </>
        </DataStatus>
      </Box>

      <Space vertical={4} />
      <ButtonGroup align="right" data-testid={props['data-testid']}>
        <Button
          onClick={toggleOpen}
          data-testid={suffixTestId('cancel', props)}
          variant="secondary"
          title={i18n.t('general.actions.cancel')}
        />
        <Button
          data-testid={suffixTestId('generateCreditDifference', props)}
          onClick={createCheckoutOffsettingOfLiabilitiesAndReceivables}
          isLoading={loadingGenerate}
          title={i18n.t('entity.checkout.labels.generateCreditDifference')}
        />
      </ButtonGroup>
    </Dialog>
  );
}

const columns = (name: string): Array<ColumnType<CreditDifferenceRow>> => [
  {id: 'name', width: 300, Header: i18n.t(`entity.checkout.labels.${name}`)},
  {
    id: 'amountWithoutVat',
    width: 150,
    align: 'right',
    Header: i18n.t('general.labels.amountWithoutVat'),
  },
  {id: 'vat', width: 100, align: 'right', Header: i18n.t('general.labels.vat')},
  {id: 'amount', align: 'right', Header: i18n.t('general.labels.amount')},
];
