import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, Flag, Label, Parameters, Rank, Tooltip} from 'platform/components';
import {Box, Heading, HStack, Icon, Image, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency, useFormatNumber, useFormatPercentage} from 'platform/locale';

import {ReactElement} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {SourcingVehicleDetailResponseBody} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {
  convertVehicleTagsToVehicleSnippetHighlights,
  CountryFlag,
  decimalToPercentRank,
  VehicleSnippetHighlightsWithMore,
} from '@omnetic-dms/teas';

import {CurrencyCodeType, suffixTestId, TestIdProps} from 'shared';

import {detailFirstLine} from '../../utils/detailFirstLine';
import {detailSecondLine} from '../../utils/detailSecondLine';
import {getLID} from '../../utils/getLID';
import {getVehicleDetailPosition} from '../../utils/getVehicleDetailPosition';
import {renderMeasurePercentage} from '../../utils/renderMeasurePercentage';
import {ActionButtonsVehicle} from '../ActionButtonsVehicle/ActionButtonsVehicle';
import {CarvagoIcon} from '../CarvagoIcon/CarvagoIcon';
import {SourcingVehicleActions} from '../SourcingVehicleActions/SourcingVehicleActions';
import {Car, Circle} from './styles';

export interface AdvanceVehicleViewProps extends TestIdProps {
  index?: number;
  vehicle?: SourcingVehicleDetailResponseBody;
  isVehicleLoading?: boolean;
  onRowClick?: () => void;
  isLastClickedRow?: boolean;
  setLastVisitedVehicle?: (adId: string, index: number) => void;
  children?: ReactElement;
}

const PERCENTAGE_MULTIPLIER = 100;

export function AdvanceVehicleView(props: AdvanceVehicleViewProps) {
  const formatPercentage = useFormatPercentage();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const sourcingVehicle =
    props.vehicle && 'sourcingVehicle' in props.vehicle ? props.vehicle.sourcingVehicle : null;

  const vehiclePosition = getVehicleDetailPosition(
    sourcingVehicle?.extraData?.marketPosition,
    sourcingVehicle?.extraData?.marketPositionCount
  );
  const {originalWithVat, originalCurrency, currency} = sourcingVehicle?.price || {};
  const currencyCode = originalCurrency?.key;
  const currencyKey = currency?.key as CurrencyCodeType;

  const isSelectedInComparison =
    isNotNil(props.vehicle) && 'isSelectedInComparison' in props.vehicle
      ? props.vehicle.isSelectedInComparison
      : false;
  const isOriginalVehicle = sourcingVehicle?.adId.includes('_DUPLICATE');

  return (
    <Car
      key={sourcingVehicle?.adId}
      className={props.isLastClickedRow ? 'active-iframe' : ''}
      disabled={
        sourcingVehicle?.isAvailable === false // isAvailable must be false not undefined
      }
      onClick={props.onRowClick}
      isLoading={!sourcingVehicle}
    >
      <Box position="relative">
        <Box position="absolute" top={2} left={2}>
          <VStack spacing={2}>
            <Show when={isSelectedInComparison}>
              <Flag
                label={i18n.t('general.labels.selected')}
                colorScheme="blue"
                data-testid={suffixTestId('selected', props)}
              />
            </Show>
            <Show when={isOriginalVehicle}>
              <Flag
                label={i18n.t('entity.vehicle.labels.original')}
                colorScheme="blue"
                data-testid={suffixTestId('original', props)}
              />
            </Show>
          </VStack>
        </Box>
        <Show when={isNotNil(sourcingVehicle?.thumbnailUrl?.full)}>
          <Image
            src={sourcingVehicle?.thumbnailUrl?.full}
            alt={sourcingVehicle?.make?.translation}
            height="100%"
            fit="cover"
            borderRadius="medium"
            data-testid={suffixTestId('vehicleThumbnail', props)}
          />
        </Show>
      </Box>
      <DataStatus isLoading={props.isVehicleLoading}>
        <Box padding={3}>
          <VStack spacing={2}>
            <HStack align="center" spacing={2} wrap>
              <Show when={sourcingVehicle?.isAvailable === false}>
                <Flag
                  label={i18n.t('entity.vehicle.labels.sold')}
                  size="small"
                  data-testid={suffixTestId('sold', props)}
                />
              </Show>
              <HStack align="center" spacing={2} grow={1}>
                <Heading size={4}>{sourcingVehicle && detailFirstLine(sourcingVehicle)}</Heading>
                <CountryFlag country={sourcingVehicle?.seller?.country?.key} />
                <Show whenFeatureEnabled={featureFlags.SOURCING_CARVAGO_AVAILABILITY}>
                  <CarvagoIcon
                    isAvailableOnCarvago={sourcingVehicle?.carvagoAvailability}
                    carvagoBlacklist={sourcingVehicle?.carvagoBlacklists}
                  />
                </Show>
                <Show when={!props.vehicle?.seen}>
                  <Circle />
                </Show>
              </HStack>
              {props.vehicle && sourcingVehicle?.isAvailable && (
                <ActionButtonsVehicle
                  index={props.index}
                  vehicle={props.vehicle}
                  actionButton={<SourcingVehicleActions vehicle={sourcingVehicle} />}
                  adId={sourcingVehicle?.adId}
                  setLastVisitedVehicle={props.setLastVisitedVehicle}
                />
              )}
            </HStack>
            <HStack spacing={2}>
              <Show when={props.vehicle?.vehicleSummary?.comment}>
                <Tooltip placement="top" label={props.vehicle?.vehicleSummary?.comment}>
                  <Icon value="communication/comment" />
                </Tooltip>
              </Show>
              <Show when={isNotNil(sourcingVehicle)}>
                <Parameters
                  size="small"
                  color="secondary"
                  parameters={detailSecondLine(formatNumber, sourcingVehicle ?? undefined)}
                  data-testid={suffixTestId('vehicleParameters', props)}
                />
              </Show>
            </HStack>
            <VehicleSnippetHighlightsWithMore
              data={convertVehicleTagsToVehicleSnippetHighlights(
                [],
                [],
                Object.values(props.vehicle?.features ?? {}).filter((i) => i.isHighlight)
              )}
            />
            <HStack justify="space-between" spacing={2} wrap>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.position')}</Label>
                <Text size="xSmall">{vehiclePosition[0]}</Text>
                <Text size="xxSmall" color="secondary">
                  {vehiclePosition[1]}
                </Text>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.originalPrice')}</Label>
                <Tooltip
                  label={
                    isNotNil(originalWithVat) &&
                    currencyCode &&
                    formatCurrency(originalWithVat, currencyCode)
                  }
                >
                  <Text size="xSmall">{formatNumber(props.vehicle?.summary?.original, 0)}</Text>
                </Tooltip>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.margin')}</Label>
                <Text size="xSmall">
                  {isNotNil(sourcingVehicle?.maximalPossibleMargin)
                    ? formatNumber(sourcingVehicle?.maximalPossibleMargin, 0)
                    : ''}
                </Text>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.recommendedPrice')}</Label>
                <Tooltip
                  isDisabled={!isFeatureEnabled(featureFlags.SOURCING_SHOW_UNCERTAINTY_PERCENTAGE)}
                  label={
                    sourcingVehicle?.extraData?.predictedPricePercentile
                      ? renderMeasurePercentage(
                          sourcingVehicle?.extraData?.predictedPricePercentile,
                          currencyKey
                        )
                      : undefined
                  }
                >
                  <Text size="xSmall">
                    {formatNumber(sourcingVehicle?.extraData?.predictedPrice, 0)}
                  </Text>
                  <Show whenFeatureEnabled={featureFlags.SOURCING_SHOW_UNCERTAINTY_PERCENTAGE}>
                    {isNotNil(sourcingVehicle?.fairPriceUncertainty) && (
                      <Text size="xxSmall" color="secondary">
                        {formatPercentage(sourcingVehicle?.fairPriceUncertainty ?? 0, 'down')}
                      </Text>
                    )}
                  </Show>
                </Tooltip>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.LID')}</Label>
                <Text size="xSmall">
                  {isNotNil(props.vehicle?.summary?.lid)
                    ? formatNumber(getLID(props.vehicle?.summary?.lid), 0)
                    : 'N/A'}
                </Text>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.DOD')}</Label>
                <Text size="xSmall">{formatNumber(sourcingVehicle?.daysOnStock, 0)}</Text>
              </Box>
              <Box>
                <Label>{i18n.t('entity.vehicle.labels.features')}</Label>
                <Tooltip label={decimalToPercentRank(props.vehicle?.summary?.rank ?? undefined)}>
                  <Rank
                    percentage={(props.vehicle?.summary?.rank ?? 0) * PERCENTAGE_MULTIPLIER}
                    data-testid={suffixTestId('rank', props)}
                  />
                </Tooltip>
              </Box>
            </HStack>
          </VStack>
        </Box>
      </DataStatus>
    </Car>
  );
}
