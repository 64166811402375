import {Center, Icon} from 'platform/foundation';

import {Link} from 'react-router-dom';

import {MenuItemProps} from '@omnetic-dms/api';

import {composePath, suffixTestId, TestIdProps} from 'shared';

import {HeaderButtonWrapper} from '../../../HeaderButtonWrapper/HeaderButtonWrapper';

interface SettingsProps extends TestIdProps {
  settingsModule: MenuItemProps | undefined;
}

export function Settings(props: SettingsProps) {
  if (!props.settingsModule) {
    return null;
  }

  return (
    <Center height="100%">
      <HeaderButtonWrapper $isOpen={false} $rounded $size="small">
        <Center height="100%" width="100%">
          <Link
            to={composePath(props.settingsModule.path, {isSearchParamsPreserved: false})}
            data-testid={suffixTestId('settings-button', props)}
          >
            <Icon value={props.settingsModule.layoutProps.icon} size={4} />
          </Link>
        </Center>
      </HeaderButtonWrapper>
    </Center>
  );
}
