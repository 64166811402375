import {
  GetSuppliersResponse,
  GetSuppliersResponseSchema,
  BaseSupplier,
  GetSuppliersRequest,
  GetSuppliersRequestSchema,
  GetSupplierResponse,
  GetSupplierRequest,
  GetSupplierRequestSchema,
  GetSupplierResponseSchema,
} from '../types/metadaWarehouseSupplier';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseSupplierApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getSuppliers: build.query<BaseSupplier[], GetSuppliersRequest>({
      query: () => ({
        url: `warehouse/v1/supplier`,
      }),
      transformResponse: (response: GetSuppliersResponse) => response.supplier,
      providesTags: ['suppliers'],
      extraOptions: {
        requestSchema: GetSuppliersRequestSchema,
        responseSchema: GetSuppliersResponseSchema,
      },
    }),
    getSupplier: build.query<GetSupplierResponse, GetSupplierRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/supplier/${queryArg.supplierId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'suppliers', id: queryArg.supplierId}],
      extraOptions: {
        requestSchema: GetSupplierRequestSchema,
        responseSchema: GetSupplierResponseSchema,
      },
    }),
  }),
});

export const {useGetSuppliersQuery, useGetSupplierQuery} = metadaWarehouseSupplierApi;
