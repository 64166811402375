import {Action, BaseFlagProps, openDeleteDialog, showNotification} from 'platform/components';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useDeleteServiceOrderIssueNoteMutation,
  useGetServiceOrderIssueNoteQuery,
  useGetUserQuery,
  usePatchServiceOrderIssueNoteCompleteMutation,
  usePatchServiceOrderIssueNotePartsReadyMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DEFAULT_CURRENCY,
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@omnetic-dms/shared';

import {composePath, EMPTY_PLACEHOLDER, isNilOrZero, parseDate, useNavigate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {Documents} from './(sections)/Documents/Documents';
import {IssueNotes} from './(sections)/IssueNotes/IssueNotes';
import {Overview} from './(sections)/Overview/Overview';

export function ServiceOrderIssueDetail() {
  const {serviceOrderIssueNoteId} = useWarehouseParams();
  const navigate = useNavigate();
  const currencyFormatter = useFormatCurrency();
  const formatDate = useDateTimeFormatter();

  const {
    data: serviceOrderIssueNote,
    isLoading: isServiceOrderIssueNoteLoading,
    isError: hasServiceOrderIssueNoteError,
  } = useGetServiceOrderIssueNoteQuery({serviceOrderIssueNoteId});

  const {data: userWhoCreated, isLoading: isUserWhoCreated} = useGetUserQuery(
    {
      id: serviceOrderIssueNote?.createdBy as string,
    },
    {skip: isNilOrEmpty(serviceOrderIssueNote?.createdBy)}
  );

  const {data: userWhoCompleted, isLoading: isUserWhoCompletedLoading} = useGetUserQuery(
    {
      id: serviceOrderIssueNote?.updatedBy as string,
    },
    {skip: isNilOrEmpty(serviceOrderIssueNote?.createdBy)}
  );

  const [deleteServiceOrderIssueNote, {isLoading: isDeleteLoading}] =
    useDeleteServiceOrderIssueNoteMutation();

  const [partsReadyServiceOrderIssueNote, {isLoading: isPartsReadyLoading}] =
    usePatchServiceOrderIssueNotePartsReadyMutation();

  const [completeServiceOrderIssueNote, {isLoading: isCompleteLoading}] =
    usePatchServiceOrderIssueNoteCompleteMutation();

  const isLoading = isServiceOrderIssueNoteLoading || isUserWhoCreated || isUserWhoCompletedLoading;
  const isError = hasServiceOrderIssueNoteError;

  const handleOpenDeleteDialog = () => {
    openDeleteDialog({
      text: i18n.t('entity.warehouse.actions.deleteEntity'),
      onConfirm: handleDelete,
    });
  };

  const handleDelete = async () =>
    await deleteServiceOrderIssueNote({serviceOrderIssueNoteId})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyDeleted')))
      .then(() => navigate(composePath(warehouseRoutes.serviceOrderIssues, {})))
      .catch(handleApiError);

  const handlePartsReady = async (newState: boolean) => {
    await partsReadyServiceOrderIssueNote({
      serviceOrderIssueNoteId,
      body: {partsReady: newState},
    })
      .unwrap()
      .then(() => {
        const successMessage = newState
          ? i18n.t('general.notifications.successfullySetAsReady')
          : i18n.t('general.notifications.successfullySetAsNotReady');
        showNotification.success(successMessage);
      })
      .catch(handleApiError);
  };

  const handleComplete = async () => {
    await completeServiceOrderIssueNote({serviceOrderIssueNoteId})
      .unwrap()
      .then(() => showNotification.success(i18n.t('general.notifications.successfullyCompleted')))
      .catch(handleApiError);
  };

  const serviceOrderIssueTitle = `${i18n.t('page.warehouse.labels.serviceOrderIssue')} ${defaultTo(
    '',
    serviceOrderIssueNote?.number
  )}`;

  const priceWithVat = currencyFormatter(
    defaultTo(0, serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.amount),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.currency
    ),
    2
  );

  const priceWithoutVat = currencyFormatter(
    defaultTo(
      0,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.amount
    ),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderIssueNote?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.currency
    ),
    2
  );

  const getFormattedDate = (date?: string) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDate('dateShort', parseDate(date));
  };

  const createdAt = `${i18n.t('general.labels.dateCreated')}: ${getFormattedDate(
    serviceOrderIssueNote?.createdAt
  )}`;

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(userWhoCreated)}`;

  const completedAt = match(serviceOrderIssueNote?.state)
    .returnType<string | undefined>()
    .with(
      'COMPLETED',
      always(
        `${i18n.t('general.labels.dateCompleted')}: ${getFormattedDate(
          serviceOrderIssueNote?.updatedAt
        )}`
      )
    )
    .otherwise(always(undefined));

  const completedBy = match(serviceOrderIssueNote?.state)
    .returnType<string | undefined>()
    .with(
      'COMPLETED',
      always(`${i18n.t('general.labels.completedBy')}: ${getUserName(userWhoCompleted)}`)
    )
    .otherwise(always(undefined));

  const headerFlags = () => {
    const flags = match(serviceOrderIssueNote?.state)
      .returnType<Omit<BaseFlagProps, 'size'>[]>()
      .with(
        'COMPLETED',
        always([{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
      )
      .otherwise(always([{colorScheme: 'blue', label: i18n.t('general.labels.pending')}]));

    if (serviceOrderIssueNote?.partsReady) {
      flags.push({colorScheme: 'blue', isSubtle: true, label: i18n.t('general.labels.ready')});
    }

    return flags;
  };

  // TODO https://carvago.atlassian.net/browse/T20-56835
  const headerActions = match(serviceOrderIssueNote?.state)
    .returnType<Action[] | undefined>()
    .with(
      'PENDING',
      always([
        {
          type: 'button',
          title: i18n.t('general.labels.delete'),
          variant: 'secondary',
          onClick: handleOpenDeleteDialog,
          isLoading: isDeleteLoading,
          'data-testid': testIds.warehouse.serviceOrderIssueDetailOverview('headerAction.delete'),
        },
        {
          type: 'button',
          title: i18n.t(
            serviceOrderIssueNote?.partsReady
              ? 'general.labels.removeReady'
              : 'general.labels.ready'
          ),
          variant: 'secondary',
          onClick: () => handlePartsReady(!serviceOrderIssueNote?.partsReady),
          isLoading: isPartsReadyLoading,
          'data-testid': testIds.warehouse.serviceOrderIssueDetailOverview(
            serviceOrderIssueNote?.partsReady ? 'headerAction.removeReady' : 'headerAction.ready'
          ),
        },
        {
          type: 'button',
          title: i18n.t('general.labels.complete'),
          variant: 'primary',
          onClick: handleComplete,
          isLoading: isCompleteLoading,
          isDisabled: isNilOrZero(
            serviceOrderIssueNote?.sparePartsTotalPrice.totalSellingPrice.withVat.amount
          ),
          'data-testid': testIds.warehouse.serviceOrderIssueDetailOverview('headerAction.complete'),
        },
      ])
    )
    .otherwise(always(undefined));

  const header: DetailTemplateHeader = {
    title: serviceOrderIssueTitle,
    icon: 'custom/warehouse',
    parameters: [
      defaultTo('', serviceOrderIssueNote?.number),
      serviceOrderIssueNote?.serviceOrder.variant,
      createdAt,
      createdBy,
      completedAt,
      completedBy,
    ],
    recordId: serviceOrderIssueNote?.serviceOrderIssueNoteId,
    resourceId: EntityResourceIds.serviceOrderIssues,
    controls: ['ASSIGNEE'],
    flags: headerFlags(),
    actions: headerActions,
    primaryParameter: defaultTo(undefined, priceWithVat),
    secondaryParameter: `${priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'serviceOrderIssue',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailOverview, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <Overview
          serviceOrderIssueNote={serviceOrderIssueNote}
          isServiceOrderIssueLoading={isServiceOrderIssueNoteLoading}
          priceWithoutVat={priceWithoutVat}
          priceWithVat={priceWithVat}
          data-testid={testIds.warehouse.serviceOrderIssueDetailOverview(
            'section.serviceOrderIssue'
          )}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailOverview(
        'navigation.serviceOrderIssue'
      ),
    },
    {
      id: 'issueNotes',
      label: i18n.t('page.warehouse.labels.issueNotes'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailIssueNotes, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <IssueNotes
          data-testid={testIds.warehouse.serviceOrderIssueDetailIssueNotes('section.issueNotes')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailIssueNotes('navigation.issueNotes'),
    },
    {
      id: 'documents',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.serviceOrderIssueDetailDocuments, {
        params: {id: serviceOrderIssueNoteId},
      }),
      content: (
        <Documents
          serviceOrderId={defaultTo('', serviceOrderIssueNote?.serviceOrderId)}
          state={serviceOrderIssueNote?.state}
          data-testid={testIds.warehouse.serviceOrderIssueDetailDocuments('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderIssueDetailDocuments('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.serviceOrderIssueDetail')} />
      <DetailTemplate
        key={serviceOrderIssueNoteId}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.serviceOrderIssueDetailOverview('page')}
      />
    </>
  );
}
