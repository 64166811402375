import {isFeatureEnabled} from 'feature-flags';
import {
  Card,
  DataStatus,
  FlagProps,
  Flags,
  Parameter,
  Parameters,
  TabProps,
  Tabs,
} from 'platform/components';
import {
  Box,
  Clickable,
  Heading,
  HStack,
  Show,
  Space,
  Stack,
  Text,
  VStack,
} from 'platform/foundation';
import {
  CurrencyFormat,
  useDateTimeFormatter,
  useFormatCurrency,
  useFormatNumber,
} from 'platform/locale';
import {match} from 'ts-pattern';

import {useEffect} from 'react';

import {defaultTo, includes} from 'ramda';
import {concatAll, isFalse, isFalsy, isNotNil, isPositive} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetServiceOrderIntegrationsQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantQuery,
  useGetCheckoutByContextQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {
  CommentsWidget,
  DEFAULT_CURRENCY,
  DEFAULT_PRICE,
  NoteWidget,
  ORDER_TABS,
  queryParams,
  usePermissions,
  useStateFlag,
} from '@omnetic-dms/shared';

import {
  buildArray,
  DOT_CHARACTER,
  Nullish,
  parseDate,
  RequiredTestIdProps,
  suffixTestId,
  useQueryState,
} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';
import {OrderType} from '../../../../../types/OrderType';
import {getSparePartStateFlag} from '../../../../../utils/getSparePartStateFlag';
import {getTimeTrackingStateFlag} from '../../../../../utils/getTimeTrackingStateFlag';
import {getMarkersFlags} from '../../../utils/getMarkersFlags';
import {Checkout} from './Checkout';
import {OrderActions} from './OrderActions';
import {OrderDetail} from './OrderDetail';
import {OrderItemsDataGrid} from './OrderItemsDataGrid';
import {OrderJobs} from './OrderJobs';
import {ProfitabilityDataGrid} from './ProfitabilityDataGrid';
import {ProfitabilitySummary} from './ProfitabilitySummary';
import {ServiceOrderDocuments} from './ServiceOrderDocuments';

const OVERPRICED_THRESHOLD = 10;
const PERCENTAGE_MULTIPLIER = 100;
const DIFFERENCE_OFFSET = 1;

interface OrderCardProps extends RequiredTestIdProps {
  order: OrderType;
  isExpanded: boolean;
  onExpand: () => void;
  onDataChange: () => void;
  dataGridKey: number;
}

export function OrderCard(props: OrderCardProps) {
  const [orderTabId, setOrderTabId] = useQueryState(queryParams.SERVICE_CASE_ORDER_TAB);
  const {orderId} = useWorkshopUrl();

  const [isChronosEnabled] = usePermissions({permissionKeys: ['chronosUser']});

  const formatDateTime = useDateTimeFormatter();
  const formatCurrency = useFormatCurrency();
  const formatNumber = useFormatNumber();

  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: isServiceOrderErrored,
  } = useGetServiceOrderQuery(
    {
      serviceCaseId: String(props.order.serviceCaseId),
      serviceOrderId: String(props.order.id),
    },
    {
      skip: !props.order.serviceCaseId || !props.order.id,
    }
  );
  const {data: serviceOrderVariant} = useGetServiceOrderVariantQuery(
    {serviceOrderVariantId: String(serviceOrder?.serviceOrderVariantId ?? '')},
    {skip: !serviceOrder?.serviceOrderVariantId}
  );
  const {data: orderIntegrations} = useGetServiceOrderIntegrationsQuery(
    {
      serviceCaseId: String(props.order.serviceCaseId),
      serviceOrderId: String(props.order.id),
    },
    {skip: !props.order.serviceCaseId || !props.order.id}
  );

  const [serviceOrderDocumentDataGrid, refreshServiceOrderDocumentDataGrid] = useRefreshDataGrid();

  useEffect(() => {
    if (
      isFalse(serviceOrder?.isCheckoutEnabled) &&
      orderTabId === ORDER_TABS.CHECKOUT &&
      props.order.id === orderId
    ) {
      setOrderTabId(ORDER_TABS.JOBS);
    }
  }, [orderTabId, setOrderTabId, serviceOrder?.isCheckoutEnabled, orderId, props.order.id]);

  const orderState = serviceOrder?.state || props.order.state;

  const stateFlag = useStateFlag(orderState);
  const markersFlags = getMarkersFlags(props.order?.markers);
  const timeTrackingFlag =
    orderState === 'OPEN' && isFeatureEnabled(featureFlags.ACL_CHRONOS) && isChronosEnabled
      ? getTimeTrackingStateFlag(props.order.timeTrackingState)
      : undefined;
  const sparePartFlag = getSparePartStateFlag(props.order.sparePartWarehouseState);

  const isOrderReadOnly = includes(serviceOrder?.state, [
    'LOCKED',
    'ARCHIVED',
    'CLOSED',
    'CANCELLED',
  ]);

  const orderCheckoutQuery = useGetCheckoutByContextQuery(
    {
      contextId: props.order.id || '',
      contextTarget: 'service-order',
    },
    {skip: !props.order.id || props.order.state !== 'CLOSED'}
  );

  const closedOrderParams = buildArray<Parameter>()
    .when(serviceOrderVariant?.name, serviceOrderVariant?.name)
    .when(
      serviceOrder?.completionAt,
      () =>
        `${i18n.t('general.labels.completion')}: ${formatDateTime(
          'dateTimeMedium',
          parseDate(serviceOrder?.completionAt)
        )}`
    )
    .when(orderCheckoutQuery.data?.invoice, () => {
      const paymentMethod = i18n.t(
        `entity.invoice.paymentMethod.${orderCheckoutQuery.data?.paymentInfo?.method?.toLowerCase()}`
      );

      return i18n.t('entity.invoice.labels.numberPayment', {
        number: orderCheckoutQuery.data?.invoice?.number,
        method: paymentMethod,
      });
    });

  const cancelledOrderParams = buildArray<Parameter>()
    .when(serviceOrderVariant?.name, serviceOrderVariant?.name)
    .when(
      serviceOrder?.completionAt,
      () =>
        `${i18n.t('general.labels.completion')}: ${formatDateTime(
          'dateTimeMedium',
          parseDate(serviceOrder?.completionAt)
        )}`
    );

  const commonParameters = buildArray<Parameter>()
    .when(serviceOrderVariant?.name, serviceOrderVariant?.name)
    .when(
      serviceOrder?.completionAt,
      serviceOrder?.completionAt &&
        `${i18n.t('general.labels.completion')}: ${formatDateTime(
          'dateTimeMedium',
          parseDate(serviceOrder?.completionAt)
        )}`
    )
    .when(
      serviceOrder?.claimState,
      i18n.t(`entity.order.labels.claimState.${serviceOrder?.claimState}`)
    );

  const isReportButtonDisabled =
    orderIntegrations?.orderIntegration
      ?.find((integration) => integration?.integration?.code === 'CHRONOS')
      ?.action?.find((action) => action.key === 'chronosContractReport')?.style === 'disabled';

  const tabs: TabProps[] = [
    {
      title: i18n.t('entity.orderRequest.labels.listTitle'),
      id: ORDER_TABS.JOBS,
      'data-testid': suffixTestId('requests', props),
      content: serviceOrder?.id && serviceOrder?.serviceCaseId && (
        <OrderJobs
          data-testid={suffixTestId('jobs', props)}
          orderId={serviceOrder.id}
          orderVariantId={defaultTo(undefined, serviceOrder.serviceOrderVariantId)}
          serviceCaseId={serviceOrder.serviceCaseId}
          isReadOnly={isOrderReadOnly}
          datagridKey={`order-jobs-${props.dataGridKey}`}
          isReportButtonDisabled={isReportButtonDisabled}
        />
      ),
    },
    {
      title: i18n.t('entity.order.labels.detailTitle'),
      id: ORDER_TABS.DETAIL,
      'data-testid': suffixTestId('detail', props),
      content: serviceOrder?.id && serviceOrder?.serviceCaseId && (
        <OrderDetail
          data-testid={suffixTestId('detail', props)}
          orderId={serviceOrder.id}
          serviceCaseId={serviceOrder.serviceCaseId}
          isReadOnly={isOrderReadOnly}
        />
      ),
    },
    {
      title: i18n.t('entity.orderRequest.labels.allItems'),
      id: ORDER_TABS.ALL_ITEMS,
      'data-testid': suffixTestId('items', props),
      content: (
        <OrderItemsDataGrid
          data-testid={suffixTestId('allJobs', props)}
          orderId={props.order.id}
          serviceCaseId={props.order.serviceCaseId}
          key={`order-items-${props.dataGridKey}`}
        />
      ),
    },
    {
      title: i18n.t('general.labels.documents'),
      id: ORDER_TABS.DOCUMENTS,
      'data-testid': suffixTestId('documents', props),
      content: (
        <ServiceOrderDocuments
          dataGridRef={serviceOrderDocumentDataGrid}
          refreshDataGrid={refreshServiceOrderDocumentDataGrid}
        />
      ),
    },
    {
      title: i18n.t('entity.order.labels.commentsAndNotes'),
      id: ORDER_TABS.COMMENTS_NOTES,
      'data-testid': suffixTestId('note', props),
      content: (
        <Stack direction={['column', 'column', 'column', 'row']} spacing={4} width="100%">
          <Box flex={1}>
            <CommentsWidget
              cardVariant="inlineWhite"
              resourceId={EntityResourceIds.serviceOrder}
              recordId={orderId}
              data-testid={suffixTestId('note', props)}
            />
          </Box>
          <Box flex={1}>
            {serviceOrder?.id && serviceOrder?.serviceCaseId && (
              <NoteWidget
                resourceId={EntityResourceIds.serviceOrder}
                recordId={serviceOrder.id}
                context={serviceOrder.serviceCaseId}
                data-testid={suffixTestId('note', props)}
                isEditable
              />
            )}
          </Box>
        </Stack>
      ),
    },
    {
      title: i18n.t('entity.order.labels.profitability'),
      id: ORDER_TABS.PROFITABILITY,
      'data-testid': suffixTestId('profitability', props),
      content: serviceOrder?.id && serviceOrder?.serviceCaseId && (
        <VStack spacing={4}>
          <ProfitabilitySummary
            order={serviceOrder}
            isReportButtonDisabled={isReportButtonDisabled}
            serviceCaseId={serviceOrder.serviceCaseId}
            data-testid={suffixTestId('profitability', props)}
          />
          <Heading size={4}>{i18n.t('entity.order.labels.orderProfitability')}</Heading>
          <ProfitabilityDataGrid
            orderId={serviceOrder.id}
            serviceCaseId={serviceOrder.serviceCaseId}
          />
        </VStack>
      ),
    },
    {
      title: i18n.t('entity.order.labels.checkout'),
      id: ORDER_TABS.CHECKOUT,
      isDisabled: isFalsy(serviceOrder?.isCheckoutEnabled),
      'data-testid': suffixTestId('checkout', props),
      content: <Checkout data-testid={suffixTestId('checkout', props)} />,
    },
  ];

  const priceDifference = getPriceDifference(
    props.order.totalPrice?.withVat?.amount,
    props.order.totalPriceEstimated?.amount
  );
  const isOverpriced = isNotNil(priceDifference) && priceDifference > OVERPRICED_THRESHOLD;

  return (
    <Card>
      <Clickable onClick={props.onExpand}>
        <HStack align="flex-start" justify="space-between">
          <HStack spacing={3} align="center">
            <Heading size={3} data-testid={suffixTestId('name', props)}>
              {defaultTo('-', props.order.number)}
            </Heading>
            <Flags
              size="small"
              spacing={1}
              flags={concatAll<FlagProps[]>([
                buildArray<FlagProps>()
                  .when(isNotNil(stateFlag), stateFlag as FlagProps)
                  .when(isNotNil(timeTrackingFlag), timeTrackingFlag!)
                  .when(props.order.synchronizeCustomer, {
                    label: i18n.t('entity.order.labels.differentCustomer'),
                    colorScheme: 'red',
                  })
                  .when(isNotNil(sparePartFlag), sparePartFlag!),
                markersFlags,
              ])}
              data-testid={suffixTestId('state', props)}
            />
          </HStack>
          <OrderActions
            order={props.order}
            isExpanded={props.isExpanded}
            onExpand={props.onExpand}
            onDataChange={props.onDataChange}
            serviceOrderDocumentDataGrid={serviceOrderDocumentDataGrid}
            refreshServiceOrderDocumentDataGrid={refreshServiceOrderDocumentDataGrid}
            isOrderReadOnly={isOrderReadOnly}
            data-testid={props['data-testid']}
          />
        </HStack>
        <Space vertical={1} />
        <HStack align="flex-start" justify="space-between">
          <Parameters
            size="small"
            color="secondary"
            parameters={match(props.order.state)
              .with('CLOSED', () => closedOrderParams)
              .with('CANCELLED', () => cancelledOrderParams)
              .otherwise(() => commonParameters)}
            data-testid={suffixTestId('parameters', props)}
          />
          <HStack>
            <HStack spacing={1}>
              <Text
                size="small"
                color="secondary"
                data-testid={suffixTestId('totalPriceEstimated', props)}
              >
                {`${i18n.t('general.labels.estimated')}: ${formatCurrency(
                  defaultTo(DEFAULT_PRICE, props.order.totalPriceEstimated?.amount),
                  props.order.totalPriceEstimated?.currency ?? DEFAULT_CURRENCY,
                  2
                )}`}
              </Text>
              <Text size="small" color="secondary">
                {DOT_CHARACTER}
              </Text>
              <Text
                size="small"
                alternative
                color={isOverpriced ? 'danger' : undefined}
                data-testid={suffixTestId('totalPriceWithVat', props)}
              >
                <CurrencyFormat
                  number={defaultTo(DEFAULT_PRICE, props.order.totalPrice?.withVat?.amount)}
                  currency={defaultTo(DEFAULT_CURRENCY, props.order.totalPrice?.withVat?.currency)}
                  decimals={2}
                />
                {isOverpriced && ` (${formatNumber(priceDifference, 1)}%)`}
              </Text>
            </HStack>
          </HStack>
        </HStack>
      </Clickable>
      <Show when={props.isExpanded}>
        <Space vertical={4} />
        <DataStatus
          isLoading={isServiceOrderLoading}
          isError={isServiceOrderErrored}
          minHeight={56}
        >
          <Tabs
            variant="condensed"
            onChange={setOrderTabId}
            activeTabId={orderTabId}
            tabs={tabs}
            data-testid={suffixTestId('tabs', props)}
            isLazy
            shouldWrap
          />
        </DataStatus>
      </Show>
    </Card>
  );
}

const getPriceDifference = (realPrice: number | Nullish, estimatedPrice: number | Nullish) =>
  isPositive(realPrice) && isPositive(estimatedPrice)
    ? (realPrice / estimatedPrice - DIFFERENCE_OFFSET) * PERCENTAGE_MULTIPLIER
    : null;
