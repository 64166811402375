/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
enum featureFlags {
  ACL_CORE = 'acl_core',
  ACL_SOURCING = 'acl_sourcing',
  ACL_VEHICLE_PHOTOS = 'acl_vehicle_photos',
  ACL_VEHICLE_PHOTOS_DEBUG = 'acl_vehicle_photos_debug',
  QUICK_PURCHASE_MODULE = 'acl_quick_purchase_module',
  AFTS_SERVICE_VEHICLE_ENTITY_V2 = 'afts_service_vehicle_entity_v2',
  DEMO_PAGE_DATAGRID_V4 = 'demo-page-datagrid-v4',
  DASHBOARD_V2 = 'dashboard_v2',
  SIDEBAR = 'omnetic_sidebar',
  SETTINGS_MANAGEMENT_SPLIT = 'core_settings_management_split',
  NOTIFICATION_CENTER = 'notification_center',
  PRICE_REPORT_WIDGET = 'acl_pricereport_widget_omnetic',
  BUSINESS_CASE_PRICING_SIMPLIFIED = 'business_case_pricing_simplified',
  BUSINESS_CASE_OVERVIEW = 'business_case_overview',
  AFS_SERVICE_CASE_VEHICLE = 'afs_service_case_vehicle',
  SALES_BROKERAGE_CONTRACT_CANCELLATION = 'sales_brokerage_contract_cancelation',
  SALES_OF_DEDUCTIBLE_VEHICLE = 'sales_of_deductible_vehicle',
  ACCOUNTING_CANCEL_V2 = 'core_accounting_canceling_v2',
  SALES_PRE_ACCOUNTING = 'sales_pre_accounting',
  SALES_VAT_CHECK_TOTALS = 'sales_vat_check_totals',
  TENANT_V2 = 'pl_addresses_in_tenant_settings',
  SALES_FOREIGN_CURRENCY = 'sales_foreign_currency',
  SALES_360_VEHICLES = 'vehicle_360_view_datagrid',
  OS_BUSINESS_CASES = 'businesscases_360_view_datagrid',
  SALES_CEBIA_REPORT_CLASSIFIED_PUBLISHING_SWITCH = 'sales_cebia_report_classified_publishing_switch',
  SALES_CEBIA_SMART_CODE_URL_PUBLISHING_SWITCH = 'sales_cebia_smart_code_url_publishing_switch',
  VEHICLE_HIGHLIGHTED_FEATURES = 'core_vehicle_highlighted_features',
  SALES_CENTRALIZED_PRICING = 'sales_buying_pricer_role',
  SALES_FEATURE_SCORING = 'acl_sales_feature_scoring',
  SALES_SUPPLIER_INVOICE = 'sales_supplier_invoice',
  SALES_VEHICLE_PRICE_SUMMARY = 'sales_vehicle_price_summary',
  ACL_REPORTING = 'acl_reporting',
  SALES_BGR_BANNER = 'sales_bgr_banner',
  CORE_ACTIVITY_LOGS = 'core_activity_logs',
  ACL_WAREHOUSE = 'acl_warehouse',
  SALES_VEHICLE_WAREHOUSE_MANAGEMENT = 'sales_vehicle_warehouse_management',
  INSPECTION_MODULE = 'acl_inspection_module',
  VEHICLE_RESERVATION_FUTURE_AGREEMENT = 'vehicle_reservation_future_agreement',
  SALES_CUSTOM_MAKE_MODEL = 'sales_custom_make_model',
  ACCOUNTING_ADDITIONAL_CUSTOMER = 'sales_accounting_additional_customer',
  ADVERTISEMENT_GENERAL_SETTINGS = 'sales_ads_generalsettings',
  ADVERTISEMENT_VERSION_GENERATION_ATTRIBUTES = 'sales_ads_version_generation_attributes',
  ADVERTISEMENT_DIGITAL_CERTIFICATE_VIDEO_URL_ATTRIBUTES = 'sales_ads_digital_certificate_video_url_attributes',
  AUTOSTACJA_CUSTOMER_WIDGET = 'autostacja_customer_widget',
  NOTIFICATIONS_COMMENT_MENTION = 'notifications_comment_mention',
  ACL_CHRONOS = 'acl_chronos',
  VEHICLE_CUSTOM_FIELDS = 'vehicle_custom_fields',
  AFS_SERVICE_CASE_OWNERSHIP = 'afs_service_case_ownership',
  AFTS_CUSTOMER_PAYMENT_SETTINGS = 'afts_customer_payment_settings',
  SIDEBAR_REMOVE_QUICK_ACTIONS = 'sidebar_remove_quick_actions',
  ACCOUNTING = 'core_accounting',
  ACCOUNTING_MARGIN_INVOICES = 'core_accounting_margin_invoices',
  ACCOUNTING_PROCESSING = 'core_accounting_processing',
  ACL_WORKSHOP = 'acl_workshop',
  SALES_CLAIMS = 'sales_claims',
  REPLACE_VEHICLE_DG_WITH_OPENSEARCH = 'replace_vehicle_dg_with_opensearch',
  VEHICLE_MULTIPLE_ALBUMS = 'vehicle_multiple_albums',
  SALES_INSURANCE_COMPARISON_V1 = 'sales_insurance_comparison_v1',
  ACL_SALES = 'acl_sales',
  VEHICLE_PRICEREPORT_WIDGET_FORM = 'vehicle_pricereport_widget_form',
  SOURCING_CARVAGO_AVAILABILITY = 'sourcing_carvago_availability',
  SOURCING_SHOW_UNCERTAINTY_PERCENTAGE = 'sourcing_show_uncertainty_percentage',
  CORE_CUSTOM_USER_ID = 'core_custom_userid',
  GOOGLE_MAP = 'google_map',
  REMOVE_VEHICLE_FROM_SALE = 'remove_vehicle_from_sale',
  CORE_CUSTOMER_GROUPS = 'core_customer_groups',
  CORE_FINANCING_INFORMATION_V2 = 'core_financing_information_v2',
  SALES_MOT_VALIDITY = 'sales_mot_validity',
  SALES_BACKGROUND_REMOVAL = 'sales_background_removal',
  SALES_PHOTO_ROTATION = 'sales_photo_rotation',
  ACL_EMPLOYEE_MANAGEMENT = 'acl_employee_management',
  PNEUHOTEL_BASIC_VIEW = 'acl_pneuhotel_basic_view',
  CORE_NUMBER_OF_DOCUMENTS = 'core_number_of_documents',
  VEHICLE_FINANCIAL_INSTITUTION = 'vehicle_financial_institution',
  VEHICLE_THREE_HUNDRED_AND_SIXTY_PHOTOS = 'vehicle_three_hundred_and_sixty_photos',
  WORKSHOP_DOCUMENT_TEMPLATES = 'workshop_document_templates',
  WAREHOUSE_DOCUMENT_TEMPLATES = 'warehouse_document_templates',
  PNEUHOTEL_DOCUMENT_TEMPLATES = 'pneuhotel_document_templates',
  SALES_VEHICLE_ORIGINAL_PRICE = 'sales_vehicle_original_price',
  CKK_CONSENT_STATUS_FLOW = 'ckk_consent_status_flow',
  SETTINGS_WATERMARKS = 'settings_watermarks',
  SETTINGS_BACKGROUND_REMOVAL = 'settings_background_removal',
  SALES_SALE_VEHICLE_COMMENTS = 'sales_comments_for_sale_vehicle',
  SETTINGS_ADVERTISEMENT_MULTIBRANCH = 'settings_advertisement_multibranch',
  SETTINGS_ADVERTISEMENT_AUTOMATIC_UPDATE = 'settings_advertisement_automatic_update',
  SALES_VEHICLE_INSURANCE = 'sales_vehicle_insurance',
  SALES_FEATURES_ADDITIONAL_INFO = 'sales_features_additional_info',
  SALES_AUTOCANCEL_RESERVATION = 'sales_autocancel_reservation',
  CUSTOMER_ABROAD_SALE_SETTING = 'customer_abroad_sale_setting',
  SALES_VEHICLE_DAT_DECODER = 'sales_vehicle_dat_decoder',
  DC_VEHICLE_INSPECTION_SHARE_LINK = 'dc_vehicle_inspection_share_link',
  ACL_VAT_CALCULATION_DEFINITION = 'acl_vat_calculation_definition',
  DG_SHARED_PRESETS = 'dg_shared_presets_',
  ACL_VAT_CHECK_DEFINITION = 'acl_vat_check_definition',
  ACL_NEGATIVE_DOCUMENT_CANCELLATION_DEFINITION = 'acl_negative_document_cancellation_definition',
  ADVERTISEMENT_FAKE_PLATFORMS = 'advertisement_fake_platforms',
  DG_SHARE_CUSTOM_PRESET_COPY = 'dg_share_custom_preset_copy', // Will become used after [T20-52058]
  INSPECTION_TO_REVISION_NO_EDIT = 'inspection_to_revision_no_edit',
  PLATFORM_LIGHTBOX = 'platform_lightbox',
  INTERESTS_UTM = 'interests_utm',
  AFTS_TIRES_VEHICLE_CARD = 'afts_tires_vehicle_card',
  AFTS_TIRE_ORDERS_CUSTOMER_CARD = 'afts_tire_orders_customer_card',
  PNEUHOTEL_PROCESS_FLOW = 'pneuhotel_process_flow',
  MERCEDES_BACKGROUND_PHOTOS = 'mercedes_background_photos',
  MERCEDES_BACKGROUND_ZIZAK = 'mercedes_background_zizak',
  AFTS_LIST_TIRES_CUSTOMER_CARD = 'afts_list_tires_customer_card',
  OMNETIC_VIN_DECODER = 'omnetic_vin_decoder',
}

export default featureFlags;
export {featureFlags};
/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
