import {
  BulkDeleteDirectSaleItemsRequest,
  BulkDeleteDirectSaleItemsRequestSchema,
  BulkDeleteDirectSaleItemsResponse,
  BulkDeleteDirectSaleItemsResponseSchema,
  DeleteDirectSaleRequest,
  DeleteDirectSaleRequestSchema,
  DeleteDirectSaleResponse,
  DeleteDirectSaleResponseSchema,
  GetDirectSaleActionsRequest,
  GetDirectSaleActionsRequestSchema,
  GetDirectSaleActionsResponse,
  GetDirectSaleActionsResponseSchema,
  GetDirectSaleDiscountRequest,
  GetDirectSaleDiscountRequestSchema,
  GetDirectSaleDiscountResponse,
  GetDirectSaleDiscountResponseSchema,
  GetDirectSaleRequest,
  GetDirectSaleRequestSchema,
  GetDirectSaleResponse,
  GetDirectSaleResponseSchema,
  PatchDirectSaleGetCurrentPriceRequest,
  PatchDirectSaleGetCurrentPriceRequestSchema,
  PatchDirectSaleGetCurrentPriceResponse,
  PatchDirectSaleGetCurrentPriceResponseSchema,
  PatchDirectSaleRequest,
  PatchDirectSaleRequestSchema,
  PatchDirectSaleResponse,
  PatchDirectSaleResponseSchema,
  PostDirectSaleRequest,
  PostDirectSaleRequestSchema,
  PostDirectSaleResponse,
  PostDirectSaleResponseSchema,
  PutDirectSaleActionRequest,
  PutDirectSaleActionRequestSchema,
  PutDirectSaleActionResponse,
  PutDirectSaleActionResponseSchema,
  PutDirectSaleDiscountRequest,
  PutDirectSaleDiscountRequestSchema,
  PutDirectSaleDiscountResponse,
  PutDirectSaleDiscountResponseSchema,
} from '../types/metadaWarehouseDirectSale';
import {metadaApi} from './baseApi/metadaApi';

export const metadaWarehouseDirectSaleApi = metadaApi.injectEndpoints({
  endpoints: (build) => ({
    getDirectSale: build.query<GetDirectSaleResponse, GetDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'directSales', id: queryArg.directSaleId}],
      extraOptions: {
        requestSchema: GetDirectSaleRequestSchema,
        responseSchema: GetDirectSaleResponseSchema,
      },
    }),
    postDirectSale: build.mutation<PostDirectSaleResponse, PostDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale`,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: ['directSales'],
      extraOptions: {
        requestSchema: PostDirectSaleRequestSchema,
        responseSchema: PostDirectSaleResponseSchema,
      },
    }),
    deleteDirectSale: build.mutation<DeleteDirectSaleResponse, DeleteDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/delete`,
        method: 'POST',
        body: queryArg,
      }),
      invalidatesTags: ['directSales'],
      extraOptions: {
        requestSchema: DeleteDirectSaleRequestSchema,
        responseSchema: DeleteDirectSaleResponseSchema,
      },
    }),
    patchDirectSale: build.mutation<PatchDirectSaleResponse, PatchDirectSaleRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PatchDirectSaleRequestSchema,
        responseSchema: PatchDirectSaleResponseSchema,
      },
    }),
    getDirectSaleActions: build.query<GetDirectSaleActionsResponse, GetDirectSaleActionsRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/actions`,
        method: 'GET',
      }),
      providesTags: (result, error, queryArg) => [{type: 'directSales', id: queryArg.directSaleId}],
      extraOptions: {
        requestSchema: GetDirectSaleActionsRequestSchema,
        responseSchema: GetDirectSaleActionsResponseSchema,
      },
    }),
    bulkDeleteDirectSaleItems: build.mutation<
      BulkDeleteDirectSaleItemsResponse,
      BulkDeleteDirectSaleItemsRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/items-delete`,
        method: 'PATCH',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSales', id: queryArg.directSaleId},
        {type: 'directSaleMaterialBasket'},
      ],
      extraOptions: {
        requestSchema: BulkDeleteDirectSaleItemsRequestSchema,
        responseSchema: BulkDeleteDirectSaleItemsResponseSchema,
      },
    }),
    putDirectSaleAction: build.mutation<PutDirectSaleActionResponse, PutDirectSaleActionRequest>({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/action`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: ['directSales'],
      extraOptions: {
        requestSchema: PutDirectSaleActionRequestSchema,
        responseSchema: PutDirectSaleActionResponseSchema,
      },
    }),
    getDirectSaleDiscount: build.query<GetDirectSaleDiscountResponse, GetDirectSaleDiscountRequest>(
      {
        query: (queryArg) => ({
          url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/discount`,
          method: 'GET',
        }),
        providesTags: (result, error, queryArg) => [
          {type: 'directSaleDiscount', id: queryArg.directSaleId},
        ],
        extraOptions: {
          requestSchema: GetDirectSaleDiscountRequestSchema,
          responseSchema: GetDirectSaleDiscountResponseSchema,
        },
      }
    ),
    putDirectSaleDiscount: build.mutation<
      PutDirectSaleDiscountResponse,
      PutDirectSaleDiscountRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/discount`,
        body: queryArg.body,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'directSaleDiscount', id: queryArg.directSaleId},
        {type: 'directSales', id: queryArg.directSaleId},
      ],
      extraOptions: {
        requestSchema: PutDirectSaleDiscountRequestSchema,
        responseSchema: PutDirectSaleDiscountResponseSchema,
      },
    }),
    patchDirectSaleGetCurrentPrice: build.mutation<
      PatchDirectSaleGetCurrentPriceResponse,
      PatchDirectSaleGetCurrentPriceRequest
    >({
      query: (queryArg) => ({
        url: `warehouse/v1/direct-sale/${queryArg.directSaleId}/get-current-price`,
        body: queryArg.body,
        method: 'PATCH',
      }),
      extraOptions: {
        requestSchema: PatchDirectSaleGetCurrentPriceRequestSchema,
        responseSchema: PatchDirectSaleGetCurrentPriceResponseSchema,
      },
    }),
  }),
});

export const {
  useGetDirectSaleQuery,
  useLazyGetDirectSaleQuery,
  usePostDirectSaleMutation,
  usePatchDirectSaleMutation,
  useDeleteDirectSaleMutation,
  useBulkDeleteDirectSaleItemsMutation,
  useGetDirectSaleActionsQuery,
  usePutDirectSaleActionMutation,
  useGetDirectSaleDiscountQuery,
  usePutDirectSaleDiscountMutation,
  usePatchDirectSaleGetCurrentPriceMutation,
} = metadaWarehouseDirectSaleApi;
