import {z} from 'zod';

import {CountryAlpha3CodeEnumSchema} from '../types/cached/Countries';
import {FileDataSchema} from '../types/common/FileData';
import {Money, MoneySchema} from '../types/common/Money';
import {BigDecimal, BigDecimalSchema} from '../types/common/Price';
import {VatTypeEnum} from '../types/common/VatTypeEnum';
import {
  FileResponseBody,
  TemplateListItemResponseBodySchema,
  UserResponseBodySchema,
  VatType,
  VatTypeSchema,
} from './api';
import {
  CheckoutResponseBody,
  OrderPaymentResponseBody,
  OrderResponseBody,
  TemplatesListItemResponseBodySchema,
  TenantBankAccount,
} from './checkout';
import {VerificationRegisterResponseBodySchema} from './customer';

export const BusinessCaseInternalTypeSchema = z.enum([
  'SELLING',
  'BUYING',
  'SWAP',
  'PURCHASE_BROKERAGE',
]);
export type BusinessCaseInternalType = z.infer<typeof BusinessCaseInternalTypeSchema>;

export type GetBusinessCaseSeriesDefinitionListApiResponse =
  /** status 200  */ BusinessCaseSeriesDefinitionResponseBody[];
export type GetBusinessCaseSeriesDefinitionApiResponse =
  /** status 200  */ BusinessCaseSeriesDefinitionResponseBody;

export const GetBusinessCaseSeriesDefinitionApiArgSchema = z.object({
  seriesDefinitionId: z.string(),
});

export type GetBusinessCaseSeriesDefinitionApiArg = z.infer<
  typeof GetBusinessCaseSeriesDefinitionApiArgSchema
>;
export type CreateBusinessCaseSeriesDefinitionApiResponse =
  /** status 201  */ BusinessCaseSeriesDefinitionResponseBody;
export type CreateBusinessCaseSeriesDefinitionApiArg = {
  businessCaseSeriesDefinitionRequestBody: BusinessCaseSeriesDefinitionRequestBody;
};
export type PutBusinessCaseSeriesDefinitionApiResponse =
  /** status 200  */ BusinessCaseSeriesDefinitionResponseBody;

export const BusinessCaseSeriesDefinitionResponseBodySchema = z.object({
  id: z.string(),
  name: z.string(),
  branchId: z.string().nullable(),
  businessCaseSeriesId: z.string(),
  proformaInvoiceSeriesId: z.string(),
  invoiceSeriesId: z.string(),
  taxDocumentSeriesId: z.string(),
  brokerageSaleInvoiceSeriesId: z.string(),
  correctiveTaxDocumentSeriesId: z.string(),
  systemDefault: z.boolean(),
  brokerageFeesCorrectiveTaxDocumentSeriesId: z.string().nullable(),
  brokerageFeesInvoiceSeriesId: z.string().nullable(),
  brokerageFeesTaxDocumentSeriesId: z.string().nullable(),
  saleBrokerageInvoiceSeriesId: z.string(),
  saleBrokerageProformaInvoiceSeriesId: z.string(),
  saleBrokerageTaxDocumentSeriesId: z.string(),
  saleBrokerageCorrectiveTaxDocumentSeriesId: z.string(),
});

export const GetBusinessCaseSeriesDefinitionListApiArgSchema = z.void();

export type GetBusinessCaseSeriesDefinitionListApiArg = z.infer<
  typeof GetBusinessCaseSeriesDefinitionListApiArgSchema
>;

export type BusinessCaseSeriesDefinitionResponseBody = z.infer<
  typeof BusinessCaseSeriesDefinitionResponseBodySchema
>;

export const PutBusinessCaseSeriesDefinitionRequestBodySchema = z.object({
  name: z.string(),
  branchId: z.string().nullable(),
  businessCaseSeriesId: z.string(),
  proformaInvoiceSeriesId: z.string(),
  invoiceSeriesId: z.string(),
  taxDocumentSeriesId: z.string(),
  brokerageSaleInvoiceSeriesId: z.string().nullable(),
  correctiveTaxDocumentSeriesId: z.string().nullable(),
  brokerageFeesCorrectiveTaxDocumentSeriesId: z.string().nullable(),
  brokerageFeesInvoiceSeriesId: z.string().nullable(),
  brokerageFeesTaxDocumentSeriesId: z.string().nullable(),
  saleBrokerageInvoiceSeriesId: z.string(),
  saleBrokerageProformaInvoiceSeriesId: z.string(),
  saleBrokerageTaxDocumentSeriesId: z.string(),
  saleBrokerageCorrectiveTaxDocumentSeriesId: z.string(),
});

export const PutBusinessCaseSeriesDefinitionApiArgSchema = z.object({
  seriesDefinitionId: z.string(),
  putBusinessCaseSeriesDefinitionRequestBody: PutBusinessCaseSeriesDefinitionRequestBodySchema,
});

export type PutBusinessCaseSeriesDefinitionApiArg = z.infer<
  typeof PutBusinessCaseSeriesDefinitionApiArgSchema
>;

export const BusinessCaseSeriesDefinitionRequestBodySchema = z.object({
  name: z.string(),
  branchId: z.string().nullable(),
  businessCaseSeriesId: z.string(),
  proformaInvoiceSeriesId: z.string(),
  invoiceSeriesId: z.string(),
  taxDocumentSeriesId: z.string(),
  brokerageSaleInvoiceSeriesId: z.string().nullable(),
  correctiveTaxDocumentSeriesId: z.string().nullable(),
  brokerageFeesCorrectiveTaxDocumentSeriesId: z.string().nullable(),
  brokerageFeesInvoiceSeriesId: z.string().nullable(),
  brokerageFeesTaxDocumentSeriesId: z.string().nullable(),
  saleBrokerageInvoiceSeriesId: z.string(),
  saleBrokerageProformaInvoiceSeriesId: z.string(),
  saleBrokerageTaxDocumentSeriesId: z.string(),
  saleBrokerageCorrectiveTaxDocumentSeriesId: z.string(),
});

export type BusinessCaseSeriesDefinitionRequestBody = z.infer<
  typeof BusinessCaseSeriesDefinitionRequestBodySchema
>;

export type PutBusinessCaseSeriesDefinitionRequestBody = z.infer<
  typeof PutBusinessCaseSeriesDefinitionRequestBodySchema
>;

export const ChangeBranchBusinessCaseRequestBodySchema = z.object({
  branchId: z.string(),
});
export type ChangeBranchBusinessCaseRequestBody = z.infer<
  typeof ChangeBranchBusinessCaseRequestBodySchema
>;
export const OfferSaleVehiclePartialInfoSchema = z.object({
  id: z.string().nullish(),
  make: z.string().nullish(),
  trim: z.string().nullish(),
  modelFamily: z.string().nullish(),
  variant: z.string().nullish(),
  title: z.string().nullish(),
});

export type OfferSaleVehiclePartialInfo = z.infer<typeof OfferSaleVehiclePartialInfoSchema>;
export const OfferPurchaseVehiclePartialInfoSchema = z.object({
  id: z.string().nullish(),
  make: z.string().nullish(),
  trim: z.string().nullish(),
  modelFamily: z.string().nullish(),
  variant: z.string().nullish(),
  title: z.string().nullish(),
});

export type OfferPurchaseVehiclePartialInfo = z.infer<typeof OfferPurchaseVehiclePartialInfoSchema>;
export const OfferPurchaseBrokerageVehiclePartialInfoSchema = z.object({
  id: z.string().nullish(),
  make: z.string().nullish(),
  trim: z.string().nullish(),
  modelFamily: z.string().nullish(),
  variant: z.string().nullish(),
  title: z.string().nullish(),
});

export type OfferPurchaseBrokerageVehiclePartialInfo = z.infer<
  typeof OfferPurchaseBrokerageVehiclePartialInfoSchema
>;
export const OfferVehicleInformationSchema = z
  .object({
    saleVehiclesPartialInfo: z.array(OfferSaleVehiclePartialInfoSchema).nullish(),
    purchaseVehiclesPartialInfo: z.array(OfferPurchaseVehiclePartialInfoSchema).nullish(),
    purchaseBrokerageVehiclesPartialInfo: z
      .array(OfferPurchaseBrokerageVehiclePartialInfoSchema)
      .nullish(),
  })
  .nullable();
export type OfferVehicleInformation = z.infer<typeof OfferVehicleInformationSchema>;
export const LastActiveBusinessCaseResponseBodySchema = z.object({
  id: z.string(),
  code: z.string(),
  ownerId: z.string().nullish(),
  ownerFirstName: z.string().nullish(),
  ownerLastName: z.string().nullish(),
  customerName: z.string().nullish(),
  businessCaseType: z.string().nullish(),
  businessCaseState: z.string().nullish(),
  vehicleDataPartialInfo: OfferVehicleInformationSchema.optional(),
});
export type LastActiveBusinessCaseResponseBody = z.infer<
  typeof LastActiveBusinessCaseResponseBodySchema
>;
export type GetLastActiveBusinessCaseApiResponse =
  /** status 200  */ LastActiveBusinessCaseResponseBody;
export const GetLastActiveBusinessCaseApiArgSchema = z.object({
  vehicleId: z.string(),
  size: z.number().optional(),
  offset: z.number().optional(),
});

export type GetLastActiveBusinessCaseApiArg = z.infer<typeof GetLastActiveBusinessCaseApiArgSchema>;
export const GetLastActiveBusinessCaseByCustomerApiResponseSchema = z.array(
  LastActiveBusinessCaseResponseBodySchema
);
export type GetLastActiveBusinessCaseByCustomerApiResponse = z.infer<
  typeof GetLastActiveBusinessCaseByCustomerApiResponseSchema
>;

export const GetBusinessCaseByBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetBusinessCaseByBusinessCaseApiArg = z.infer<
  typeof GetBusinessCaseByBusinessCaseApiArgSchema
>;
export type GetBusinessCaseByBusinessCaseApiRes = LastActiveBusinessCaseResponseBody;
export const GetLastActiveBusinessCaseByCustomerApiArgSchema = z.object({
  customerId: z.string(),
});

export type GetLastActiveBusinessCaseByCustomerApiArg = z.infer<
  typeof GetLastActiveBusinessCaseByCustomerApiArgSchema
>;
export const GetActiveBusinessCaseByCustomerApiArgSchema = z.object({
  customerId: z.string(),
  size: z.number().optional(),
  offset: z.number().optional(),
});

export type GetActiveBusinessCaseByCustomerApiArg = z.infer<
  typeof GetActiveBusinessCaseByCustomerApiArgSchema
>;
export type CreateAgreementOnFutureContractApiResponse = /** status 200  */ FileResponseBody;

export const GetPurchaseTemplatesApiResponseSchema = z.array(TemplatesListItemResponseBodySchema);
export type GetPurchaseTemplatesApiResponse = z.infer<typeof GetPurchaseTemplatesApiResponseSchema>;
export const GetPurchaseBrokerageTemplatesApiResponseSchema = z.array(
  TemplatesListItemResponseBodySchema
);
export type GetPurchaseBrokerageTemplatesApiResponse = z.infer<
  typeof GetPurchaseBrokerageTemplatesApiResponseSchema
>;
export const GetPurchaseBrokerageTemplatesApiArgSchema = z.void();
export type GetPurchaseBrokerageTemplatesApiArg = z.infer<
  typeof GetPurchaseBrokerageTemplatesApiArgSchema
>;
export type CreatePurchaseBrokerageCancelationDocumentApiResponse =
  /** status 200  */ FileResponseBody;
export const GetSaleTemplatesApiResponseSchema = z.array(TemplatesListItemResponseBodySchema);
export type GetSaleTemplatesApiResponse = z.infer<typeof GetSaleTemplatesApiResponseSchema>;
export type CreateSaleOfferDocumentApiResponse = /** status 200  */ z.infer<
  typeof BusinessCaseResponseBodySchema
>;
export const GetSaleOfferTemplatesApiResponseSchema = z.array(TemplateListItemResponseBodySchema);
export type GetSaleOfferTemplatesApiResponse = z.infer<
  typeof GetSaleOfferTemplatesApiResponseSchema
>;
export const GetSaleOfferTemplatesApiArgSchema = z.void();
export type GetSaleOfferTemplatesApiArg = z.infer<typeof GetSaleOfferTemplatesApiArgSchema>;

export const PutBackToOfferApiResponseSchema = z.void();
export type PutBackToOfferApiResponse = z.infer<typeof PutBackToOfferApiResponseSchema>;
export const PutBackToOfferApiArgSchema = z.object({businessCaseId: z.string()});
export type PutBackToOfferApiArg = z.infer<typeof PutBackToOfferApiArgSchema>;

export const PutCloseContractApiResponseSchema = z.void();
export type PutCloseContractApiResponse = z.infer<typeof PutCloseContractApiResponseSchema>;
export const PutCloseContractApiArgSchema = z.object({businessCaseId: z.string()});
export type PutCloseContractApiArg = z.infer<typeof PutCloseContractApiArgSchema>;

export const PutReopenContractApiResponseSchema = z.void();
export type PutReopenContractApiResponse = z.infer<typeof PutReopenContractApiResponseSchema>;
export const PutReopenContractApiArgSchema = z.object({businessCaseId: z.string()});
export type PutReopenContractApiArg = z.infer<typeof PutReopenContractApiArgSchema>;

export type BrokerageFeesPrice = {
  /**
   * Price without VAT
   */
  withoutVat: Money;
  /**
   * Price with VAT
   */
  withVat: Money;
  /**
   * VAT
   */
  vat: Money;
  vatType: VatTypeEnum;
  vatRate: BigDecimal;
};
export enum PriceWithVatDirectionEnum {
  FROM_PRICE_WITHOUT_VAT = 'FROM_PRICE_WITHOUT_VAT',
  FROM_PRICE_WITH_VAT = 'FROM_PRICE_WITH_VAT',
}
export type CalculatedBrokerageServicesCost = {
  price: BrokerageFeesPrice;
};
export type CalculatedInvoicedBrokerageServices = {
  price: BrokerageFeesPrice;
  /**
   * Is service fee enabled?
   */
  isEnabled: boolean;
};
export type CalculatedBrokerageServices = {
  cost: CalculatedBrokerageServicesCost | null;
  invoiced: CalculatedInvoicedBrokerageServices | null;
};
export type CalculatedSalesCommissionFixed = {
  price: BrokerageFeesPrice;
};
export type CalculatedSalesCommissionPercentage = {
  price: BrokerageFeesPrice;
  percentage: BigDecimal;
  isWithVat: boolean;
};
export type CalculatedSalesCommissionMinimal = {
  price: BrokerageFeesPrice;
};

export type CalculatedSalesCommission = {
  /**
   * Price
   */
  fixed: CalculatedSalesCommissionFixed | null;
  /**
   * Percentage
   */
  percentage: CalculatedSalesCommissionPercentage | null;
  /**
   * Price
   */
  minimal: CalculatedSalesCommissionMinimal | null;
  /**
   * Total
   */
  total: BrokerageFeesPrice;
  isEnabled: boolean;
};
export type CalculatedBrokerageFees = {
  brokerageServices: CalculatedBrokerageServices;
  salesCommission: CalculatedSalesCommission;
  profit: CalculatedBrokerageFeesProfit;
};
export type CalculatedBrokerageFeesProfit = {
  /**
   * Profit without VAT
   */
  withoutVat: Money;
  /**
   * Profit with VAT
   */
  withVat: Money;
  /**
   * Profit VAT
   */
  vat: Money;
  percentage: BigDecimal;
};
export type BussinessCasePrice = {
  withoutVat: Money | null;
  withVat: Money | null;
  vatRate: string | null;
  vatCode: string | null;
  percentage: string | null;
  direction: PriceWithVatDirectionEnum | null;
};
export type VehicleSale = {
  id: string;
  title: string;
  upsellTotalWithVat: Money | null;
  upsellTotalWithoutVat: Money | null;
  sellingPrice: BussinessCasePrice | null;
  priceTotalAfterDiscount: BussinessCasePrice | null;
  profit: BussinessCasePrice | null;
  margin: string | null;
  discountPrice: BussinessCasePrice | null;
  purchasePrice: BussinessCasePrice | null;
};
export type VehiclePurchase = {
  id: string;
  title: string;
  profit: BussinessCasePrice | null;
  margin: string | null;
  maxBuyingPrice: BussinessCasePrice | null;
  customerRequestedPrice: Money | null;
  proposedSellingPrice: BussinessCasePrice | null;
  purchasePrice: BussinessCasePrice | null;
};
export type VehiclePurchaseBrokerage = {
  id: string;
  title: string;
  proposedSellingPrice: BussinessCasePrice | null;
  calculatedBrokerageFees: CalculatedBrokerageFees | null;
};
export const PriceWithVatDirectionSchema = z.enum([
  'FROM_PRICE_WITHOUT_VAT',
  'FROM_PRICE_WITH_VAT',
]);
export type PriceWithVatDirection = z.infer<typeof PriceWithVatDirectionSchema>;
export const OfferPriceResponseBodySchema = z.object({
  withoutVat: MoneySchema.nullable(),
  withVat: MoneySchema.nullable(),
  vatRate: z.string().nullable(),
  vatCode: z.string().nullable(),
  percentage: z.string().nullable(),
  direction: PriceWithVatDirectionSchema.nullable(),
});
export type OfferPriceResponseBody = z.infer<typeof OfferPriceResponseBodySchema>;
export const VehicleSaleResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  upsellTotalWithVat: MoneySchema.nullable(),
  upsellTotalWithoutVat: MoneySchema.nullable(),
  sellingPrice: OfferPriceResponseBodySchema.nullable(),
  priceTotalAfterDiscount: OfferPriceResponseBodySchema.nullable(),
  profit: OfferPriceResponseBodySchema.nullable(),
  margin: z.string().nullable(),
  discountPrice: OfferPriceResponseBodySchema.nullable(),
  purchasePrice: OfferPriceResponseBodySchema.nullable(),
});
export type VehicleSaleResponseBody = z.infer<typeof VehicleSaleResponseBodySchema>;
export const VehiclePurchaseResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  profit: OfferPriceResponseBodySchema.nullable(),
  margin: z.string().nullable(),
  maxBuyingPrice: OfferPriceResponseBodySchema.nullable(),
  customerRequestedPrice: MoneySchema.nullable(),
  proposedSellingPrice: OfferPriceResponseBodySchema.nullable(),
  purchasePrice: OfferPriceResponseBodySchema.nullable(),
});
export type VehiclePurchaseResponseBody = z.infer<typeof VehiclePurchaseResponseBodySchema>;
export const BrokerageFeesPriceResponseBodySchema = z.object({
  withoutVat: MoneySchema,
  withVat: MoneySchema,
  vat: MoneySchema,
  vatType: VatTypeSchema,
  vatRate: BigDecimalSchema,
});
export type BrokerageFeesPriceResponseBody = z.infer<typeof BrokerageFeesPriceResponseBodySchema>;
export const CalculatedBrokerageServicesCostResponseBodySchema = z.object({
  price: BrokerageFeesPriceResponseBodySchema,
});
export type CalculatedBrokerageServicesCostResponseBody = z.infer<
  typeof CalculatedBrokerageServicesCostResponseBodySchema
>;
export const CalculatedInvoicedBrokerageServicesResponseBodySchema = z.object({
  price: BrokerageFeesPriceResponseBodySchema,
  isEnabled: z.boolean(),
});
export type CalculatedInvoicedBrokerageServicesResponseBody = z.infer<
  typeof CalculatedInvoicedBrokerageServicesResponseBodySchema
>;
export const CalculatedBrokerageServicesResponseBodySchema = z.object({
  cost: CalculatedBrokerageServicesCostResponseBodySchema.nullable(),
  invoiced: CalculatedInvoicedBrokerageServicesResponseBodySchema.nullable(),
});
export type CalculatedBrokerageServicesResponseBody = z.infer<
  typeof CalculatedBrokerageServicesResponseBodySchema
>;
export const CalculatedBrokerageFeesProfitResponseBodySchema = z.object({
  withoutVat: MoneySchema,
  withVat: MoneySchema,
  vat: MoneySchema,
  percentage: BigDecimalSchema,
});
export type CalculatedBrokerageFeesProfitResponseBody = z.infer<
  typeof CalculatedBrokerageFeesProfitResponseBodySchema
>;
export const CalculatedSalesCommissionFixedResponseBodySchema = z.object({
  price: BrokerageFeesPriceResponseBodySchema,
});
export type CalculatedSalesCommissionFixedResponseBody = z.infer<
  typeof CalculatedSalesCommissionFixedResponseBodySchema
>;
export const CalculatedSalesCommissionPercentageResponseBodySchema = z.object({
  price: BrokerageFeesPriceResponseBodySchema,
  percentage: BigDecimalSchema,
});
export type CalculatedSalesCommissionPercentageResponseBody = z.infer<
  typeof CalculatedSalesCommissionPercentageResponseBodySchema
>;
export const CalculatedSalesCommissionMinimalResponseBodySchema = z.object({
  price: BrokerageFeesPriceResponseBodySchema,
});
export type CalculatedSalesCommissionMinimalResponseBody = z.infer<
  typeof CalculatedSalesCommissionMinimalResponseBodySchema
>;
export const CalculatedSalesCommissionResponseBodySchema = z.object({
  fixed: CalculatedSalesCommissionFixedResponseBodySchema.nullable(),
  percentage: CalculatedSalesCommissionPercentageResponseBodySchema.nullable(),
  minimal: CalculatedSalesCommissionMinimalResponseBodySchema.nullable(),
  total: BrokerageFeesPriceResponseBodySchema,
  isEnabled: z.boolean(),
});
export type CalculatedSalesCommissionResponseBody = z.infer<
  typeof CalculatedSalesCommissionResponseBodySchema
>;
export const CalculatedBrokerageFeesResponseBodySchema = z.object({
  brokerageServices: CalculatedBrokerageServicesResponseBodySchema,
  salesCommission: CalculatedSalesCommissionResponseBodySchema,
  profit: CalculatedBrokerageFeesProfitResponseBodySchema,
  contractLength: z.number().nullable(),
});
export type CalculatedBrokerageFeesResponseBody = z.infer<
  typeof CalculatedBrokerageFeesResponseBodySchema
>;
export const VehiclePurchaseBrokerageResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  proposedSellingPrice: OfferPriceResponseBodySchema.nullable(),
  calculatedBrokerageFees: CalculatedBrokerageFeesResponseBodySchema.nullable(),
});
export type VehiclePurchaseBrokerageResponseBody = z.infer<
  typeof VehiclePurchaseBrokerageResponseBodySchema
>;

export const ActiveBusinessCaseResponseBodySchema = z.object({
  id: z.string(),
  code: z.string(),
  createdById: z.string().nullable(),
  createdByFirstName: z.string().nullable(),
  createdByLastName: z.string().nullable(),
  createdAt: z.string(),
  updatedById: z.string().nullable(),
  updatedByFirstName: z.string().nullable(),
  updatedByLastName: z.string().nullable(),
  updatedAt: z.string().nullable(),
  customerName: z.string().nullable(),
  customerId: z.string().nullable(),
  businessCaseType: z.string().nullable(),
  businessCaseState: z.string().nullable(),
  offerTotalPrice: OfferPriceResponseBodySchema.nullable(),
  offerTotalMarginPrice: OfferPriceResponseBodySchema.nullable(),
  offerTotalMarginPercentage: z.string().nullable(),
  vehiclesSale: z.array(VehicleSaleResponseBodySchema),
  vehiclesPurchase: z.array(VehiclePurchaseResponseBodySchema),
  vehiclesPurchaseBrokerage: z.array(VehiclePurchaseBrokerageResponseBodySchema),
});
export type ActiveBusinessCaseResponseBody = z.infer<typeof ActiveBusinessCaseResponseBodySchema>;
export const GetActiveBusinessCaseByCustomerApiResponseSchema = z.array(
  ActiveBusinessCaseResponseBodySchema
);
export type GetActiveBusinessCaseByCustomerApiResponse = z.infer<
  typeof GetActiveBusinessCaseByCustomerApiResponseSchema
>;

export const BrokerageContractCancelationRequestBodySchema = z.object({
  templateId: z.string(),
  businessCaseId: z.string(),
  note: z.string().nullable(),
});

export type BrokerageContractCancelationRequestBody = z.infer<
  typeof BrokerageContractCancelationRequestBodySchema
>;
export const CreatePurchaseBrokerageCancelationDocumentApiArgSchema = z.object({
  brokerageContractCancelationRequestBody: BrokerageContractCancelationRequestBodySchema,
});
export type CreatePurchaseBrokerageCancelationDocumentApiArg = z.infer<
  typeof CreatePurchaseBrokerageCancelationDocumentApiArgSchema
>;
export const SaleOfferRequestBodySchema = z.object({
  templateId: z.string(),
  businessCaseId: z.string(),
});
export type SaleOfferRequestBody = z.infer<typeof SaleOfferRequestBodySchema>;
export const CreateSaleOfferDocumentApiArgSchema = z.object({
  saleOfferRequestBody: SaleOfferRequestBodySchema,
});
export type CreateSaleOfferDocumentApiArg = z.infer<typeof CreateSaleOfferDocumentApiArgSchema>;
export type EditPricesSimplifiedApiResponse = unknown;
export type EditPricesSimplifiedApiArg = {
  businessCaseId: string;
  offerPurchaseVehicleId: string;
  vehicleId: string;
  editPricingSimplifiedRequestBody: EditPricingSimplifiedRequestBody;
};
export const DeprecatedGetVerificationRegistersApiResponseSchema = z.array(
  VerificationRegisterResponseBodySchema
);
export type DeprecatedGetVerificationRegistersApiResponse = z.infer<
  typeof DeprecatedGetVerificationRegistersApiResponseSchema
>;
export const DeprecatedGetVerificationRegistersApiArgSchema = z.object({
  businessCaseId: z.string(),
  contractInformationId: z.string(),
});

export type DeprecatedGetVerificationRegistersApiArg = z.infer<
  typeof DeprecatedGetVerificationRegistersApiArgSchema
>;
export type GetCheckoutForBusinessCaseApiResponse = /** status 200  */ CheckoutResponseBody;
export const GetCheckoutForBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetCheckoutForBusinessCaseApiArg = z.infer<
  typeof GetCheckoutForBusinessCaseApiArgSchema
>;
export const VerificationRegisterTypeSchema = z.enum([
  'distraint',
  'insolvency',
  'invalid_document',
  'unreliable_vat_payer',
  'eu_vies',
]);

export type VerificationRegisterType = z.infer<typeof VerificationRegisterTypeSchema>;
export const VerifyRequestBodySchema = z.object({
  verificationRegisterTypes: z.array(VerificationRegisterTypeSchema),
});
export type VerifyRequestBody = z.infer<typeof VerifyRequestBodySchema>;
export const DeprecatedVerifyApiArgSchema = z.object({
  businessCaseId: z.string(),
  contractInformationId: z.string(),
  body: VerifyRequestBodySchema,
});
export type DeprecatedVerifyApiArg = z.infer<typeof DeprecatedVerifyApiArgSchema>;

export const SetReadyForPricingApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerPurchaseVehicleId: z.string(),
});

export type SetReadyForPricingApiArg = z.infer<typeof SetReadyForPricingApiArgSchema>;

export const SetReadyForPricingApiResSchema = z.unknown();
export type SetReadyForPricingApiRes = z.infer<typeof SetReadyForPricingApiResSchema>;

export const GetCustomerRequestedPriceApiArgSchema = z.object({
  offerPurchaseVehicleId: z.string(),
  offerId: z.string(),
  businessCaseId: z.string(),
});

export type GetCustomerRequestedPriceApiArg = z.infer<typeof GetCustomerRequestedPriceApiArgSchema>;

export const CreateBusinessCaseOfferApiArgSchema = z.object({
  businessCaseId: z.string(),
  vehicleToSaleId: z.string().optional(),
  vehicleToPurchaseId: z.string().optional(),
  vehicleToPurchaseBrokerageId: z.string().optional(),
});

export type CreateBusinessCaseOfferApiArg = z.infer<typeof CreateBusinessCaseOfferApiArgSchema>;

export type CreateBusinessCaseOfferApiRes = BusinessCaseResponseBody;

export const GetCustomerRequestedPriceApiResSchema = z.object({
  customerRequestedPrice: z.object({
    priceWithVat: z.object({
      amount: z.string(),
      currency: z.string(),
    }),
    priceWoVat: z.object({
      amount: z.string(),
      currency: z.string(),
    }),
    vatRate: z.string(),
    vatType: z.string(),
  }),
  vatRate: z.object({
    vatType: VatTypeSchema,
    vatRate: z.string(),
    country: z.string(),
  }),
  isVatDeductible: z.boolean(),
});
export type GetCustomerRequestedPriceApiRes = z.infer<typeof GetCustomerRequestedPriceApiResSchema>;

export type PostDepositPaymentApiArg = {
  checkoutId: string;
  orderId: string;
};

export type PostDepositPaymentApiRes = OrderPaymentResponseBody;

export type GetOrderApiArg = {
  orderId: string;
  checkoutId: string;
};

export type CancelOrderApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
};

export type RecordPaymentApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
  recordPayment: {
    payDate: string;
    amount: string;
    currency: string;
    paymentMethod: 'CASH' | 'BANK_TRANSFER' | 'PAYMENT_CARD' | 'CASH_ON_DELIVERY' | 'OFFSET';
    cashRegisterId: string | null;
    createCashRegisterDocument: boolean | null;
    tenantBankAccount: TenantBankAccount | null;
  };
};

export type WithdrawalPaymentApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
  prescriptionId: string;
};

export type WithdrawalPurchasePaymentApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
};

export type OrderDocumentType =
  | 'PURCHASE_CONTRACT'
  | 'PURCHASE_POWER_OF_ATTORNEY'
  | 'PURCHASE_HANDOVER_PROTOCOL'
  | 'SALE_OFFER'
  | 'SALE_CONTRACT'
  | 'SALE_POWER_OF_ATTORNEY'
  | 'SALE_HANDOVER_PROTOCOL'
  | 'SALE_BROKERAGE_CONTRACT'
  | 'PAYMENT_ATTACHMENT'
  | 'PAYMENT_CASH_RECEIPT'
  | 'PAYMENT_CORRECTIVE_CASH_RECEIPT'
  | 'PAYMENT_INVOICE'
  | 'PAYMENT_PROFORMA_INVOICE'
  | 'PAYMENT_TAX_DOCUMENT'
  | 'PURCHASE_BROKERAGE_CONTRACT'
  | 'VEHICLE_CONDITION_PROTOCOL'
  | 'GDPR_MARKETING_CONSENT'
  | 'PURCHASE_BROKERAGE_POWER_OF_ATTORNEY'
  | 'CORRECTIVE_TAX_DOCUMENT'
  | 'OFFSETTING_OF_LIABILITIES_AND_RECEIVABLES'
  | 'INTERNAL_INVOICE_DOCUMENT';

export type CreatePaymentFileApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
  fileData: {
    fileId: string;
    name: string;
    fileUri: string;
    remoteId: string;
    createdAt: string | null;
    createdBy: string | null;
    documentType: OrderDocumentType;
    fileFileId: string;
  };
};

export type DeletePaymentFileApiArg = {
  orderId: string;
  checkoutId: string;
  paymentId: string;
  fileId: string;
};
export type DeletePaymentFileApiRes = unknown;
export type CreatePaymentFileApiRes = OrderPaymentResponseBody;

export type WithdrawalPaymentApiRes = unknown;

export type RecordPaymentApiRes = {
  orderPayment: OrderPaymentResponseBody;
  incomeTransactions: {
    transactionId: string;
    documentId: string;
    checkoutId: string;
    orderId: string;
    paymentId: string;
  }[];
};

export type CancelOrderApiRes = unknown;

export type GetOrderApiRes = OrderResponseBody;
export const PutCustomerRequestedPriceApiArgSchema = z.object({
  offerPurchaseVehicleId: z.string(),
  offerId: z.string(),
  businessCaseId: z.string(),
  customerRequestedPrice: z
    .object({
      priceWithVat: z.object({
        amount: z.string(),
        currency: z.string(),
      }),
      priceWoVat: z.object({
        amount: z.string(),
        currency: z.string(),
      }),
      vatRate: z.string(),
      vatType: z.string(),
    })
    .nullable(),
  vatRate: z.object({
    vatType: VatTypeSchema,
    vatRate: z.string(),
    country: z.string(),
  }),
  isVatDeductible: z.boolean(),
});

export type PutCustomerRequestedPriceApiArg = z.infer<typeof PutCustomerRequestedPriceApiArgSchema>;
export const PutPurchaseVehiclePricingCentralizedApiResSchema = z.unknown();
export type PutPurchaseVehiclePricingCentralizedApiRes = z.infer<
  typeof PutPurchaseVehiclePricingCentralizedApiResSchema
>;

export const CentralizedPriceSchema = z.object({
  priceNotDeductible: z
    .object({
      amount: z.string(),
      currency: z.string(),
    })
    .nullable(),
  vatDirection: z.enum(['FROM_PRICE_WITHOUT_VAT', 'FROM_PRICE_WITH_VAT']).nullable(),
  priceWithoutVat: z
    .object({
      amount: z.string(),
      currency: z.string(),
    })
    .nullable(),
  priceWithVat: z
    .object({
      amount: z.string(),
      currency: z.string(),
    })
    .nullable(),
});

export type CentralizedPrice = z.infer<typeof CentralizedPriceSchema>;
export const PutPurchaseVehiclePricingCentralizedApiArgSchema = z.object({
  offerPurchaseVehicleId: z.string(),
  businessCaseId: z.string(),
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  maxBuyingPrice: CentralizedPriceSchema,
  sellingPrice: CentralizedPriceSchema,
  reason: z.string(),
});
export type PutPurchaseVehiclePricingCentralizedApiArg = z.infer<
  typeof PutPurchaseVehiclePricingCentralizedApiArgSchema
>;

export const PutCustomerRequestedPriceApiResSchema = z.unknown();
export type PutCustomerRequestedPriceApiRes = z.infer<typeof PutCustomerRequestedPriceApiResSchema>;

export type applyCommissionRuleApiArg = {
  businessCaseId: string;
  offerId: string;
  vehicleId: string;
};
export type applyCommissionRuleApiRes = {
  isCommissionRuleApplied: boolean;
};

export const DiscountResponseBodySchema = z.object({
  withoutVat: MoneySchema.nullable(),
  withVat: MoneySchema.nullable(),
  vatRate: BigDecimalSchema.nullable(),
  vatCode: z.string().nullable(),
  percentage: BigDecimalSchema.nullable(),
});
export type DiscountResponseBody = z.infer<typeof DiscountResponseBodySchema>;
export const UpsellResponseBodySchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  purchasePrice: OfferPriceResponseBodySchema.nullable(),
  catalogPrice: OfferPriceResponseBodySchema.nullable(),
  sellingPrice: OfferPriceResponseBodySchema.nullable(),
  discountPrice: DiscountResponseBodySchema.nullable(),
  margin: z.string().nullable(),
  profitPrice: OfferPriceResponseBodySchema.nullable(),
});
export type UpsellResponseBody = z.infer<typeof UpsellResponseBodySchema>;
export const OfferSaleVehicleResponseBodySchema = z.object({
  id: z.string(),
  vehicleId: z.string(),
  purchasePrice: OfferPriceResponseBodySchema.nullable(),
  sellingPrice: OfferPriceResponseBodySchema.nullable(),
  vatDeductible: z.boolean().nullable(),
  profit: OfferPriceResponseBodySchema.nullable(),
  margin: z.string().nullable(),
  discountPrice: OfferPriceResponseBodySchema.nullable(),
  discountPercentage: z.string().nullable(),
  discountReason: z.string().nullable(),
  priceTotalAfterDiscount: OfferPriceResponseBodySchema.nullable(),
  totalUpsellWithVat: MoneySchema.nullish(),
  totalUpsellWithoutVat: MoneySchema.nullish(),
  upsell: z.array(UpsellResponseBodySchema).nullable(),
});
export type OfferSaleVehicleResponseBody = z.infer<typeof OfferSaleVehicleResponseBodySchema>;
export const UpsellRequestBodySchema = z.object({
  name: z.string().nullish(),
  purchasePrice: z.string().nullish(),
  purchasePriceWithVat: z.string().nullish(),
  purchaseVatRate: z.string().nullish(),
  purchaseVatCode: z.string().nullish(),
  catalogPrice: z.string().nullish(),
  catalogPriceWithVat: z.string().nullish(),
  catalogVatRate: z.string().nullish(),
  catalogVatCode: z.string().nullish(),
  sellingPrice: z.string().nullish(),
  sellingPriceWithVat: z.string().nullish(),
  sellingVatRate: z.string().nullish(),
  sellingVatCode: z.string().nullish(),
  discountPercentage: z.string().nullish(),
  discountPrice: z.string().nullish(),
  discountPriceWithVat: z.string().nullish(),
  discountVatRate: z.string().nullish(),
  discountVatCode: z.string().nullish(),
});

export type UpsellRequestBody = z.infer<typeof UpsellRequestBodySchema>;
export const PurchaseVehicleTypeSchema = z.enum(['IN_STOCK', 'BROKERAGE']);
export type PurchaseVehicleType = z.infer<typeof PurchaseVehicleTypeSchema>;
export const CostsOfferPurchaseVehicleResponseBodySchema = z.object({
  confirmed: z.boolean(),
  customerRequestedPrice: MoneySchema.nullable(),
  coster: z.string().nullable(),
  costsOn: z.string().nullable(),
});
export type CostsOfferPurchaseVehicleResponseBody = z.infer<
  typeof CostsOfferPurchaseVehicleResponseBodySchema
>;
export const DeductibleOrNotDeductiblePriceSchema = z.object({
  priceNotDeductible: MoneySchema.nullable(),
  vatDirection: PriceWithVatDirectionSchema.nullable(),
  priceWithoutVat: MoneySchema.nullable(),
  priceWithVat: MoneySchema.nullable(),
});
export type DeductibleOrNotDeductiblePrice = z.infer<typeof DeductibleOrNotDeductiblePriceSchema>;
export const PricingOfferPurchaseVehicleResponseBodySchema = z.object({
  confirmed: z.boolean(),
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  maxBuyingPrice: DeductibleOrNotDeductiblePriceSchema,
  sellingPrice: DeductibleOrNotDeductiblePriceSchema,
  reason: z.string().nullable(),
  pricer: z.string().nullable(),
  pricedOn: z.string().nullable(),
});
export type PricingOfferPurchaseVehicleResponseBody = z.infer<
  typeof PricingOfferPurchaseVehicleResponseBodySchema
>;
export const BuyingOfferPurchaseVehicleResponseBodySchema = z.object({
  confirmed: z.boolean(),
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  buyingPrice: DeductibleOrNotDeductiblePriceSchema,
  reason: z.string().nullable(),
  buyer: z.string().nullable(),
  pricedOn: z.string().nullable(),
  maxBuyingPrice: MoneySchema.nullish(),
});
export type BuyingOfferPurchaseVehicleResponseBody = z.infer<
  typeof BuyingOfferPurchaseVehicleResponseBodySchema
>;
export const NewOfferPurchaseVehicleResponseBodySchema = z.object({
  id: z.string(),
  vehicleId: z.string(),
  purchaseVehicleType: PurchaseVehicleTypeSchema,
  costs: CostsOfferPurchaseVehicleResponseBodySchema,
  pricing: PricingOfferPurchaseVehicleResponseBodySchema,
  buying: BuyingOfferPurchaseVehicleResponseBodySchema,
  margin: z.string().nullable(),
  profit: OfferPriceResponseBodySchema.nullable(),
  expectedPurchaseOn: z.string().nullable(),
  isPricingConfirmedSimplified: z.boolean(),
});
export type NewOfferPurchaseVehicleResponseBody = z.infer<
  typeof NewOfferPurchaseVehicleResponseBodySchema
>;
export const CostsOfferPurchaseBrokerageVehicleResponseBodySchema = z.object({
  confirmed: z.boolean(),
  customerRequestedPrice: MoneySchema.nullable(),
  coster: z.string().nullable(),
  costsOn: z.string().nullable(),
});
export type CostsOfferPurchaseBrokerageVehicleResponseBody = z.infer<
  typeof CostsOfferPurchaseBrokerageVehicleResponseBodySchema
>;
export const PricingOfferPurchaseBrokerageVehicleResponseBodySchema = z.object({
  confirmed: z.boolean(),
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  maxBuyingPrice: DeductibleOrNotDeductiblePriceSchema,
  sellingPrice: DeductibleOrNotDeductiblePriceSchema,
  reason: z.string().nullable(),
  pricer: z.string().nullable(),
  pricedOn: z.string().nullable(),
});
export type PricingOfferPurchaseBrokerageVehicleResponseBody = z.infer<
  typeof PricingOfferPurchaseBrokerageVehicleResponseBodySchema
>;
export const NewOfferPurchaseBrokerageVehicleResponseBodySchema = z.object({
  id: z.string(),
  vehicleId: z.string(),
  costs: CostsOfferPurchaseBrokerageVehicleResponseBodySchema,
  pricing: PricingOfferPurchaseBrokerageVehicleResponseBodySchema,
  brokerageFees: CalculatedBrokerageFeesResponseBodySchema.nullable(),
  expectedPurchaseOn: z.string().nullable(),
});
export type NewOfferPurchaseBrokerageVehicleResponseBody = z.infer<
  typeof NewOfferPurchaseBrokerageVehicleResponseBodySchema
>;
export const OfferUserResponseBodySchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});
export type OfferUserResponseBody = z.infer<typeof OfferUserResponseBodySchema>;
export const OfferResponseBodySchema = z.object({
  id: z.string(),
  saleVehicles: z.array(OfferSaleVehicleResponseBodySchema).nullish(),
  purchaseVehicles: z.array(NewOfferPurchaseVehicleResponseBodySchema).nullish(),
  purchaseBrokerageVehicles: z.array(NewOfferPurchaseBrokerageVehicleResponseBodySchema).nullish(),
  offerTotalPrice: OfferPriceResponseBodySchema.nullish(),
  totalMarginPrice: OfferPriceResponseBodySchema.nullish(),
  totalMarginPercentage: z.string().nullish(),
  totalDiscountPrice: OfferPriceResponseBodySchema.nullish(),
  totalDiscountPercentage: z.string().nullish(),
  totalUpsell: OfferPriceResponseBodySchema.nullish(),
  createdAt: z.string(),
  createdBy: OfferUserResponseBodySchema,
  updatedAt: z.string().nullish(),
  updateBy: OfferUserResponseBodySchema.nullish(),
  files: z.array(FileDataSchema),
  documentsIsActual: z.boolean().optional(),
  expectedCosts: OfferPriceResponseBodySchema.nullish(),
  otherExpectedCosts: OfferPriceResponseBodySchema.nullish(),
});
export type OfferResponseBody = z.infer<typeof OfferResponseBodySchema>;
export type GetBusinessCaseSettingsApiResponse =
  /** status 200  */ BusinessCaseSettingsResponseBody;
export const PutBusinessCaseSettingsApiResponseSchema = z.undefined();
export type PutBusinessCaseSettingsApiResponse = z.infer<
  typeof PutBusinessCaseSettingsApiResponseSchema
>;
export const BusinessCaseSettingsRequestBodySchema = z.object({
  maximalPurchasePriceRequired: z.boolean(),
});

export type BusinessCaseSettingsRequestBody = z.infer<typeof BusinessCaseSettingsRequestBodySchema>;
export const PutBusinessCaseSettingsApiArgSchema = z.object({
  businessCaseSettingsRequestBody: BusinessCaseSettingsRequestBodySchema,
});
export type PutBusinessCaseSettingsApiArg = z.infer<typeof PutBusinessCaseSettingsApiArgSchema>;
export const BusinessCaseSettingsResponseBodySchema = z.object({
  maximalPurchasePriceRequired: z.boolean(),
});

export type BusinessCaseSettingsResponseBody = z.infer<
  typeof BusinessCaseSettingsResponseBodySchema
>;
export const LinkedBusinessCaseResponseBodySchema = z.object({
  id: z.string(),
  code: z.string(),
  createdById: z.string().nullable(),
  createdByFirstName: z.string().nullable(),
  createdByLastName: z.string().nullable(),
  createdAt: z.string(),
  customerName: z.string().nullable(),
  customerId: z.string().nullable(),
  businessCaseType: z.string().nullable(),
  businessCaseState: z.string().nullable(),
});

export type LinkedBusinessCaseResponseBody = z.infer<typeof LinkedBusinessCaseResponseBodySchema>;
export const BusinessCaseStateSchema = z.enum([
  'CONCEPT',
  'OFFER',
  'CONTRACT',
  'CLOSED',
  'UNSUCCESSFUL',
  'DEPOSIT',
  'DEPOSIT_PAID',
  'INVOICE',
  'PAID',
]);

export type BusinessCaseState = z.infer<typeof BusinessCaseStateSchema>;
export const BusinessCaseTypeSchema = z.enum(['SELLING', 'BUYING', 'SWAP', 'PURCHASE_BROKERAGE']);
export type BusinessCaseType = z.infer<typeof BusinessCaseTypeSchema>;
const BusinessCaseActionsResponseBodySchema = z.object({
  customerChangeExisting: z.boolean(),
  customerEditExisting: z.boolean(),
  vehicleAssign: z.boolean(),
  saleViewVehicleDetail: z.boolean(),
  saleEditVehiclePrices: z.boolean(),
  upsellAddNew: z.boolean(),
  upsellOpenDetail: z.boolean(),
  upsellEdit: z.boolean(),
  upsellRemove: z.boolean(),
  purchaseViewVehicleDetail: z.boolean(),
  purchaseEditVehicle: z.boolean(),
  checkoutViewDetail: z.boolean(),
  checkoutAddBillingInformation: z.boolean(),
  checkoutEditPaymentDetails: z.boolean(),
  paymentConfirmation: z.boolean(),
  checkoutDownloadDocuments: z.boolean(),
  businessCaseGenerateDocuments: z.boolean(),
  delete: z.boolean(),
  end: z.boolean(),
  closeContract: z.boolean(),
  close: z.boolean(),
  changePurchaseTradeType: z.boolean(),
  reopen: z.boolean(),
  backToOffer: z.boolean(),
  switchSaleVehicle: z.boolean(),
  deleteSaleVehicle: z.boolean(),
  deletePurchaseVehicle: z.boolean(),
  deletePurchaseBrokerageVehicle: z.boolean(),
  endPurchaseBrokerage: z.boolean(),
  checkoutSplitOrder: z.boolean(),
  isPurchaseBusinessCaseOpen: z.boolean(),
});
export type BusinessCaseActionsResponseBody = z.infer<typeof BusinessCaseActionsResponseBodySchema>;
export const BusinessCaseResponseBodySchema = z.object({
  id: z.string(),
  branchId: z.string(),
  code: z.string(),
  customCode: z.string().nullable(),
  country: z.string(),
  customerId: z.string(),
  contactPersonId: z.string().nullable(),
  offers: z.array(OfferResponseBodySchema),
  saleVehicleId: z.string().nullish(),
  createdAt: z.string(),
  createdBy: UserResponseBodySchema,
  updatedAt: z.string().nullable(),
  updatedBy: UserResponseBodySchema.nullable(),
  closedAt: z.string().nullable(),
  closedBy: UserResponseBodySchema.nullable(),
  brokerageBusinessCaseId: z.string().nullable(),
  brokerageBusinessCase: LinkedBusinessCaseResponseBodySchema.nullable(),
  checkoutId: z.string().nullable(),
  reasonCloseCodeId: z.string().nullish(),
  reasonCloseSystem: z.string().nullish(),
  reasonCloseNote: z.string().nullish(),
  businessCaseState: BusinessCaseStateSchema,
  businessCaseType: BusinessCaseTypeSchema.nullable(),
  businessCaseInternalType: BusinessCaseInternalTypeSchema.nullable(),
  actions: BusinessCaseActionsResponseBodySchema,
  canBeEdited: z.boolean().optional(),
  canBeEnded: z.boolean().optional(),
  participationId: z.string(),
  insuranceOffered: z.boolean(),
  customerRecommended: z.boolean(),
  interestId: z.string().nullish(),
});
export type BusinessCaseResponseBody = z.infer<typeof BusinessCaseResponseBodySchema>;
export const ChangeBranchBusinessCaseApiResponseSchema = z.unknown();
export type ChangeBranchBusinessCaseApiResponse = z.infer<
  typeof ChangeBranchBusinessCaseApiResponseSchema
>;
export const ChangeBranchBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  changeBranchBusinessCaseRequestBody: ChangeBranchBusinessCaseRequestBodySchema,
});
export type ChangeBranchBusinessCaseApiArg = {
  businessCaseId: string;
  changeBranchBusinessCaseRequestBody: ChangeBranchBusinessCaseRequestBody;
};
export type CloseBusinessCaseApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const CloseBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type CloseBusinessCaseApiArg = z.infer<typeof CloseBusinessCaseApiArgSchema>;

export type AddUpsellFromCatalogApiResponse = /** status 201  */ OfferResponseBody;
export const AddUpsellFromCatalogRequestBodySchema = z.object({
  productId: z.string(),
});
export type AddUpsellFromCatalogRequestBody = z.infer<typeof AddUpsellFromCatalogRequestBodySchema>;
export const AddUpsellFromCatalogApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  body: AddUpsellFromCatalogRequestBodySchema,
});
export type AddUpsellFromCatalogApiArg = z.infer<typeof AddUpsellFromCatalogApiArgSchema>;

const CloseBusinessCaseRequestBodySchema = z.object({
  reasonCloseNote: z.string().nullish(),
  reasonCloseCodeId: z.string().nullish(),
});

export type CloseBusinessCaseRequestBody = z.infer<typeof CloseBusinessCaseRequestBodySchema>;

export type EndBusinessCaseApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const EndBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  closeBusinessCaseRequestBody: CloseBusinessCaseRequestBodySchema,
});
export type EndBusinessCaseApiArg = z.infer<typeof EndBusinessCaseApiArgSchema>;

export type EndPurchaseBrokerageBusinessCaseApiResponse =
  /** status 200  */ BusinessCaseResponseBody;
export const EndPurchaseBrokerageBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  closeBusinessCaseRequestBody: CloseBusinessCaseRequestBodySchema,
});
export type EndPurchaseBrokerageBusinessCaseApiArg = z.infer<
  typeof EndPurchaseBrokerageBusinessCaseApiArgSchema
>;
export type EditPricingSimplifiedRequestBody = {
  vatDeductible: boolean;
  vatType: VatType | null;
  sellingPrice: DeductibleOrNotDeductiblePrice;
  reason: string | null;
  buyingPrice: DeductibleOrNotDeductiblePrice;
  customerRequestedPrice: Money | null;
  expectedPurchaseOn?: string | null;
  maxBuyingPrice?: DeductibleOrNotDeductiblePrice | null;
};
export const BusinessCaseStatisticsResponseBodySchema = z.object({
  open: z.number(),
  offer: z.number(),
  contract: z.number(),
  unsuccessful: z.number(),
  successful: z.number(),
});

export type BusinessCaseStatisticsResponseBody = z.infer<
  typeof BusinessCaseStatisticsResponseBodySchema
>;
export type GetBusinessCaseStatisticsApiResponse =
  /** status 200  */ BusinessCaseStatisticsResponseBody;
export const GetBusinessCaseStatisticsApiArgSchema = z.object({
  branchId: z.string().nullish(),
});
export type GetBusinessCaseStatisticsApiArg = z.infer<typeof GetBusinessCaseStatisticsApiArgSchema>;
export type CreateUpsellApiResponse = /** status 201  */ OfferResponseBody;
export const CreateUpsellApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  body: UpsellRequestBodySchema,
});
export type CreateUpsellApiArg = z.infer<typeof CreateUpsellApiArgSchema>;
export type DeleteUpsellApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const DeleteUpsellApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  upsellId: z.string(),
});

export type DeleteUpsellApiArg = z.infer<typeof DeleteUpsellApiArgSchema>;
export type PatchUpsellApiResponse = /** status 200  */ OfferResponseBody;
export const PatchUpsellApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  upsellId: z.string(),
  body: UpsellRequestBodySchema,
});
export type PatchUpsellApiArg = z.infer<typeof PatchUpsellApiArgSchema>;
export type FinishPricesUpsellApiResponse = /** status 201  */ UpsellResponseBody;
export const FinishPricesUpsellApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  body: UpsellRequestBodySchema,
});
export type FinishPricesUpsellApiArg = z.infer<typeof FinishPricesUpsellApiArgSchema>;
export const CreateBusinessCaseRequestBodySchema = z.object({
  customerId: z.string(),
  vehicleToSaleId: z.string().nullish(),
  vehicleToPurchaseId: z.string().nullish(),
  vehicleToPurchaseBrokerageId: z.string().nullish(),
  createdAt: z.string().nullish(),
});

export type CreateBusinessCaseRequestBody = z.infer<typeof CreateBusinessCaseRequestBodySchema>;
export type CreateBusinessCaseApiResponse = /** status 201  */ BusinessCaseResponseBody;
export const CreateBusinessCaseApiArgSchema = z.object({
  createBusinessCaseRequestBody: CreateBusinessCaseRequestBodySchema,
});
export type CreateBusinessCaseApiArg = z.infer<typeof CreateBusinessCaseApiArgSchema>;
export type GetBusinessCaseApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const GetBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetBusinessCaseApiArg = z.infer<typeof GetBusinessCaseApiArgSchema>;
export type PatchBusinessCaseCustomCodeApiResponse = /** status 201  */ BusinessCaseResponseBody;
export const PatchBusinessCaseCustomCodeRequestBodySchema = z.object({
  customCode: z.string().nullable(),
});
export type PatchBusinessCaseCustomCodeRequestBody = z.infer<
  typeof PatchBusinessCaseCustomCodeRequestBodySchema
>;
export const PatchBusinessCaseCustomCodeApiArgSchema = z.object({
  businessCaseId: z.string(),
  patchBusinessCaseCustomCodeRequestBody: PatchBusinessCaseCustomCodeRequestBodySchema,
});
export type PatchBusinessCaseCustomCodeApiArg = z.infer<
  typeof PatchBusinessCaseCustomCodeApiArgSchema
>;
export type GetBusinessCaseCheckoutApiResponse = /** status 200  */ CheckoutResponseBody;
export const GetBusinessCaseCheckoutApiArgSchema = z.object({
  businessCaseId: z.string(),
});

export type GetBusinessCaseCheckoutApiArg = z.infer<typeof GetBusinessCaseCheckoutApiArgSchema>;
export type DeleteOfferSaleVehicleApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const DeleteOfferSaleVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
});

export type DeleteOfferSaleVehicleApiArg = z.infer<typeof DeleteOfferSaleVehicleApiArgSchema>;
export const DeleteOfferPurchaseBrokerageVehicleApiResponseSchema = z.unknown();
export type DeleteOfferPurchaseBrokerageVehicleApiResponse = z.infer<
  typeof DeleteOfferPurchaseBrokerageVehicleApiResponseSchema
>;
export const DeleteOfferPurchaseBrokerageVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseBrokerageVehicleId: z.string(),
});
export type DeleteOfferPurchaseBrokerageVehicleApiArg = z.infer<
  typeof DeleteOfferPurchaseBrokerageVehicleApiArgSchema
>;
export const DeleteOfferPurchaseVehicleApiResponseSchema = z.unknown();
export type DeleteOfferPurchaseVehicleApiResponse = z.infer<
  typeof DeleteOfferPurchaseVehicleApiResponseSchema
>;
export const DeleteOfferPurchaseVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseVehicleId: z.string(),
});

export type DeleteOfferPurchaseVehicleApiArg = z.infer<
  typeof DeleteOfferPurchaseVehicleApiArgSchema
>;
export type CreateOfferPurchaseVehicleApiResponse = OfferResponseBody;
export const CreateOfferPurchaseVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  vehicleId: z.string(),
});

export type CreateOfferPurchaseVehicleApiArg = z.infer<
  typeof CreateOfferPurchaseVehicleApiArgSchema
>;
export type PatchSaleVehicleApiResponse = /** status 200  */ OfferResponseBody;
const OfferSaleVehiclePatchRequestBodySchema = z.object({
  discountPrice: z.string().nullable(),
  discountPercentage: z.string().nullable(),
  discountReason: z.string().nullable(),
});

export type OfferSaleVehiclePatchRequestBody = z.infer<
  typeof OfferSaleVehiclePatchRequestBodySchema
>;
export const PatchBuyingOfferPurchaseVehicleRequestBodySchema = z.object({
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  buyingPrice: DeductibleOrNotDeductiblePriceSchema,
  reason: z.string().nullable(),
  expectedPurchaseOn: z.string().nullish(),
});
export type PatchBuyingOfferPurchaseVehicleRequestBody = z.infer<
  typeof PatchBuyingOfferPurchaseVehicleRequestBodySchema
>;
export type PatchBuyingOfferPurchaseVehicleApiResponse = /** status 200  */ OfferResponseBody;
export const PatchBuyingOfferPurchaseVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseVehicleId: z.string(),
  patchBuyingOfferPurchaseVehicleRequestBody: PatchBuyingOfferPurchaseVehicleRequestBodySchema,
});
export type PatchBuyingOfferPurchaseVehicleApiArg = z.infer<
  typeof PatchBuyingOfferPurchaseVehicleApiArgSchema
>;
export const PatchSaleVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleId: z.string(),
  offerSaleVehiclePatchRequestBody: OfferSaleVehiclePatchRequestBodySchema,
});
export type PatchSaleVehicleApiArg = z.infer<typeof PatchSaleVehicleApiArgSchema>;
export type CreateSaleVehicleApiResponse = /** status 201  */ OfferResponseBody;
export const OfferSaleVehicleRequestBodySchema = z.object({
  vehicleId: z.string(),
});
export type OfferSaleVehicleRequestBody = z.infer<typeof OfferSaleVehicleRequestBodySchema>;
export const CreateSaleVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerSaleVehicleRequestBody: OfferSaleVehicleRequestBodySchema,
});
export type CreateSaleVehicleApiArg = z.infer<typeof CreateSaleVehicleApiArgSchema>;
export type PatchFeesOfferPurchaseBrokerageVehicleApiResponse =
  /** status 200  */ OfferResponseBody;
export const BrokerageFeesPriceRequestBodySchema = z.object({
  price: MoneySchema,
  isWithVat: z.boolean(),
  vatType: VatTypeSchema,
});
export type BrokerageFeesPriceRequestBody = z.infer<typeof BrokerageFeesPriceRequestBodySchema>;
export const InvoicedBrokerageServicesRequestBodySchema = z.object({
  fee: BrokerageFeesPriceRequestBodySchema,
  isEnabled: z.boolean(),
});
export type InvoicedBrokerageServicesRequestBody = z.infer<
  typeof InvoicedBrokerageServicesRequestBodySchema
>;
export const BrokerageServicesRequestBodySchema = z.object({
  cost: BrokerageFeesPriceRequestBodySchema.nullable(),
  invoiced: InvoicedBrokerageServicesRequestBodySchema.nullable(),
});
export type BrokerageServicesRequestBody = z.infer<typeof BrokerageServicesRequestBodySchema>;
export const SalesCommissionRequestBodySchema = z.object({
  fixed: BrokerageFeesPriceRequestBodySchema.nullable(),
  percentage: BigDecimalSchema,
  isEnabled: z.boolean(),
  minimal: BrokerageFeesPriceRequestBodySchema.nullable(),
  isWithVat: z.boolean(),
  isVatDeductible: z.boolean().optional(),
});
export type SalesCommissionRequestBody = z.infer<typeof SalesCommissionRequestBodySchema>;
export const BrokerageFeesRequestBodySchema = z.object({
  brokerageServices: BrokerageServicesRequestBodySchema.nullable(),
  salesCommission: SalesCommissionRequestBodySchema.nullable(),
  country: CountryAlpha3CodeEnumSchema,
  vehicleSellingPrice: MoneySchema,
  date: z.string(),
  contractLength: z.number().nullish(),
});
export type BrokerageFeesRequestBody = z.infer<typeof BrokerageFeesRequestBodySchema>;
export const PatchFeesOfferPurchaseBrokerageVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseBrokerageVehicleId: z.string(),
  body: BrokerageFeesRequestBodySchema,
});
export type PatchFeesOfferPurchaseBrokerageVehicleApiArg = z.infer<
  typeof PatchFeesOfferPurchaseBrokerageVehicleApiArgSchema
>;
export type CalculateBrokerageFeesApiResponse =
  /** status 200  */ CalculatedBrokerageFeesResponseBody;
export const CalculateBrokerageFeesApiArgSchema = z.object({
  body: BrokerageFeesRequestBodySchema,
});
export type CalculateBrokerageFeesApiArg = z.infer<typeof CalculateBrokerageFeesApiArgSchema>;
export const AgreementOnFutureRequestBodySchema = z.object({
  templateId: z.string(),
  businessCaseId: z.string(),
  note: z.string().nullable(),
});

export type AgreementOnFutureRequestBody = z.infer<typeof AgreementOnFutureRequestBodySchema>;
export const CreateAgreementOnFutureContractApiArgSchema = z.object({
  agreementOnFutureRequestBody: AgreementOnFutureRequestBodySchema,
});
export type CreateAgreementOnFutureContractApiArg = z.infer<
  typeof CreateAgreementOnFutureContractApiArgSchema
>;
export const PatchPricingOfferPurchaseVehicleRequestBodySchema = z.object({
  vatDeductible: z.boolean(),
  vatType: VatTypeSchema.nullable(),
  maxBuyingPrice: DeductibleOrNotDeductiblePriceSchema,
  sellingPrice: DeductibleOrNotDeductiblePriceSchema,
  reason: z.string().nullable(),
  customerRequestedPrice: MoneySchema.nullable(),
});
export type PatchPricingOfferPurchaseVehicleRequestBody = z.infer<
  typeof PatchPricingOfferPurchaseVehicleRequestBodySchema
>;
export type PatchPricingOfferPurchaseBrokerageVehicleRequestBody = {
  vatDeductible: boolean;
  vatType: VatType | null;
  maxBuyingPrice: DeductibleOrNotDeductiblePrice;
  sellingPrice: DeductibleOrNotDeductiblePrice;
  reason: string | null;
  customerRequestedPrice: Money | null;
  expectedPurchaseOn?: string | null;
};
export type PatchPricingOfferPurchaseVehicleApiResponse = /** status 200  */ OfferResponseBody;
export const PatchPricingOfferPurchaseVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseVehicleId: z.string(),
  patchPricingOfferPurchaseVehicleRequestBody: PatchPricingOfferPurchaseVehicleRequestBodySchema,
});
export type PatchPricingOfferPurchaseVehicleApiArg = z.infer<
  typeof PatchPricingOfferPurchaseVehicleApiArgSchema
>;
export type PatchPricingOfferPurchaseBrokerageVehicleApiResponse =
  /** status 200  */ OfferResponseBody;
export type PatchPricingOfferPurchaseBrokerageVehicleApiArg = {
  businessCaseId: string;
  offerId: string;
  offerPurchaseBrokerageVehicleId: string;
  patchPricingOfferPurchaseBrokerageVehicleRequestBody: PatchPricingOfferPurchaseBrokerageVehicleRequestBody;
};

export const OfferBusinessCaseInsuranceApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type OfferBusinessCaseInsuranceApiArg = z.infer<
  typeof OfferBusinessCaseInsuranceApiArgSchema
>;

export const RecommendBusinessCaseCustomerApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type RecommendBusinessCaseCustomerApiArg = z.infer<
  typeof RecommendBusinessCaseCustomerApiArgSchema
>;

export const BusinessCaseCountResponseBodySchema = z.object({
  count: z.number(),
});
export type BusinessCaseCountResponseBody = z.infer<typeof BusinessCaseCountResponseBodySchema>;

export type GetBusinessCasesByVehicleCountApiResponse =
  /** status 200  */ BusinessCaseCountResponseBody;
export const GetBusinessCasesByVehicleCountApiArgSchema = z.object({
  vehicleId: z.string(),
});
export type GetBusinessCasesByVehicleCountApiArg = z.infer<
  typeof GetBusinessCasesByVehicleCountApiArgSchema
>;

export const GetLastActiveBuyingBusinessCaseApiArgSchema = z.object({vehicleId: z.string()});
export type GetLastActiveBuyingBusinessCaseApiArg = z.infer<
  typeof GetLastActiveBuyingBusinessCaseApiArgSchema
>;
export type GetLastActiveBuyingBusinessCaseApiRes = LastActiveBusinessCaseResponseBody;

export const GetLastActiveSellingBusinessCaseApiArgSchema = z.object({vehicleId: z.string()});
export type GetLastActiveSellingBusinessCaseApiArg = z.infer<
  typeof GetLastActiveSellingBusinessCaseApiArgSchema
>;
export type GetLastActiveSellingBusinessCaseApiRes = LastActiveBusinessCaseResponseBody;
export type GetBusinessCaseActionsApiResponse =
  /** status 200  */ BusinessCaseActionsV2ResponseBody;
export const GetBusinessCaseActionsApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetBusinessCaseActionsApiArg = z.infer<typeof GetBusinessCaseActionsApiArgSchema>;
export const BusinessCaseActionSchema = z.enum([
  'CHANGE_BRANCH',
  'CHANGE_CUSTOM_CODE',
  'CHANGE_CUSTOMER',
  'SELECT_CUSTOMER_CONTACT_PERSON',
  'ADD_VEHICLE_TO_SELL',
  'ADD_VEHICLE_TO_BUY',
  'DELETE',
  'SWITCH_OFFER_SALE_VEHICLE',
  'DELETE_OFFER_SALE_VEHICLE',
  'GENERATE_SALE_OFFER_DOCUMENT',
  'ADD_UPSELL_ITEM_FROM_CATALOGUE',
  'CREATE_UPSELL_ITEM',
  'EDIT_UPSELL_ITEM',
  'REMOVE_UPSELL_ITEM',
  'CLOSE_CONTRACT',
  'BACK_TO_OFFER',
  'CLOSE',
  'REOPEN',
  'END',
  'END_PURCHASE_BROKERAGE',
  'CHANGE_TO_PURCHASE_VEHICLE_TO_BROKERAGE',
  'CHANGE_TO_STOCK_PURCHASE_BROKERAGE_VEHICLE',
]);

export type BusinessCaseAction = z.infer<typeof BusinessCaseActionSchema>;
export const BusinessCaseActionsV2ResponseBodySchema = z.object({
  actions: z.array(BusinessCaseActionSchema),
});
export type BusinessCaseActionsV2ResponseBody = z.infer<
  typeof BusinessCaseActionsV2ResponseBodySchema
>;
export type PatchBusinessCaseApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const PatchBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  patchBusinessCaseRequestBody: z.object({
    customerId: z.string().nullable(),
  }),
});

export type PatchBusinessCaseApiArg = z.infer<typeof PatchBusinessCaseApiArgSchema>;
export type PutBusinessCaseContactPersonApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const PutBusinessCaseContactPersonApiArgSchema = z.object({
  businessCaseId: z.string(),
  putBusinessCaseContactPersonRequestBody: z.object({
    contactId: z.string().nullish(),
  }),
});

export type PutBusinessCaseContactPersonApiArg = z.infer<
  typeof PutBusinessCaseContactPersonApiArgSchema
>;
export const TakeoverBusinessCaseApiResponseSchema = z.unknown();
export type TakeoverBusinessCaseApiResponse = z.infer<typeof TakeoverBusinessCaseApiResponseSchema>;
export const TakeoverBusinessCaseRequestBodySchema = z.object({
  branchId: z.string(),
});

export type TakeoverBusinessCaseRequestBody = z.infer<typeof TakeoverBusinessCaseRequestBodySchema>;
export const TakeoverBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  takeoverBusinessCaseRequestBody: TakeoverBusinessCaseRequestBodySchema,
});
export type TakeoverBusinessCaseApiArg = z.infer<typeof TakeoverBusinessCaseApiArgSchema>;
export const DeleteBusinessCaseApiResponseSchema = z.void();
export type DeleteBusinessCaseApiResponse = z.infer<typeof DeleteBusinessCaseApiResponseSchema>;
export const DeleteBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
});

export type DeleteBusinessCaseApiArg = z.infer<typeof DeleteBusinessCaseApiArgSchema>;
export const GetPurchaseOfferTemplatesApiResponseSchema = z.array(
  TemplateListItemResponseBodySchema
);
export type GetPurchaseOfferTemplatesApiResponse = z.infer<
  typeof GetPurchaseOfferTemplatesApiResponseSchema
>;
export const GetPurchaseOfferTemplatesApiArgSchema = z.void();
export type GetPurchaseOfferTemplatesApiArg = z.infer<typeof GetPurchaseOfferTemplatesApiArgSchema>;
export type CreatePurchaseOfferDocumentApiResponse = /** status 200  */ BusinessCaseResponseBody;
export const PurchaseOfferRequestBodySchema = z.object({
  templateId: z.string(),
  businessCaseId: z.string(),
});
export type PurchaseOfferRequestBody = z.infer<typeof PurchaseOfferRequestBodySchema>;
export const CreatePurchaseOfferDocumentApiArgSchema = z.object({
  purchaseOfferRequestBody: PurchaseOfferRequestBodySchema,
});
export type CreatePurchaseOfferDocumentApiArg = z.infer<
  typeof CreatePurchaseOfferDocumentApiArgSchema
>;
export const PricePurchaseBusinessCaseApiResponseSchema = z.unknown();
export type PricePurchaseBusinessCaseApiResponse = z.infer<
  typeof PricePurchaseBusinessCaseApiResponseSchema
>;
export const BusinessCasePriceRequestBodySchema = z.object({
  priceNotDeductible: MoneySchema.nullable(),
  vatDirection: PriceWithVatDirectionSchema.nullable(),
  priceWithoutVat: MoneySchema.nullable(),
  priceWithVat: MoneySchema.nullable(),
  vatType: VatTypeSchema.nullable(),
});
export type BusinessCasePriceRequestBody = z.infer<typeof BusinessCasePriceRequestBodySchema>;
export const BusinessCasePriceInForeignCurrencyRequestBodySchema = z.object({
  foreignCurrencyCode: z.string(),
  priceWithoutVat: z.string().nullable(),
  priceWithVat: z.string().nullable(),
});

export type BusinessCasePriceInForeignCurrencyRequestBody = z.infer<
  typeof BusinessCasePriceInForeignCurrencyRequestBodySchema
>;
export const PricePurchaseBusinessCaseRequestBodySchema = z.object({
  isVatDeductible: z.boolean().optional(),
  expectedPurchasePrice: BusinessCasePriceRequestBodySchema.optional(),
  expectedSellingPrice: BusinessCasePriceRequestBodySchema.optional(),
  maxPurchasePrice: MoneySchema.nullish(),
  updateReasonNote: z.string().nullish(),
  customerExpectedPrice: MoneySchema.nullish(),
  expectedPurchaseDate: z.string().nullish(),
  purchasePriceInForeignCurrency: BusinessCasePriceInForeignCurrencyRequestBodySchema.nullish(),
});
export type PricePurchaseBusinessCaseRequestBody = z.infer<
  typeof PricePurchaseBusinessCaseRequestBodySchema
>;
export const PricePurchaseBusinessCaseApiArgSchema = z.object({
  businessCaseId: z.string(),
  pricePurchaseBusinessCaseRequestBody: PricePurchaseBusinessCaseRequestBodySchema,
});
export type PricePurchaseBusinessCaseApiArg = z.infer<typeof PricePurchaseBusinessCaseApiArgSchema>;
export type ChangeToPurchaseBrokeragePurchaseVehicleApiResponse =
  /** status 200  */ BusinessCaseResponseBody;
export const ChangeToPurchaseBrokeragePurchaseVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  offerPurchaseVehicleId: z.string(),
});
export type ChangeToPurchaseBrokeragePurchaseVehicleApiArg = z.infer<
  typeof ChangeToPurchaseBrokeragePurchaseVehicleApiArgSchema
>;
export const MarkOfferDocumentsAsActualApiResponseSchema = z.unknown();
export type MarkOfferDocumentsAsActualApiResponse = z.infer<
  typeof MarkOfferDocumentsAsActualApiResponseSchema
>;
export const MarkOfferDocumentsAsActualApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
});
export type MarkOfferDocumentsAsActualApiArg = z.infer<
  typeof MarkOfferDocumentsAsActualApiArgSchema
>;
export type SelectBusinessCaseCheckoutOrderContractInformationApiResponse =
  /** status 200  */ OrderResponseBody;
export const SelectContractInformationRequestBodySchema = z.object({
  customerContractInformationId: z.string(),
  selectedIdentityCardIds: z.array(z.string()),
});

export type SelectContractInformationRequestBody = z.infer<
  typeof SelectContractInformationRequestBodySchema
>;
export const SelectBusinessCaseCheckoutOrderContractInformationApiArgSchema = z.object({
  businessCaseId: z.string(),
  orderId: z.string(),
  selectContractInformationRequestBody: SelectContractInformationRequestBodySchema,
});

export type SelectBusinessCaseCheckoutOrderContractInformationApiArg = z.infer<
  typeof SelectBusinessCaseCheckoutOrderContractInformationApiArgSchema
>;
export type SelectBusinessCaseCheckoutOrderDeputyPersonsApiResponse =
  /** status 200  */ OrderResponseBody;
export const SelectDeputyPersonRequestBodySchema = z.object({
  customerPersonId: z.string(),
  selectedIdentityCardIds: z.array(z.string()),
});
export type SelectDeputyPersonRequestBody = z.infer<typeof SelectDeputyPersonRequestBodySchema>;
export const SelectDeputyPersonsRequestBodySchema = z.object({
  deputyPersons: z.array(SelectDeputyPersonRequestBodySchema),
});
export type SelectDeputyPersonsRequestBody = z.infer<typeof SelectDeputyPersonsRequestBodySchema>;
export const SelectBusinessCaseCheckoutOrderDeputyPersonsApiArgSchema = z.object({
  businessCaseId: z.string(),
  orderId: z.string(),
  selectDeputyPersonsRequestBody: SelectDeputyPersonsRequestBodySchema,
});
export type SelectBusinessCaseCheckoutOrderDeputyPersonsApiArg = z.infer<
  typeof SelectBusinessCaseCheckoutOrderDeputyPersonsApiArgSchema
>;
export const OfferRequestBodySchema = z.object({
  vehicleToSaleId: z.string().nullish(),
  vehicleToPurchaseId: z.string().nullish(),
  vehicleToPurchaseBrokerageId: z.string().nullish(),
});
export type OfferRequestBody = z.infer<typeof OfferRequestBodySchema>;
export type CreateOfferApiResponse = /** status 201  */ BusinessCaseResponseBody;
export const CreateOfferApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerRequestBody: OfferRequestBodySchema,
});
export type CreateOfferApiArg = z.infer<typeof CreateOfferApiArgSchema>;
export const DeleteOfferApiResponseSchema = z.unknown();
export type DeleteOfferApiResponse = z.infer<typeof DeleteOfferApiResponseSchema>;
export const DeleteOfferApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
});

export type DeleteOfferApiArg = z.infer<typeof DeleteOfferApiArgSchema>;

export type SwitchOfferSaleVehicleApiResponse = BusinessCaseResponseBody;
export const SwitchOfferSaleVehicleApiArgSchema = z.object({
  businessCaseId: z.string(),
  offerId: z.string(),
  vehicleId: z.string(),
});

export type SwitchOfferSaleVehicleApiArg = z.infer<typeof SwitchOfferSaleVehicleApiArgSchema>;
export const BranchBusinessCaseResponseBodySchema = z.object({
  id: z.string(),
  branchId: z.string(),
  vehicleId: z.string(),
});
export type BranchBusinessCaseResponseBody = z.infer<typeof BranchBusinessCaseResponseBodySchema>;
export const FindBusinessCasesByVehicleApiResponseSchema = z.array(
  BranchBusinessCaseResponseBodySchema
);
export type FindBusinessCasesByVehicleApiResponse = z.infer<
  typeof FindBusinessCasesByVehicleApiResponseSchema
>;
export const FindBusinessCasesByVehicleApiArgSchema = z.object({
  vehicles: z.array(z.string()).optional(),
});
export type FindBusinessCasesByVehicleApiArg = z.infer<
  typeof FindBusinessCasesByVehicleApiArgSchema
>;
export const GetActiveBusinessCasesByVehicleApiResponseSchema = z.array(
  ActiveBusinessCaseResponseBodySchema
);
export type GetActiveBusinessCasesByVehicleApiResponse = z.infer<
  typeof GetActiveBusinessCasesByVehicleApiResponseSchema
>;
export const GetActiveBusinessCasesByVehicleApiArgSchema = z.object({
  vehicleId: z.string(),
});
export type GetActiveBusinessCasesByVehicleApiArg = z.infer<
  typeof GetActiveBusinessCasesByVehicleApiArgSchema
>;
export type GetPurchaseBusinessCasePricesOverviewApiResponse =
  /** status 200  */ PurchaseBusinessCasePricesOverviewResponseBody;
export const VatMoneyResponseBodySchema = z.object({
  withoutVat: MoneySchema,
  withVat: MoneySchema,
});
export type VatMoneyResponseBody = z.infer<typeof VatMoneyResponseBodySchema>;
export const MarginResponseBodySchema = z.object({
  amount: MoneySchema,
  percentage: BigDecimalSchema,
});
export type MarginResponseBody = z.infer<typeof MarginResponseBodySchema>;
export const PurchaseBusinessCasePricesOverviewResponseBodySchema = z.object({
  vehiclePurchasePrice: VatMoneyResponseBodySchema.nullable(),
  vehicleSellingPrice: VatMoneyResponseBodySchema.nullable(),
  additionalCosts: MoneySchema.nullable(),
  additionalEarnings: MoneySchema.nullable(),
  totalCosts: MoneySchema.nullable(),
  totalEarnings: MoneySchema.nullable(),
  vehicleMargin: MarginResponseBodySchema.nullable(),
  totalMargin: MarginResponseBodySchema.nullable(),
});
export type PurchaseBusinessCasePricesOverviewResponseBody = z.infer<
  typeof PurchaseBusinessCasePricesOverviewResponseBodySchema
>;
export const GetPurchaseBusinessCasePricesOverviewApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetPurchaseBusinessCasePricesOverviewApiArg = z.infer<
  typeof GetPurchaseBusinessCasePricesOverviewApiArgSchema
>;
export type GetSaleBusinessCasePricesOverviewApiResponse =
  /** status 200  */ SaleBusinessCasePricesOverviewResponseBody;
export const SaleBusinessCasePricesOverviewResponseBodySchema = z.object({
  vehicleSellingPrice: VatMoneyResponseBodySchema.nullable(),
  upsell: VatMoneyResponseBodySchema.nullable(),
  discount: VatMoneyResponseBodySchema.nullable(),
  totalPrice: VatMoneyResponseBodySchema.nullable(),
  realCosts: MoneySchema.nullable(),
  expectedEarnings: MoneySchema.nullable(),
  vehicleMargin: MarginResponseBodySchema.nullable(),
  totalMargin: MarginResponseBodySchema.nullable(),
});
export type SaleBusinessCasePricesOverviewResponseBody = z.infer<
  typeof SaleBusinessCasePricesOverviewResponseBodySchema
>;
export const GetSaleBusinessCasePricesOverviewApiArgSchema = z.object({
  businessCaseId: z.string(),
});
export type GetSaleBusinessCasePricesOverviewApiArg = z.infer<
  typeof GetSaleBusinessCasePricesOverviewApiArgSchema
>;
