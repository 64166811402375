import {useAnimatedPopper} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import {useRef} from 'react';

import {MenuItemProps} from '@omnetic-dms/api';

import {suffixTestId, TestIdProps, useOutsideClick} from 'shared';

import {MenuItem} from '../components/MenuItem';
import {addPinsToMenuItems} from '../utils/addPinsToMenuItems';
import {SidebarButton} from './SidebarButton';
import {SidebarToggleMenuIcon} from './SidebarToggleMenuIcon';

interface SidebarMenuProps extends TestIdProps {
  menuItems: MenuItemProps[];
  pinnedMenuItems: string[];
}

export function SidebarMenu(props: SidebarMenuProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {togglePopper, closePopper, referenceRef, popperProps, Popper, isOpen} = useAnimatedPopper({
    placement: 'right-start',
    isOutsideClickIgnored: true,
  });

  const menuItemsWithPins = addPinsToMenuItems(props.menuItems, props.pinnedMenuItems);

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      closePopper();
    },
  });

  return (
    <div ref={ref}>
      <Box
        ref={referenceRef}
        padding={2}
        paddingBottom={0}
        data-testid={suffixTestId('hamburgerButton', props)}
      >
        <SidebarButton onClick={togglePopper} isActive={isOpen}>
          <SidebarToggleMenuIcon isOpen={isOpen} />
        </SidebarButton>
      </Box>
      <Popper {...popperProps}>
        <Box
          overflowY="auto"
          overflowX="hidden"
          width="fit-content"
          height="100vh"
          padding={2}
          backgroundColor="palettes.neutral.900.100"
          borderLeft="1px solid"
          borderColor="palettes.neutral.600.100"
        >
          <Box width={62}>
            <VStack spacing={1}>
              {menuItemsWithPins.map((module) => (
                <MenuItem key={module.id} depth={0} onClick={togglePopper} {...module} />
              ))}
            </VStack>
          </Box>
        </Box>
      </Popper>
    </div>
  );
}
