import styled from 'styled-components';

import {Link} from 'react-router-dom';

export const MenuLink = styled(Link)`
  width: 100%;
  min-width: 0;
  white-space: nowrap;
  padding: ${({theme}) => theme.getSize(2)};
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;
