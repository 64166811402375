import {Button, ButtonGroup, Card, Choice, DataStatus, Label, RadioItem} from 'platform/components';
import {Clickable, Heading, HStack, Show, Space, Text, VStack} from 'platform/foundation';

import {useState} from 'react';

import {filter, includes, isNil, not} from 'ramda';
import {isString} from 'ramda-adjunct';

import {
  useGetServiceCaseOrdersQuery,
  useGetServiceCaseOrderVariantsQuery,
  usePostServiceOrderMutation,
  usePutJobToOtherOrderMutation,
  getOptionsFromServiceCaseOrderVariants,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, LiteralUnionAutocomplete, TestIdProps} from 'shared';

import {RequestOrder} from '../../../components/RequestOrder';

interface MoveRequestProps extends TestIdProps {
  serviceCaseId: string;
  serviceJobId: string;
  serviceOrderId: string;
  onClose: () => void;
}

export function MoveRequest(props: MoveRequestProps) {
  const [selectedOrder, setSelectedOrder] = useState<LiteralUnionAutocomplete<'NEW'> | null>(null);
  const [variant, setVariant] = useState<number | string[] | string | null>(null);

  const {
    data: serviceCaseOrders,
    isLoading,
    isError,
  } = useGetServiceCaseOrdersQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const {data: orderVariants} = useGetServiceCaseOrderVariantsQuery({
    serviceCaseId: props.serviceCaseId,
  });
  const [putJobToOtherOrder, {isLoading: isPutJobToOtherOrderLoading}] =
    usePutJobToOtherOrderMutation();
  const [postServiceOrder, {isLoading: isPostServiceOrderLoading}] = usePostServiceOrderMutation();

  const handleMoveRequest = () => {
    if (!selectedOrder) {
      return;
    }

    if (selectedOrder === 'NEW') {
      if (!isString(variant)) {
        return;
      }

      postServiceOrder({serviceCaseId: props.serviceCaseId, body: {orderVariantId: variant}})
        .unwrap()
        .then((order) => {
          const createdId = order?.id;

          if (isNil(createdId)) {
            return;
          }

          putJobToOtherOrder({
            serviceCaseId: props.serviceCaseId,
            serviceJobId: props.serviceJobId,
            serviceOrderId: props.serviceOrderId,
            body: {
              serviceOtherOrderId: createdId,
            },
          })
            .unwrap()
            .then(props.onClose)
            .catch(handleApiError);
        })
        .catch(handleApiError);
      return;
    }
    putJobToOtherOrder({
      serviceCaseId: props.serviceCaseId,
      serviceJobId: props.serviceJobId,
      serviceOrderId: props.serviceOrderId,
      body: {
        serviceOtherOrderId: selectedOrder,
      },
    })
      .unwrap()
      .then(props.onClose)
      .catch(handleApiError);
  };

  const serviceCaseOrdersToShow = filter(
    (order) =>
      order?.id !== props.serviceOrderId &&
      not(includes(order?.state, ['LOCKED', 'ARCHIVED', 'CLOSED', 'CANCELLED'])),
    serviceCaseOrders ?? []
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={23}>
      <VStack spacing={4}>
        <Text color="tertiary" size="small">
          {i18n.t('entity.orderRequest.actions.moveRequestSelectDescription')}
        </Text>
        {serviceCaseOrdersToShow?.map(
          (order, index) =>
            order && (
              <RequestOrder
                key={order?.id}
                order={order}
                isSelected={selectedOrder === order?.id}
                onSelect={() => setSelectedOrder(order?.id ?? null)}
                data-testid={suffixTestId(`requestOrder-[${index}]`, props)}
              />
            )
        )}
        <Card variant="inlineWhite">
          <Clickable
            onClick={() => setSelectedOrder('NEW')}
            data-testid={suffixTestId('selectNew', props)}
          >
            <HStack spacing={3} align="center">
              <RadioItem
                value={selectedOrder === 'NEW'}
                data-testid={suffixTestId('selected', props)}
              />
              <Heading size={4}>{i18n.t('entity.order.labels.newServiceOrder')}</Heading>
            </HStack>
          </Clickable>
          <Show when={selectedOrder === 'NEW'}>
            <Space vertical={4} />
            <Label>{i18n.t('entity.order.labels.orderVariant')}</Label>
            <Choice
              value={variant}
              onChange={setVariant}
              options={getOptionsFromServiceCaseOrderVariants(orderVariants)}
              placeholder={i18n.t('general.labels.select')}
              menuInPortal
              data-testid={suffixTestId('variant', props)}
            />
          </Show>
        </Card>
        <ButtonGroup align="right">
          <Button
            variant="secondary"
            onClick={props.onClose}
            data-testid={suffixTestId('cancel', props)}
            title={i18n.t('general.actions.cancel')}
          />
          <Button
            onClick={handleMoveRequest}
            isDisabled={!selectedOrder || (selectedOrder === 'NEW' && !Boolean(variant))}
            isLoading={isPutJobToOtherOrderLoading || isPostServiceOrderLoading}
            data-testid={suffixTestId('move', props)}
            title={i18n.t('entity.order.actions.moveHere')}
          />
        </ButtonGroup>
      </VStack>
    </DataStatus>
  );
}
