import {differenceInCalendarDays} from 'date-fns';
import {
  Action,
  Actions,
  Alert,
  Attributes,
  AttributesRow,
  Button,
  ButtonGroup,
  Card,
  closeCurrentDialog,
  closeDialog,
  DataStatus,
  Flag,
  openDeleteDialog,
  openDialog,
  Parameter,
  Parameters,
  Tooltip,
} from 'platform/components';
import {Heading, HStack, Link, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency, useFormatNumber} from 'platform/locale';

import {isNil, isNotEmpty, isNotNil} from 'ramda';
import {isNilOrEmpty, isNotBoolean} from 'ramda-adjunct';

import {
  ComplaintResponseBody,
  FileResponseBody,
  useDeleteComplaintMutation,
  useGetComplaintQuery,
  useGetCustomerV2Query,
  useGetUserQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {businessCaseRoutes, customerRoutes} from '@omnetic-dms/routes';

import {
  buildArray,
  composePath,
  Nullish,
  openFile,
  parseDate,
  suffixTestId,
  TestIdProps,
} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {usePermissions} from '../../../hooks/usePermissions/usePermissions';
import {getComplaintFlag} from '../../../utils/getComplaintFlag';
import {getCustomerName} from '../../../utils/getCustomerName';
import {handleApiError} from '../../../utils/handleApiError';
import {DocumentsDialog} from '../../DocumentsDialog/DocumentsDialog';
import {ComplaintDocumentDialog} from './ComplaintDocumentDialog';

interface VehicleComplaintCardProps extends TestIdProps {
  complaintId: string;
  onEdit: (complaintId: string | Nullish, code: string | Nullish) => void;
  originalMileage?: number | Nullish;
  onInspectionClick: (complaint: ComplaintResponseBody) => void;
  isInspectionButtonLoading: boolean;
}

const DIALOG_ID = 'complaintDocumentDialog';

export function VehicleComplaintCard(props: VehicleComplaintCardProps) {
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const {
    data: complaint,
    isLoading: isComplaintLoading,
    isError: isComplaintError,
  } = useGetComplaintQuery({
    complaintId: props.complaintId,
  });

  const {
    data: createdBy,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUserQuery({id: complaint?.createdBy ?? ''}, {skip: isNil(complaint?.createdBy)});

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
  } = useGetCustomerV2Query(
    {customerId: complaint?.customerId ?? ''},
    {skip: isNil(complaint?.customerId)}
  );

  const [
    canEditComplaint,
    canDeleteComplaint,
    canCreateProtocol,
    canReadInspection,
    canCreateInspection,
  ] = usePermissions({
    permissionKeys: [
      'complaintPatch',
      'complaintDelete',
      'complaintProtocol',
      'readInspection',
      'createInspection',
    ],
    scopes: {
      // TODO: fix scope
      readInspection: {inspectionType: ''},
    },
  });

  const [deleteComplaint] = useDeleteComplaintMutation();

  const isLoading = isComplaintLoading || isUserLoading || isCustomerLoading;
  const isError = isComplaintError || isUserError || isCustomerError;

  const dateReceived = complaint?.receiveAt;
  const daysSinceReceived = differenceInCalendarDays(new Date(), parseDate(dateReceived ?? 0));

  const formatMileage = (mileage: number | Nullish) => {
    if (isNilOrEmpty(mileage)) {
      return EMPTY_PLACEHOLDER;
    }

    return `${formatNumber(mileage)} ${i18n.t('general.metric.km')}`;
  };

  const getResolutionText = (resolution: boolean | Nullish) => {
    if (isNotBoolean(resolution)) {
      return EMPTY_PLACEHOLDER;
    }

    return resolution ? i18n.t('general.labels.yes') : i18n.t('general.labels.no');
  };

  const handleComplaintDelete = () => {
    openDeleteDialog({
      onConfirm: () =>
        deleteComplaint({complaintId: props.complaintId}).unwrap().catch(handleApiError),
    });
  };

  const onDocumentCreation = (document: FileResponseBody) => {
    closeDialog(DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={complaint?.customerId}
        documents={[document]}
        isDocxFormat
        text={i18n.t('entity.vehicleComplaint.labels.complaintDocumentCreatedText')}
      />,
      {
        title: i18n.t('entity.vehicleComplaint.labels.complaintDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const onCreateComplaintDocumentClick = () => {
    openDialog(
      <ComplaintDocumentDialog
        complaintId={props.complaintId}
        onDocumentCreation={onDocumentCreation}
      />,
      {
        id: DIALOG_ID,
        title: i18n.t('entity.vehicleComplaint.labels.complaintDocument'),
      }
    );
  };

  const attributesCol1 = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.vehicleComplaint.labels.dateReceived'),
      value: isNotNil(complaint?.receiveAt)
        ? formatDateTime('dateShort', parseDate(complaint?.receiveAt ?? ''))
        : EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.problem'),
      value: complaint?.problem ?? EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicle.labels.mileage'),
      content: (
        <HStack spacing={2}>
          <Text size="xSmall">{formatMileage(complaint?.currentMileage)}</Text>
          <Show when={isNotNil(props.originalMileage)}>
            <Text size="xSmall" color="tertiary">
              ({formatMileage(props.originalMileage)}{' '}
              {i18n.t('entity.vehicleComplaint.labels.atMomentOfSell')})
            </Text>
          </Show>
        </HStack>
      ),
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.claimedSolution'),
      value: isNotNil(complaint?.claimedSolution)
        ? i18n.t(`entity.vehicleComplaint.labels.${complaint?.claimedSolution.toLowerCase()}`)
        : EMPTY_PLACEHOLDER,
    },
  ])
    .when(complaint?.claimedSolution === 'DISCOUNT', {
      label: i18n.t('entity.vehicleComplaint.labels.claimedDiscount'),
      value: isNotNil(complaint?.claimedDiscount?.amount)
        ? formatCurrency(
            Number(complaint!.claimedDiscount!.amount),
            complaint!.claimedDiscount!.currency
          )
        : EMPTY_PLACEHOLDER,
    })
    .add({
      label: i18n.t('general.labels.note'),
      value: complaint?.note ?? EMPTY_PLACEHOLDER,
    })
    .add({
      label: i18n.t('entity.vehicleComplaint.labels.complaintDocument'),
      content: isNotEmpty(complaint?.documents) ? (
        <VStack spacing={2}>
          {complaint?.documents.map((document) => (
            <VStack spacing={0} key={document.id}>
              <Link
                size="xSmall"
                onClick={() => openFile(document.pdfUri)}
                title={document.pdfFilename}
                data-testid={suffixTestId('openComplaintDocumentLink', props)}
              />
              <Text size="xSmall" color="tertiary">
                ({formatDateTime('dateTimeShort', parseDate(document.uploadedAt))})
              </Text>
            </VStack>
          ))}
        </VStack>
      ) : (
        EMPTY_PLACEHOLDER
      ),
    });

  const attributesCol2: AttributesRow[] = [
    {
      label: i18n.t('entity.vehicleComplaint.labels.resolvedOn'),
      value: isNotNil(complaint?.closeAt)
        ? formatDateTime('dateShort', parseDate(complaint?.closeAt ?? ''))
        : EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.result'),
      value: complaint?.resultNote ?? EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.technicalReport'),
      value: complaint?.technicalReport ?? EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.returnMileage'),
      value: formatMileage(complaint?.returnMileage),
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.vehicleReturned'),
      value: getResolutionText(complaint?.vehicleReturned),
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.cost'),
      value: isNotNil(complaint?.cost?.amount)
        ? formatCurrency(Number(complaint!.cost!.amount), complaint!.cost!.currency)
        : EMPTY_PLACEHOLDER,
    },
    {
      label: i18n.t('entity.vehicleComplaint.labels.customerSatisfied'),
      value: getResolutionText(complaint?.customerSatisfied),
    },
  ];

  const actions: Action[] = [
    {
      title: i18n.t('general.actions.edit'),
      leftIcon: 'image/edit',
      type: 'button',
      variant: 'ghostLink',
      onClick: () => props.onEdit(props.complaintId, complaint?.code),
      isDisabled: !canEditComplaint,
    },
    {
      title: i18n.t('general.actions.delete'),
      leftIcon: 'navigation/cancel',
      type: 'button',
      variant: 'dangerGhost',
      onClick: handleComplaintDelete,
      isDisabled: !canDeleteComplaint,
    },
  ];

  const parameters: Parameter[] = [
    `${i18n.t('general.labels.generated')}: ${
      isNotNil(complaint?.createdAt)
        ? formatDateTime('dateTimeShort', parseDate(complaint?.createdAt ?? ''))
        : EMPTY_PLACEHOLDER
    }`,
    `${i18n.t('general.labels.createdBy')}: ${createdBy?.firstName} ${createdBy?.lastName}`,
  ];

  const flag = getComplaintFlag(complaint);

  const isInspectionButtonAvailable = isNotNil(complaint?.inspectionId)
    ? canReadInspection
    : canCreateInspection;
  const inspectionButtonTooltip = !isInspectionButtonAvailable
    ? i18n.t('general.labels.noPermission')
    : undefined;

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Card>
        <VStack spacing={4}>
          <HStack align="flex-start" justify="space-between">
            <VStack spacing={1}>
              <HStack spacing={2} align="center">
                <Heading size={4}>{`${i18n.t('entity.vehicleComplaint.labels.complaint')} ${
                  complaint?.code
                } - ${complaint?.reason}`}</Heading>
                <Flag
                  data-testid={suffixTestId('complaintFlags', props)}
                  label={flag.label}
                  colorScheme={flag.colorScheme}
                  size="small"
                />
              </HStack>
              <Parameters
                data-testid={suffixTestId('complaintParameters', props)}
                parameters={parameters}
                color="secondary"
              />
            </VStack>
            <Actions data-testid={suffixTestId('complaintActions', props)} actions={actions} />
          </HStack>
          <Show when={isNil(complaint?.closeAt)}>
            <Alert
              data-testid={suffixTestId('daysInProgressAlert', props)}
              title={`${i18n.t('entity.vehicleComplaint.notifications.daysInProgress')}:`}
              message={String(daysSinceReceived)}
              variant={daysSinceReceived > 20 ? 'warning' : 'info'}
            />
          </Show>
          <Card variant="inlineGrey">
            <VStack spacing={4}>
              <Heading size={4}>{getCustomerName(customer)}</Heading>
              <HStack spacing={4}>
                <Link
                  data-testid={suffixTestId('customerLink', props)}
                  title={i18n.t('entity.customer.labels.customer')}
                  href={composePath(customerRoutes.detail, {
                    params: {id: complaint?.customerId ?? ''},
                  })}
                  leftIcon="action/open_in_new"
                  size="small"
                  target="_blank"
                />
                <Link
                  data-testid={suffixTestId('businessCaseLink', props)}
                  title={i18n.t('page.businessCase.labels.businessCase')}
                  href={composePath(businessCaseRoutes.overview, {
                    params: {id: complaint?.businessCaseId ?? ''},
                  })}
                  leftIcon="action/open_in_new"
                  size="small"
                  target="_blank"
                />
              </HStack>
              <HStack spacing={4}>
                <Attributes
                  data-testid={suffixTestId('attributesFirst', props)}
                  rows={attributesCol1}
                />
                <Attributes
                  data-testid={suffixTestId('attributesSecond', props)}
                  rows={attributesCol2}
                />
              </HStack>
            </VStack>
          </Card>

          <ButtonGroup align="right">
            <Tooltip label={inspectionButtonTooltip}>
              <Button
                data-testid={suffixTestId('inspectionButton', props)}
                title={i18n.t('entity.vehicleComplaint.labels.carAuditInspection')}
                leftIcon="action/youtube_searched_for"
                onClick={() => props.onInspectionClick(complaint!)}
                isLoading={props.isInspectionButtonLoading}
                isDisabled={!isInspectionButtonAvailable}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip label={!canCreateProtocol ? i18n.t('general.labels.noPermission') : undefined}>
              <Button
                data-testid={suffixTestId('createComplaintDocumentButton', props)}
                title={i18n.t('entity.vehicleComplaint.actions.createComplaintDocument')}
                leftIcon="editor/insert_drive_file"
                onClick={onCreateComplaintDocumentClick}
                isDisabled={!canCreateProtocol}
                variant="outlined"
              />
            </Tooltip>
          </ButtonGroup>
        </VStack>
      </Card>
    </DataStatus>
  );
}
