import {
  Button,
  Card,
  DataStatus,
  Flag,
  FormSubmitHandler,
  IconButton,
  Parameter,
  Parameters,
  RadioItem,
} from 'platform/components';
import {Box, Clickable, Heading, Hide, HStack, Link, Show, Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  FullVehicleResponseBody,
  useGetAdditionalNoteQuery,
  useGetVehicleQuery,
  useSetAdditionalNoteMutation,
  useUpdateVehicleV2Mutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {vehiclesRoutes} from '@omnetic-dms/routes';

import {
  buildArray,
  composePath,
  Nullish,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useToggle,
} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {AftersaleVehicleOverview} from '../../AftersalesVehicleWidgetCard/components/AftersaleVehicleOverview';
import {getVehicleV2FromServiceVehicle} from '../../AftersalesVehicleWidgetCard/utils/getVehicleV2FromServiceVehicle';
import {ServiceVehicleForm} from '../../ServiceVehicleForm/ServiceVehicleForm';
import {ServiceVehicleFormType} from '../../ServiceVehicleForm/types/ServiceVehicleFormType';
import {getServiceVehicleDefaultValueFromVehicle} from '../../ServiceVehicleForm/utils/getServiceVehicleDefaultValueFromVehicle';
import {OwnershipType} from '../types/ownershipType';

interface SuggestedVehicleProps extends TestIdProps {
  vehicleId: string;
  isSelected: boolean;
  type: OwnershipType | Nullish;
  onSelect: (vehicle: FullVehicleResponseBody | null) => void;
}

export function SuggestedVehicle(props: SuggestedVehicleProps) {
  const [updateVehicle] = useUpdateVehicleV2Mutation();
  const [setAdditionalNote] = useSetAdditionalNoteMutation();
  const {data: vehicle, isLoading, isError} = useGetVehicleQuery({vehicleId: props.vehicleId});
  const {data: additionalNote} = useGetAdditionalNoteQuery({vehicleId: props.vehicleId});

  const [isExpanded, expand] = useToggle();
  const [isEditting, setEdit, cancelEdit] = useBoolean();

  const onEditVehicleSubmit: FormSubmitHandler<ServiceVehicleFormType> = async (data) => {
    const vehicleId = vehicle?.id;

    if (isNil(vehicleId)) {
      return;
    }

    await updateVehicle({...getVehicleV2FromServiceVehicle(data), id: vehicleId})
      .unwrap()
      .then(cancelEdit)
      .then(() => {
        if (isNotNilOrEmpty(data.note)) {
          setAdditionalNote({
            vehicleId,
            additionalNoteRequestBody: {note: data.note ?? ''},
          });
        }
      })
      .catch(handleApiError);
  };

  const flagTitle = match(props.type)
    .with('CORVEHICLECUSTOMERTYPE_OPERATOR', () => i18n.t('entity.ownership.label.vehicleOperator'))
    .with('CORVEHICLECUSTOMERTYPE_OWNER', () => i18n.t('entity.ownership.label.vehicleOwner'))
    .with('CORVEHICLECUSTOMERTYPE_USER', () => i18n.t('entity.ownership.label.vehicleUser'))
    .with('CORVEHICLECUSTOMERTYPE_MANAGER', () => i18n.t('entity.ownership.label.vehicleManager'))
    .otherwise(() => undefined);

  if (isError) {
    return null;
  }

  return (
    <Card variant="inlineWhite">
      <DataStatus isLoading={isLoading} minHeight={12}>
        <HStack spacing={4} align="flex-start">
          <RadioItem
            value={props.isSelected}
            onChange={() => props.onSelect(vehicle ?? null)}
            data-testid={suffixTestId('selected', props)}
          />
          <Box flex={1}>
            <Clickable
              data-testid={suffixTestId('selectVehicle', props)}
              onClick={() => props.onSelect(vehicle ?? null)}
            >
              <HStack spacing={2} align="center">
                <Heading size={4} data-testid={suffixTestId('title', props)}>
                  {vehicle?.title}
                </Heading>
                <Show when={isNotNil(flagTitle)}>
                  <Flag
                    data-testid={suffixTestId('selectVehicle', props)}
                    label={flagTitle ?? ''}
                    colorScheme="blue"
                    size="small"
                    isSubtle
                  />
                </Show>
              </HStack>
            </Clickable>
            <Space vertical={1} />
            <Parameters
              color="secondary"
              size="small"
              parameters={buildArray<Parameter>()
                .when(vehicle?.vehicle.registrationPlate, vehicle?.vehicle.registrationPlate)
                .when(vehicle?.vin, vehicle?.vin)}
              data-testid={suffixTestId('parameters', props)}
            />
          </Box>
          <IconButton
            data-testid={suffixTestId('suggestedVehicle-expand', props)}
            icon={isExpanded ? 'navigation/expand_less' : 'navigation/expand_more'}
            onClick={expand}
          />
        </HStack>
        <Show when={isExpanded}>
          <Space vertical={3} />
          <Show when={isEditting}>
            <ServiceVehicleForm
              data-testid={suffixTestId('suggestedVehicle', props)}
              defaultValue={getServiceVehicleDefaultValueFromVehicle(vehicle, additionalNote?.note)}
              onCancel={cancelEdit}
              onSubmit={onEditVehicleSubmit}
            />
          </Show>
          <Hide when={isEditting}>
            <Button
              data-testid={suffixTestId('suggestedVehicle-Edit', props)}
              size="small"
              variant="link"
              leftIcon="image/edit"
              title={i18n.t('general.actions.edit')}
              onClick={setEdit}
            />
            <Space vertical={1} />
            {vehicle && (
              <AftersaleVehicleOverview
                vehicle={vehicle}
                data-testid={suffixTestId('overview', props)}
              />
            )}
            <Space vertical={1} />
            <Link
              size="small"
              rightIcon="action/launch"
              href={vehicle?.id && composePath(vehiclesRoutes.detail, {params: {id: vehicle?.id}})}
              data-testid={suffixTestId('detail', props)}
              target="_blank"
              title={i18n.t('entity.vehicle.labels.vehicleDetails')}
            />
          </Hide>
        </Show>
      </DataStatus>
    </Card>
  );
}
