import {GridPhoto} from 'platform/components';

import {useEffect, useState} from 'react';

import {filter} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {
  useLazyGetAuditDataQuery,
  useLazyGetServiceCheckInQuery,
  useLazyGetServiceHandOverQuery,
  VehicleAlbumResponseBody,
} from '@omnetic-dms/api';
import {getGridPhotosFromAudit} from '@omnetic-dms/shared';

type HistoryPhotos = {
  checkinPhotos: GridPhoto[];
  handoverPhotos: GridPhoto[];
};

export function useHistoryAlbumPhotos(historyAlbums?: VehicleAlbumResponseBody[]) {
  const [historyAlbumPhotos, setHistoryAlbumPhotos] = useState<HistoryPhotos[]>([]);
  const [isHistoryPhotosLoading, setIsHistoryPhotosLoading] = useState(true);

  const [lazyGetServiceCheckin] = useLazyGetServiceCheckInQuery();
  const [lazyGetServiceHandover] = useLazyGetServiceHandOverQuery();
  const [lazyGetAuditData] = useLazyGetAuditDataQuery();

  useEffect(() => {
    const promises =
      historyAlbums?.map(async (album) => {
        if (!album.serviceCaseId) {
          return;
        }

        const checkin = await lazyGetServiceCheckin({
          serviceCaseId: album.serviceCaseId,
        }).unwrap();
        const handover = await lazyGetServiceHandover({
          serviceCaseId: album.serviceCaseId,
        }).unwrap();

        const checkinAudit = checkin?.inspectionId?.[0];
        const handoverAudit = handover?.inspectionId?.[0];

        const checkinAuditData = checkinAudit
          ? await lazyGetAuditData({
              auditId: checkinAudit,
            }).unwrap()
          : null;

        const handoverAuditData = handoverAudit
          ? await lazyGetAuditData({
              auditId: handoverAudit,
            }).unwrap()
          : null;

        const checkinPhotos = getGridPhotosFromAudit(checkinAuditData?.[0]?.categories);
        const handoverPhotos = getGridPhotosFromAudit(handoverAuditData?.[0]?.categories);

        return {
          checkinPhotos,
          handoverPhotos,
        };
      }) || [];

    Promise.all(promises)
      .then((result) => {
        const filteredResult = filter(isNotNilOrEmpty, result);
        setHistoryAlbumPhotos(filteredResult as HistoryPhotos[]);
      })
      .finally(() => setIsHistoryPhotosLoading(false));
  }, [historyAlbums, lazyGetAuditData, lazyGetServiceCheckin, lazyGetServiceHandover]);

  const historyAlbumPhotosCount = historyAlbumPhotos.reduce(
    (result, photos) => result + photos.checkinPhotos.length + photos.handoverPhotos.length,
    0
  );

  return {
    isHistoryPhotosLoading,
    historyAlbumPhotos,
    historyAlbumPhotosCount,
  };
}
