import {Button, FormControl, FormField} from 'platform/components';
import {Grid, GridItem, HStack, Heading, VStack} from 'platform/foundation';

import {GetWarehouseHandlingUnitsResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {AddSupplierOrderItemForm} from '../../../../../types/AddSupplierOrderItemForm';
import {getOptionsFromHandlingUnits} from '../../../../../utils/getOptionsFromHandlingUnits';

export interface SupplierToWarehouseMappingFormProps extends RequiredTestIdProps {
  control: FormControl<AddSupplierOrderItemForm>;
  handlingUnit: string | Nullish;
  handlingUnits: GetWarehouseHandlingUnitsResponse | Nullish;
  areHandlingUnitsLoading: boolean;
  onSaveMapping: () => Promise<void>;
  isSaveMappingLoading: boolean;
}

export function SupplierToWarehouseMappingForm(props: SupplierToWarehouseMappingFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <HStack justify="space-between">
        <Heading size={5}>{i18n.t('entity.warehouse.labels.supplierArticle')}</Heading>
        <Button
          variant="link"
          size="small"
          title={i18n.t('entity.warehouse.actions.saveMapping')}
          leftIcon="content/save"
          onClick={props.onSaveMapping}
          isLoading={props.isSaveMappingLoading}
          data-testid={suffixTestId('actions.saveMapping', props)}
        />
      </HStack>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="supplierToWarehouseMapping.supplierQuantityMapping"
            label={i18n.t('entity.warehouse.labels.supplierQuantity')}
            suffix={props.handlingUnit}
            data-testid={suffixTestId('inputs.supplierQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="supplierToWarehouseMapping.supplierUnitMapping"
            label={i18n.t('entity.warehouse.labels.supplierUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled
            data-testid={suffixTestId('inputs.supplierUnit', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="number"
            name="supplierToWarehouseMapping.warehouseQuantityMapping"
            label={i18n.t('entity.warehouse.labels.warehouseQuantity')}
            suffix={props.handlingUnit}
            data-testid={suffixTestId('inputs.warehouseQuantity', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            control={props.control}
            type="choice"
            name="supplierToWarehouseMapping.warehouseUnitMapping"
            label={i18n.t('entity.warehouse.labels.warehouseUnit')}
            options={getOptionsFromHandlingUnits(props.handlingUnits)}
            isLoading={props.areHandlingUnitsLoading}
            isDisabled
            isNotClearable
            menuInPortal
            data-testid={suffixTestId('inputs.warehouseUnit', props)}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
}
