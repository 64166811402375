import {z} from 'zod';

import {
  BasePriceSchema,
  BasketTooltipSchema,
  PriceWithAndWithoutVatSchema,
} from './aftersalesCommon';
import {BaseIdSchema, BaseVoidResponseSchema} from './base';

/**
 * Base types
 */

export const BaseDirectSaleIdSchema = z.object({
  directSaleId: z.string(),
});

export const BaseDirectSaleActionSchema = z.object({
  actionKey: z.string(),
  newDirectSaleVariantId: z.string().optional(),
});
export type BaseDirectSaleAction = z.infer<typeof BaseDirectSaleActionSchema>;

const BaseDirectSaleSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  branchId: z.string(),
  directSaleVariantId: z.string(),
  authorizationProfileId: z.string(),
  number: z.string(),
  state: z.enum(['ARCHIVED', 'OPEN', 'LOCKED', 'CLOSED']),
  name: z.string().optional(),
  vehicleId: z.string().optional(),
  customerId: z.string().optional(),
  description: z.string().optional(),
  neededAt: z.string().optional(),
  createdAt: z.string(),
  createdBy: z.string(),
  variantName: z.string(),
  hasPendingReturn: z.boolean(),
  tooltip: z.object({
    authorizationProfile: z.string().optional(),
    directSaleType: z.string().optional(),
    isUnitPriceWithVat: z.string().optional(),
    priceType: z.string().optional(),
    priceTypeRatio: z.number().optional(),
    minimalMaterialMargin: z.string().optional(),
    rateType: z.string().optional(),
    rateTypeRatio: z.number().optional(),
    minimalWorkMargin: z.string().optional(),
    note: z.string().optional(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  originObjectType: z.string(),
});
export type BaseDirectSale = z.infer<typeof BaseDirectSaleSchema>;

export const BaseCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const BaseDirectSaleLabourCatalogueSchema = z.object({
  id: z.string(),
  category: BaseCategorySchema,
});
export type BaseDirectSaleLabourCatalogue = z.infer<typeof BaseDirectSaleLabourCatalogueSchema>;

export const BaseDirectSaleMaterialItemSchema = z.object({
  id: z.string(),
  warehouse_id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  requestType: z.string(),
  name: z.string(),
  number: z.string(),
  quantity: z.number(),
  unit: z.string(),
  dispensingUnit: z.number(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type BaseDirectSaleMaterialItem = z.infer<typeof BaseDirectSaleMaterialItemSchema>;

export const BaseDirectSaleLabourItemSchema = z.object({
  id: z.string(),
  itemEditingAllowed: z.boolean(),
  priceType: z.string(),
  priceTypeName: z.string(),
  name: z.string(),
  number: z.string(),
  labourCatalog: BaseDirectSaleLabourCatalogueSchema,
  technican: z.string(),
  quantity: z.number(),
  unit: z.string(),
  quantityEditingAllowed: z.boolean(),
  unitPrice: PriceWithAndWithoutVatSchema,
  discount: PriceWithAndWithoutVatSchema.extend({
    rate: z.number(),
  }),
  totalPrice: PriceWithAndWithoutVatSchema,
  tooltip: z.array(BasketTooltipSchema),
});
export type BaseDirectSaleLabourItem = z.infer<typeof BaseDirectSaleLabourItemSchema>;

const BaseDirectSaleActionKeySchema = z.enum([
  'discount',
  'variantChange',
  'offer',
  'checkout',
  'lock',
  'archive',
  'unlock',
  'reopen',
  'delete',
]);

const TypeSchema = z.enum(['list-item', 'divider']);

const StyleSchema = z.enum(['enable', 'hidden', 'disabled']);

const DirectSaleActionsOptionSchema = z.object({
  type: TypeSchema,
  key: BaseDirectSaleActionKeySchema,
  style: StyleSchema,
  position: z.number(),
});
export type DirectSaleActionsOption = z.infer<typeof DirectSaleActionsOptionSchema>;

export const DirectSaleActionsSchema = z.object({
  optionClick: z.string(),
  options: z.array(DirectSaleActionsOptionSchema),
});
export type DirectSaleActions = z.infer<typeof DirectSaleActionsSchema>;

/**
 * GET requests
 */
export const GetDirectSaleRequestSchema = BaseDirectSaleIdSchema;
export type GetDirectSaleRequest = z.infer<typeof GetDirectSaleRequestSchema>;

export const GetDirectSaleResponseSchema = BaseDirectSaleSchema.merge(
  z.object({itemsCount: z.number().nullish()})
);
export type GetDirectSaleResponse = z.infer<typeof GetDirectSaleResponseSchema>;

export const GetDirectSaleActionsRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleActionsRequest = z.infer<typeof GetDirectSaleActionsRequestSchema>;

export const GetDirectSaleActionsResponseSchema = z.object({
  directSaleActions: DirectSaleActionsSchema,
});
export type GetDirectSaleActionsResponse = z.infer<typeof GetDirectSaleActionsResponseSchema>;

export const GetDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
});
export type GetDirectSaleDiscountRequest = z.infer<typeof GetDirectSaleDiscountRequestSchema>;

const DiscountWorkRateSchema = z.object({
  workType: z.string(),
  workRateWithoutVat: BasePriceSchema,
});
export type DiscountWorkRate = z.infer<typeof DiscountWorkRateSchema>;

const MaterialDiscountSchema = z
  .object({
    discountToSalePrice: z.number().optional(),
    markupToPurchasePrice: z.number().optional(),
  })
  .nullable();

const WorkDiscountSchema = z
  .object({
    percentageDiscount: z.number().optional(),
    discountedWorkRate: z.array(DiscountWorkRateSchema).optional(),
  })
  .nullable();

const DirectSaleDiscountSchema = z.object({
  workDiscount: WorkDiscountSchema,
  materialDiscount: MaterialDiscountSchema,
});

export const GetDirectSaleDiscountResponseSchema = DirectSaleDiscountSchema;
export type GetDirectSaleDiscountResponse = z.infer<typeof GetDirectSaleDiscountResponseSchema>;

/**
 * POST requests
 */
export const PostDirectSaleRequestSchema = z.object({
  directSaleVariantId: z.string(),
});
export type PostDirectSaleRequest = z.infer<typeof PostDirectSaleRequestSchema>;

export const PostDirectSaleResponseSchema = BaseIdSchema;
export type PostDirectSaleResponse = z.infer<typeof PostDirectSaleResponseSchema>;

/**
 * PATCH requests
 */
const PatchDirectSaleRequestBodySchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  vehicleId: z.union([z.string(), z.null()]).optional(),
  customerId: z.union([z.string(), z.null()]).optional(),
  neededAt: z.union([z.string(), z.null()]).optional(),
  isItemNeededAtUpdate: z.union([z.boolean(), z.null()]).optional(),
});

export const PatchDirectSaleRequestSchema = z.object({
  directSaleId: z.string(),
  body: PatchDirectSaleRequestBodySchema,
});
export type PatchDirectSaleRequest = z.infer<typeof PatchDirectSaleRequestSchema>;

export const PatchDirectSaleResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleResponse = z.infer<typeof PatchDirectSaleResponseSchema>;

export const PatchDirectSaleGetCurrentPriceRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleItemId: z.array(z.string()),
  }),
});
export type PatchDirectSaleGetCurrentPriceRequest = z.infer<
  typeof PatchDirectSaleGetCurrentPriceRequestSchema
>;

export const PatchDirectSaleGetCurrentPriceResponseSchema = BaseVoidResponseSchema;
export type PatchDirectSaleGetCurrentPriceResponse = z.infer<
  typeof PatchDirectSaleGetCurrentPriceResponseSchema
>;

/**
 * DELETE requests
 */
export const DeleteDirectSaleRequestSchema = z.object({
  directSaleId: z.array(z.string()),
});
export type DeleteDirectSaleRequest = z.infer<typeof DeleteDirectSaleRequestSchema>;

export const DeleteDirectSaleResponseSchema = BaseVoidResponseSchema;
export type DeleteDirectSaleResponse = z.infer<typeof DeleteDirectSaleResponseSchema>;

export const BulkDeleteDirectSaleItemsRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    items: z.array(z.object({id: z.string()})),
  }),
});
export type BulkDeleteDirectSaleItemsRequest = z.infer<
  typeof BulkDeleteDirectSaleItemsRequestSchema
>;

export const BulkDeleteDirectSaleItemsResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteDirectSaleItemsResponse = z.infer<
  typeof BulkDeleteDirectSaleItemsResponseSchema
>;

/**
 * PUT requests
 */
export const PutDirectSaleActionRequestSchema = z.object({
  directSaleId: z.string(),
  body: BaseDirectSaleActionSchema,
});
export type PutDirectSaleActionRequest = z.infer<typeof PutDirectSaleActionRequestSchema>;

export const PutDirectSaleActionResponseSchema = BaseVoidResponseSchema;
export type PutDirectSaleActionResponse = z.infer<typeof PutDirectSaleActionResponseSchema>;

export const PutDirectSaleDiscountRequestSchema = z.object({
  directSaleId: z.string(),
  body: z.object({
    directSaleDiscount: DirectSaleDiscountSchema,
    isDiscountApply: z.boolean(),
  }),
});
export type PutDirectSaleDiscountRequest = z.infer<typeof PutDirectSaleDiscountRequestSchema>;

export const PutDirectSaleDiscountResponseSchema = BaseVoidResponseSchema;
export type PutDirectSaleDiscountResponse = z.infer<typeof PutDirectSaleDiscountResponseSchema>;
