import {Icon, Box, Text, Show, HStack, Integer} from 'platform/foundation';
import styled from 'styled-components';

import {MenuItemProps as DefaultMenuItemProps} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {composePath, useBoolean, useToggle} from 'shared';

import {MenuItemPin} from '../components/MenuItemPin';
import {MenuItemActionButton} from './MenuItemActionButton';
import {MenuLink} from './MenuLink';

function getDepthPadding(depth: Integer): Integer {
  if (depth > 4) {
    return 16;
  }
  return (depth * 4) as Integer;
}

interface MenuItemProps extends DefaultMenuItemProps {
  depth: Integer;
  isPinned?: boolean;
  onClick: () => void;
}

export function MenuItem(props: MenuItemProps) {
  const [isOpen, toggle] = useToggle(false);
  const [isPinVisible, setPinVisible, hidePin] = useBoolean(false);

  const isGroup = props.content && props.content.length > 1;

  return (
    <>
      <StyledMenuItem onMouseEnter={setPinVisible} onMouseLeave={hidePin}>
        <MenuLink
          to={composePath(props.path, {isSearchParamsPreserved: false})}
          onClick={props.onClick}
        >
          <Box overflow="hidden" paddingLeft={getDepthPadding(props.depth)}>
            <HStack spacing={2} justify="space-between" align="center">
              <Icon
                size={4}
                color="text.white"
                value={props.layoutProps.iconV2 ?? props.layoutProps.icon}
              />
              <Box width="100%" overflow="hidden">
                <Text size="small" color="white" noWrap>
                  {i18n.t(props.layoutProps.title)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </MenuLink>
        <HStack spacing={0}>
          <Show when={isPinVisible || props.isPinned}>
            <MenuItemPin id={props.id} isPinned={props.isPinned} path={props.path} />
          </Show>
          <Show when={isGroup}>
            <MenuItemActionButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                toggle();
              }}
            >
              <StyledIconWrapper isOpen={isOpen}>
                <Icon color="text.white" size={4} value="navigation/expand_more" />
              </StyledIconWrapper>
            </MenuItemActionButton>
          </Show>
        </HStack>
      </StyledMenuItem>
      <Show when={isOpen && isGroup}>
        {props.content?.map((module) => (
          <MenuItem
            key={module.id}
            depth={(props.depth + 1) as Integer}
            onClick={props.onClick}
            {...module}
          />
        ))}
      </Show>
    </>
  );
}

const StyledMenuItem = styled.div`
  display: flex;
  max-width: 100%;
  height: '100%';
  overflow: hidden;
  border-radius: ${({theme}) => theme.getSize(1)};
  color: ${({theme}) => theme.colors.text.white};
  &:hover {
    background-color: ${(props) => props.theme.colors.palettes.neutral[500]['100']};
  }
`;

const StyledIconWrapper = styled.div<{isOpen: boolean}>`
  transition: transform 0.3s ease-in-out;
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
