import {GridPhoto} from 'platform/components';

import {pipe, propEq, prop, defaultTo, filter, map, sortBy, chain, uniq} from 'ramda';

import {
  AuditDataAssetsBody,
  AuditDataAssetsFilesBody,
  LoadAuditDataCategories,
  VehiclePhotoResponseBody,
} from '@omnetic-dms/api';

export const getGridPhotos = (albumId?: string) =>
  pipe<
    [VehiclePhotoResponseBody[] | undefined],
    VehiclePhotoResponseBody[],
    VehiclePhotoResponseBody[],
    VehiclePhotoResponseBody[],
    GridPhoto[]
  >(
    defaultTo([]),
    filter(propEq(albumId, 'albumId')),
    sortBy(prop('weight')),
    map((photo) => ({
      url: photo.url,
      originalUrl: photo.url.replace('resize', 'get'),
      resizeUrl: photo.url,
      makeUrl: (url, width) => `${url}&width=${width}`,
      id: photo.id,
    }))
  );

export const getGridPhotosFromAudit = (auditCategories?: LoadAuditDataCategories[]) =>
  pipe(
    defaultTo<LoadAuditDataCategories[]>([]),
    chain((category: LoadAuditDataCategories) => category.assets),
    chain((audit: AuditDataAssetsBody) => audit.files),
    uniq,
    map<AuditDataAssetsFilesBody, GridPhoto>((file) => ({
      url: file.url,
      originalUrl: file.url,
      resizeUrl: file.resizeUrl,
      id: file.uploadedAssetsId,
      makeUrl: (url, width) => `${url}&width=${width}`,
    }))
  )(auditCategories);
