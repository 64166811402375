import {showNotification} from 'platform/components';
import {
  getValueByDevice,
  Show,
  useDevice,
  useIsInViewport,
  useWindowDimensions,
} from 'platform/foundation';
import {css} from 'styled-components';

import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {platformLightboxQueryParams} from '../consts/platformLightboxQueryParams';
import {LightboxAction} from '../types/LightboxAction';
import {LightboxVideo} from '../types/LightboxVideo';
import {Actions} from './Actions';
import {VideoItem} from './VideoItem';

interface SlideshowVideoProps extends RequiredTestIdProps {
  activeIndex: number;
  index: number;
  video: LightboxVideo;
  onSlideshowItemInView: () => void;
}

export function SlideshowVideo(props: SlideshowVideoProps) {
  const device = useDevice();
  const windowDimensions = useWindowDimensions();
  const {t} = useTranslation();

  const boxRef = useRef<HTMLDivElement>(null);
  const isInView = useIsInViewport(boxRef, 0.85);

  const [isHovered, onMouseEnter, onMouseLeave] = useBoolean();

  /**
   * @description
   * Whenever a image item is inView, we trigger a callback to level up,
   * which sets the pointer to this element for comparison.
   * If the pointer is not equal to the activeIndex from controls,
   * then scrollIntoView is triggered to this element
   */
  useEffect(() => {
    if (isInView && device !== 'mobile' && device !== 'tablet') {
      props.onSlideshowItemInView();
    }
  }, [device, isInView, props]);

  const actions: LightboxAction[] = [
    {
      icon: 'social/share',
      onClick: async () => {
        const url =
          window.location.href +
          `&${platformLightboxQueryParams.LIGHTBOX_DETAIL_INDEX}=${props.activeIndex}`;
        if (navigator.share) {
          try {
            await navigator.share({
              title: document.title,
              url,
            });
          } catch (err: unknown) {
            if (!(err instanceof Error && err.toString().includes('AbortError'))) {
              showNotification.error(t('platform.lightbox.share-error'));
            }
          }
        } else {
          await navigator.clipboard.writeText(url);
          showNotification.info(t('platform.lightbox.copy-to-clipboard'));
        }
      },
      id: 'shareBtn',
    },
  ];

  const handleMouseEnter = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (isNilOrEmpty(actions) || device === 'mobile' || device === 'tablet') {
      return;
    }
    onMouseLeave();
  };

  return (
    <div
      ref={boxRef}
      data-testid={suffixTestId('slideshowItem', props)}
      css={css`
        position: relative;
        height: ${getValueByDevice(device, 'auto', 'auto', `${windowDimensions.height}px`)};
        width: 100%;
        aspect-ratio: ${getValueByDevice(device, 4 / 3, 4 / 3, undefined)};
        background-color: ${({theme}) => theme.colors.palettes.neutral['10']['40']};
        border-radius: ${({theme}) => theme.radii.medium};
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Show when={isNotNilOrEmpty(actions)}>
        <div
          css={css`
            position: absolute;
            height: 100%;
            top: ${props.index === 0 ? 0 : ({theme}) => theme.getSize(4)};
            right: ${({theme}) => theme.getSize(24)};
            z-index: ${({theme}) => theme.zIndices.LIGHTBOX_ACTIONS};
          `}
        >
          <div
            css={css`
              position: sticky;
              top: ${({theme}) => theme.getSize(4)};
            `}
          >
            <Actions
              data-testid={suffixTestId('slideshowItem', props)}
              disablePosition
              zIndex="LIGHTBOX_ACTIONS"
              actions={[actions]}
              isHidden={!isHovered}
            />
          </div>
        </div>
      </Show>

      <VideoItem data-testid={suffixTestId('slideshowItem', props)} video={props.video} />
    </div>
  );
}
