import {subDays} from 'date-fns';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  isCurrency,
  showNotification,
} from 'platform/components';
import {Box, Center, Grid, Heading, Hide, HStack, Show, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useState} from 'react';
import {DeepPartial, UseFormReturn} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {always, isNil, isNotNil} from 'ramda';
import {isFalse, isFalsy, isNilOrEmpty, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {
  PricePurchaseBusinessCaseApiArg,
  PricePurchaseBusinessCaseRequestBody,
  PriceWithVatDirection,
  selectTenant,
  useFindConversionRateQuery,
  useGetBusinessCaseQuery,
  useGetBusinessCaseSettingsQuery,
  useGetCurrenciesQuery,
  useGetSaleVehicleFromSaleVehicleIdQuery,
  useLazyFindConversionRateQuery,
  usePricePurchaseBusinessCaseMutation,
  VatTypeEnum,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {
  areFormFieldsDifferent,
  CalculatePrice,
  CURRENCY_EUR,
  handleApiError,
  NoPermissionTooltip,
  useGetComplexPermissions,
  usePermissions,
  useTenant,
} from '@omnetic-dms/shared';
import {DEFAULT_COUNTRY, DEFAULT_CURRENCY, selectVatRatesByCountryCode} from '@omnetic-dms/teas';

import {buildObject, CurrencyCodeType, Nullish} from 'shared';

import {$PricingFormScheme} from './utils/$PricingFormScheme';

interface PurchaseBusinessCasePricingForm extends PricePurchaseBusinessCaseRequestBody {
  exchangeRate?: string | null;
}

interface PricingFormProps {
  toggleDetail: () => void;
  businessCaseId: string | undefined;
}

type LastTouchedDirection = 'withVat' | 'withoutVat' | undefined;

const MIN_INPUT_WIDTH = 35;

const YESTERDAY_DATE = subDays(new Date(), 1);

export function PricingForm(props: PricingFormProps) {
  const {data: businessCase} = useGetBusinessCaseQuery(
    {businessCaseId: props.businessCaseId ?? ''},
    {skip: isNil(props.businessCaseId)}
  );

  const {data: saleVehicle} = useGetSaleVehicleFromSaleVehicleIdQuery(
    {
      vehicleId: businessCase?.saleVehicleId ?? '',
    },
    {skip: isNilOrEmpty(businessCase?.saleVehicleId)}
  );
  const {
    data: currencyOptions,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrenciesQuery();

  const {tenantCurrency} = useTenant();
  const [getConversationRate] = useLazyFindConversionRateQuery();
  const {data: conversionRateApi} = useFindConversionRateQuery({
    domesticCurrency: tenantCurrency,
    foreignCurrency:
      saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode ?? CURRENCY_EUR,
  });

  const {canReadPurchasePrice} = useGetComplexPermissions();

  const firstPurchaseVehicle = businessCase?.offers?.[0]?.purchaseVehicles?.[0];

  const [isCurrencyCardOpen, setCurrencyCardOpen] = useState<boolean>(
    isNotNilOrEmpty(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat) ||
      isNotNilOrEmpty(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat)
  );
  const [canEditSalePrice, canEditPurchasePrice, canEditMaxPurchasePrice] = usePermissions({
    permissionKeys: [
      'vehicleEditSalePrice',
      'vehicleEditPurchasePrice',
      'businessCaseEditMaxPurchasePrice',
    ],
  });

  const {data: selectedTenant} = useSelector(selectTenant);
  const country = selectedTenant?.country ?? DEFAULT_COUNTRY;
  const currency = isCurrency(selectedTenant?.currency)
    ? (selectedTenant?.currency ?? DEFAULT_CURRENCY)
    : DEFAULT_CURRENCY;
  const {data: businessCaseSettingsData} = useGetBusinessCaseSettingsQuery();
  const shouldValidateMaximumBuyingPrice =
    businessCase?.businessCaseInternalType === 'BUYING' &&
    isTrue(businessCaseSettingsData?.maximalPurchasePriceRequired);

  const [lastTouchedBuyingPriceField, setLastTouchedBuyingPriceField] =
    useState<LastTouchedDirection>(
      match<PriceWithVatDirection | Nullish, LastTouchedDirection>(
        firstPurchaseVehicle?.buying?.buyingPrice?.vatDirection
      )
        .with('FROM_PRICE_WITH_VAT', always('withVat'))
        .with('FROM_PRICE_WITHOUT_VAT', always('withoutVat'))
        .otherwise(always(undefined))
    );
  const [lastTouchedSellPriceField, setLastTouchedSellPriceField] = useState<LastTouchedDirection>(
    match<PriceWithVatDirection | Nullish, LastTouchedDirection>(
      firstPurchaseVehicle?.pricing?.sellingPrice?.vatDirection
    )
      .with('FROM_PRICE_WITH_VAT', always('withVat'))
      .with('FROM_PRICE_WITHOUT_VAT', always('withoutVat'))
      .otherwise(always(undefined))
  );

  const [editPricesSimplified] = usePricePurchaseBusinessCaseMutation();

  const vatRates = useSelector(selectVatRatesByCountryCode(selectedTenant?.country))?.rates?.map(
    (vatRate) => ({
      ...vatRate,
      value: vatRate.type,
      label: `${vatRate.rate} % ${vatRate.name}`,
      fieldLabel: `${vatRate.rate}%`,
    })
  );

  const requestedPriceArgs: Omit<
    PricePurchaseBusinessCaseApiArg,
    'pricePurchaseBusinessCaseRequestBody'
  > = {
    businessCaseId: businessCase?.id ?? '',
  };

  const handleSubmit: FormSubmitHandler<PurchaseBusinessCasePricingForm> = async (data) => {
    if (isNil(businessCase) || isNil(firstPurchaseVehicle)) {
      return showNotification.error('missing business case or purchase vehicle');
    }

    const buyingVatDirection = match<[LastTouchedDirection, boolean], PriceWithVatDirection | null>(
      [
        lastTouchedBuyingPriceField,
        Boolean(data.isVatDeductible) &&
          isNotNilOrEmpty(data.expectedPurchasePrice?.priceWithoutVat?.amount),
      ]
    )
      .with(['withVat', true], always('FROM_PRICE_WITH_VAT'))
      .with(['withoutVat', true], always('FROM_PRICE_WITHOUT_VAT'))
      .with([undefined, true], always('FROM_PRICE_WITH_VAT'))
      .otherwise(always('FROM_PRICE_WITH_VAT'));

    const sellingVatDirection = match<
      [LastTouchedDirection, boolean],
      PriceWithVatDirection | null
    >([
      lastTouchedSellPriceField,
      Boolean(data.isVatDeductible) &&
        isNotNilOrEmpty(data.expectedSellingPrice?.priceWithoutVat?.amount),
    ])
      .with(['withVat', true], always('FROM_PRICE_WITH_VAT'))
      .with(['withoutVat', true], always('FROM_PRICE_WITHOUT_VAT'))
      .with([undefined, true], always('FROM_PRICE_WITH_VAT'))
      .otherwise(always('FROM_PRICE_WITH_VAT'));

    const isVatDeductibleDifferent = areFormFieldsDifferent(
      data.isVatDeductible,
      defaultValues.isVatDeductible
    );

    const isPurchasePriceDifferent = data.isVatDeductible
      ? areFormFieldsDifferent(
          data.expectedPurchasePrice?.priceWithVat?.amount?.toString(),
          defaultValues.expectedPurchasePrice?.priceWithVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedPurchasePrice?.priceWithoutVat?.amount?.toString(),
          defaultValues.expectedPurchasePrice?.priceWithoutVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedPurchasePrice?.vatType,
          defaultValues.expectedPurchasePrice?.vatType
        )
      : areFormFieldsDifferent(
          data.expectedPurchasePrice?.priceNotDeductible?.amount?.toString(),
          defaultValues.expectedPurchasePrice?.priceNotDeductible?.amount
        );

    const isSellingPriceDifferent = data.isVatDeductible
      ? areFormFieldsDifferent(
          data.expectedSellingPrice?.priceWithVat?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceWithVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedSellingPrice?.priceWithoutVat?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceWithoutVat?.amount
        ) ||
        areFormFieldsDifferent(
          data.expectedSellingPrice?.vatType,
          defaultValues.expectedSellingPrice?.vatType
        )
      : areFormFieldsDifferent(
          data.expectedSellingPrice?.priceNotDeductible?.amount?.toString(),
          defaultValues.expectedSellingPrice?.priceNotDeductible?.amount
        );

    const isMaxPurchasePriceDifferent =
      areFormFieldsDifferent(
        isNotNilOrEmpty(data.maxPurchasePrice?.amount)
          ? String(data.maxPurchasePrice!.amount)
          : undefined,
        defaultValues.maxPurchasePrice?.amount
      ) ||
      (isNotNilOrEmpty(defaultValues.maxPurchasePrice?.currency)
        ? areFormFieldsDifferent(currency, defaultValues.maxPurchasePrice!.currency)
        : false);

    const isCustomerExpectedPriceDifferent =
      areFormFieldsDifferent(
        isNotNilOrEmpty(data.customerExpectedPrice?.amount)
          ? String(data.customerExpectedPrice!.amount)
          : undefined,
        defaultValues.customerExpectedPrice?.amount
      ) ||
      (isNotNilOrEmpty(defaultValues.customerExpectedPrice?.currency)
        ? areFormFieldsDifferent(currency, defaultValues.customerExpectedPrice!.currency)
        : false);

    const isExpectedPurchaseDateDifferent = areFormFieldsDifferent(
      data.expectedPurchaseDate,
      defaultValues.expectedPurchaseDate
    );

    const isPurchasePriceInForeignCurrencyDifferent =
      areFormFieldsDifferent(
        data.purchasePriceInForeignCurrency?.foreignCurrencyCode,
        defaultValues.purchasePriceInForeignCurrency?.foreignCurrencyCode
      ) ||
      areFormFieldsDifferent(
        data.purchasePriceInForeignCurrency?.priceWithoutVat?.toString(),
        defaultValues.purchasePriceInForeignCurrency?.priceWithoutVat
      ) ||
      areFormFieldsDifferent(
        data.purchasePriceInForeignCurrency?.priceWithVat?.toString(),
        defaultValues.purchasePriceInForeignCurrency?.priceWithVat
      );

    const pricePurchaseBusinessCaseRequestBody = buildObject<PricePurchaseBusinessCaseRequestBody>()
      .isVatDeductible(data.isVatDeductible, isVatDeductibleDifferent)
      .expectedPurchasePrice(
        {
          priceNotDeductible:
            isFalse(data.isVatDeductible) &&
            isNotNilOrEmpty(data.expectedPurchasePrice?.priceNotDeductible?.amount)
              ? {
                  amount: String(data.expectedPurchasePrice!.priceNotDeductible!.amount),
                  currency,
                }
              : null,
          priceWithoutVat:
            data.isVatDeductible &&
            isNotNilOrEmpty(data.expectedPurchasePrice?.priceWithoutVat?.amount)
              ? {
                  amount: String(data.expectedPurchasePrice!.priceWithoutVat!.amount),
                  currency,
                }
              : null,
          priceWithVat:
            data.isVatDeductible &&
            isNotNilOrEmpty(data.expectedPurchasePrice?.priceWithVat?.amount)
              ? {
                  amount: String(data.expectedPurchasePrice!.priceWithVat!.amount),
                  currency,
                }
              : null,
          vatDirection: buyingVatDirection,
          vatType:
            (data.isVatDeductible ? data.expectedPurchasePrice?.vatType : VatTypeEnum.Z) ??
            VatTypeEnum.Z,
        },
        isPurchasePriceDifferent
      )
      .expectedSellingPrice(
        {
          priceNotDeductible:
            !data.isVatDeductible &&
            isNotNilOrEmpty(data.expectedSellingPrice?.priceNotDeductible?.amount)
              ? {
                  amount: String(data.expectedSellingPrice!.priceNotDeductible!.amount),
                  currency,
                }
              : null,
          priceWithoutVat:
            data.isVatDeductible &&
            isNotNilOrEmpty(data.expectedSellingPrice?.priceWithoutVat?.amount)
              ? {
                  amount: String(data.expectedSellingPrice!.priceWithoutVat!.amount),
                  currency,
                }
              : null,
          priceWithVat:
            data.isVatDeductible && isNotNilOrEmpty(data.expectedSellingPrice?.priceWithVat?.amount)
              ? {
                  amount: String(data.expectedSellingPrice!.priceWithVat!.amount),
                  currency,
                }
              : null,
          vatDirection: sellingVatDirection,
          vatType:
            (data.isVatDeductible ? data.expectedSellingPrice?.vatType : VatTypeEnum.Z) ??
            VatTypeEnum.Z,
        },
        isSellingPriceDifferent
      )
      .maxPurchasePrice(
        isNotNilOrEmpty(data.maxPurchasePrice?.amount)
          ? {amount: String(data.maxPurchasePrice!.amount), currency}
          : null,
        isMaxPurchasePriceDifferent
      )
      .updateReasonNote(data.updateReasonNote, data.updateReasonNote)
      .customerExpectedPrice(
        isNotNilOrEmpty(data.customerExpectedPrice?.amount)
          ? {amount: String(data.customerExpectedPrice!.amount), currency}
          : null,
        isCustomerExpectedPriceDifferent
      )
      .expectedPurchaseDate(
        isNotNilOrEmpty(data.expectedPurchaseDate) ? data.expectedPurchaseDate : null,
        isExpectedPurchaseDateDifferent
      )
      .purchasePriceInForeignCurrency(
        isCurrencyCardOpen
          ? {
              foreignCurrencyCode:
                data.purchasePriceInForeignCurrency?.foreignCurrencyCode ?? currency,
              priceWithoutVat:
                data.purchasePriceInForeignCurrency?.priceWithoutVat?.toString() ?? null,
              priceWithVat: data.purchasePriceInForeignCurrency?.priceWithVat?.toString() ?? null,
            }
          : null,
        isPurchasePriceInForeignCurrencyDifferent
      )
      .build();

    const partialSubmitData: PricePurchaseBusinessCaseApiArg = {
      ...requestedPriceArgs,
      pricePurchaseBusinessCaseRequestBody,
    };

    await editPricesSimplified(partialSubmitData)
      .unwrap()
      .then(() => {
        showNotification.success();
        props.toggleDetail();
      })
      .catch(handleApiError);
  };

  const defaultValues: DeepPartial<PricePurchaseBusinessCaseRequestBody> = {
    isVatDeductible: firstPurchaseVehicle?.buying?.vatDeductible ?? false,
    expectedPurchasePrice: isNotNilOrEmpty(firstPurchaseVehicle?.buying?.buyingPrice)
      ? {
          ...firstPurchaseVehicle!.buying!.buyingPrice,
          vatType: firstPurchaseVehicle!.buying!.vatType ?? VatTypeEnum.Z,
        }
      : undefined,
    expectedSellingPrice: isNotNilOrEmpty(firstPurchaseVehicle?.pricing?.sellingPrice)
      ? {
          ...firstPurchaseVehicle!.pricing!.sellingPrice,
          vatType: firstPurchaseVehicle!.pricing!.vatType ?? VatTypeEnum.Z,
        }
      : undefined,
    maxPurchasePrice: isNotNilOrEmpty(firstPurchaseVehicle?.buying?.maxBuyingPrice?.amount)
      ? {
          amount: String(firstPurchaseVehicle?.buying?.maxBuyingPrice?.amount ?? ''),
          currency: firstPurchaseVehicle?.buying?.maxBuyingPrice?.currency,
        }
      : null,
    updateReasonNote: firstPurchaseVehicle?.pricing.reason ?? null,
    customerExpectedPrice: firstPurchaseVehicle?.costs?.customerRequestedPrice,
    expectedPurchaseDate: firstPurchaseVehicle?.expectedPurchaseOn ?? null,
    purchasePriceInForeignCurrency:
      isNotNilOrEmpty(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat) ||
      isNotNilOrEmpty(saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat)
        ? {
            foreignCurrencyCode:
              saleVehicle?.purchasePriceIsForeignCurrency?.foreignCurrencyCode ?? currency,
            priceWithoutVat: saleVehicle?.purchasePriceIsForeignCurrency?.priceWithoutVat,
            priceWithVat: saleVehicle?.purchasePriceIsForeignCurrency?.priceWithVat,
          }
        : {
            foreignCurrencyCode: CURRENCY_EUR,
            priceWithoutVat: null,
            priceWithVat: null,
          },
  };

  const handleCalculationChange =
    (
      formApi: UseFormReturn<PurchaseBusinessCasePricingForm>,
      name: 'expectedPurchasePrice' | 'expectedSellingPrice' | 'purchasePriceInForeignCurrency'
    ) =>
    (res: {withVat?: string} | {withoutVat?: string}) => {
      if ('withVat' in res) {
        if (name === 'purchasePriceInForeignCurrency') {
          formApi.setValue(`${name}.priceWithVat`, res?.withVat ?? '');
        } else {
          formApi.setValue(`${name}.priceWithVat.amount`, res?.withVat ?? '');
        }
      }
      if ('withoutVat' in res) {
        if (name === 'purchasePriceInForeignCurrency') {
          formApi.setValue(`${name}.priceWithoutVat`, res?.withoutVat ?? '');
        } else {
          formApi.setValue(`${name}.priceWithoutVat.amount`, res?.withoutVat ?? '');
        }
      }
    };

  const handleCalculatePriceFromForeignCurrency = (
    formApi: UseFormReturn<PurchaseBusinessCasePricingForm>,
    name: 'WithoutVat' | 'WithVat'
  ) => {
    if (isFalsy(formApi.getValues('isVatDeductible'))) {
      formApi.setValue(
        'expectedPurchasePrice.priceNotDeductible.amount',
        isNotNilOrEmpty(formApi.getValues(`purchasePriceInForeignCurrency.price${name}`))
          ? (
              parseFloat(formApi.getValues(`purchasePriceInForeignCurrency.price${name}`) ?? '0') *
              parseFloat(formApi.getValues('exchangeRate') ?? '0')
            )
              .toFixed(2)
              .toString()
          : ''
      );
    } else {
      formApi.setValue(
        `expectedPurchasePrice.price${name}.amount`,
        isNotNilOrEmpty(formApi.getValues(`purchasePriceInForeignCurrency.price${name}`))
          ? (
              parseFloat(formApi.getValues(`purchasePriceInForeignCurrency.price${name}`) ?? '0') *
              parseFloat(formApi.getValues('exchangeRate') ?? '0')
            )
              .toFixed(2)
              .toString()
          : ''
      );
    }
  };

  const syncDeductiblePricesChange = (formApi: UseFormReturn<PurchaseBusinessCasePricingForm>) => {
    const isDeductible = formApi.getValues('isVatDeductible');

    if (isDeductible) {
      formApi.setValue(
        'expectedSellingPrice.priceWithVat.amount',
        formApi.getValues('expectedSellingPrice.priceNotDeductible.amount')
      );
      formApi.setValue(
        'expectedPurchasePrice.priceWithVat.amount',
        formApi.getValues('expectedPurchasePrice.priceNotDeductible.amount')
      );
      formApi.setValue('expectedPurchasePrice.vatType', VatTypeEnum.S);
      formApi.setValue('expectedSellingPrice.vatType', VatTypeEnum.S);
      setLastTouchedSellPriceField('withVat');
      setLastTouchedBuyingPriceField('withVat');
    } else {
      formApi.setValue(
        'expectedSellingPrice.priceNotDeductible.amount',
        formApi.getValues('expectedSellingPrice.priceWithVat.amount')
      );
      formApi.setValue(
        'expectedPurchasePrice.priceNotDeductible.amount',
        formApi.getValues('expectedPurchasePrice.priceWithVat.amount')
      );
      setLastTouchedSellPriceField('withoutVat');
      setLastTouchedBuyingPriceField('withoutVat');
    }
  };

  const handleChangeCurrencyCode = (
    data: string | number | null,
    formApi: UseFormReturn<PurchaseBusinessCasePricingForm>
  ) => {
    isNotNil(data)
      ? getConversationRate({
          domesticCurrency: tenantCurrency,
          foreignCurrency: String(data),
        })
          .unwrap()
          .then((exchangeRate) => {
            formApi.setValue('exchangeRate', exchangeRate?.ratio);
            handleCalculatePriceFromForeignCurrency(
              formApi,
              lastTouchedBuyingPriceField === 'withoutVat' ||
                lastTouchedBuyingPriceField === 'withVat'
                ? ((lastTouchedBuyingPriceField!.charAt(0).toUpperCase() +
                    lastTouchedBuyingPriceField!.slice(1)) as 'WithoutVat' | 'WithVat')
                : 'WithoutVat'
            );
          })
          .catch(handleApiError)
      : formApi.setValue('exchangeRate', null);
  };

  return (
    <Form<PurchaseBusinessCasePricingForm>
      onSubmit={handleSubmit}
      defaultValues={{...defaultValues, exchangeRate: conversionRateApi?.ratio}}
      schema={$PricingFormScheme(Boolean(firstPurchaseVehicle?.costs?.customerRequestedPrice))}
    >
      {(control, formApi) => {
        const [
          isVatDeductible,
          expectedPurchaseVatType,
          expectedSellingVatType,
          buyingPriceWithoutVat,
          buyingPriceWithVat,
          sellingPriceWithoutVat,
          sellingPriceWithVat,
          maxBuyingPrice,
          buyingPriceNotDeductible,
          foreignPuchasePriceWithVat,
          foreignPuchasePriceWithoutVat,
        ] = formApi.watch([
          'isVatDeductible',
          'expectedPurchasePrice.vatType',
          'expectedSellingPrice.vatType',
          'expectedPurchasePrice.priceWithoutVat.amount',
          'expectedPurchasePrice.priceWithVat.amount',
          'expectedSellingPrice.priceWithoutVat.amount',
          'expectedSellingPrice.priceWithVat.amount',
          'maxPurchasePrice.amount',
          'expectedPurchasePrice.priceNotDeductible.amount',
          'purchasePriceInForeignCurrency.priceWithVat',
          'purchasePriceInForeignCurrency.priceWithoutVat',
        ]);

        const shouldShowMaximumPriceError =
          shouldValidateMaximumBuyingPrice &&
          isNotNil(maxBuyingPrice) &&
          (isVatDeductible
            ? maxBuyingPrice < buyingPriceWithVat
            : maxBuyingPrice < buyingPriceNotDeductible);

        if (
          isNotNilOrEmpty(conversionRateApi?.ratio) &&
          isNilOrEmpty(formApi.getValues('exchangeRate')) &&
          isNotNilOrEmpty(formApi.getValues('purchasePriceInForeignCurrency.foreignCurrencyCode'))
        ) {
          formApi.setValue('exchangeRate', conversionRateApi?.ratio);
        }

        return (
          <VStack spacing={4}>
            <Heading size={4}>{i18n.t('entity.businessCase.labels.buying')}</Heading>
            <HStack spacing={4}>
              <Box flexGrow={1} width={MIN_INPUT_WIDTH}>
                <Center justify="flex-start" height={8}>
                  <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                    <FormField
                      control={control}
                      name="isVatDeductible"
                      type="checkbox"
                      label={i18n.t('general.labels.vatDeductible')}
                      data-testid={testIds.businessCase.buying('pricing-vatDeductible')}
                      onChange={() => syncDeductiblePricesChange(formApi)}
                      isDisabled={isFalse(canEditPurchasePrice)}
                    />
                  </NoPermissionTooltip>
                </Center>
              </Box>
            </HStack>
            <Grid columns={2}>
              <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                <FormField
                  control={control}
                  type="currency"
                  currency={currency}
                  decimalPlaces={2}
                  name="customerExpectedPrice.amount"
                  label={i18n.t('entity.businessCase.labels.customerRequestedPrice')}
                  data-testid={testIds.businessCase.buying('pricing-customerRequestedPrice')}
                  isDisabled={isFalse(canEditPurchasePrice)}
                />
              </NoPermissionTooltip>
              <FormField
                control={control}
                type="apiDate"
                name="expectedPurchaseDate"
                label={i18n.t('entity.businessCase.labels.expectedPurchaseOn')}
                minDate={YESTERDAY_DATE}
                data-testid={testIds.businessCase.selling('pricing-expectedPurchaseOn')}
              />
            </Grid>
            <Grid columns={2}>
              <Hide when={isVatDeductible}>
                <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                  <FormField
                    control={control}
                    name="expectedPurchasePrice.priceNotDeductible.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.buyingPrice')}
                    onChange={() => setLastTouchedBuyingPriceField('withoutVat')}
                    data-testid={testIds.businessCase.buying('pricing-buyingPrice')}
                    isDisabled={isFalse(canEditPurchasePrice)}
                  />
                </NoPermissionTooltip>
              </Hide>
              <Show when={isVatDeductible}>
                {expectedPurchaseVatType && (
                  <CalculatePrice
                    amount={
                      lastTouchedBuyingPriceField === 'withVat'
                        ? buyingPriceWithVat
                        : buyingPriceWithoutVat
                    }
                    countryCode={country}
                    type={lastTouchedBuyingPriceField}
                    vatCode={expectedPurchaseVatType}
                    onChange={handleCalculationChange(formApi, 'expectedPurchasePrice')}
                  />
                )}
                <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                  <FormField
                    control={control}
                    name="expectedPurchasePrice.priceWithoutVat.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.purchasePriceWithoutVat')}
                    onChange={() => setLastTouchedBuyingPriceField('withoutVat')}
                    data-testid={testIds.businessCase.buying('pricing-buyingPrice-priceWithoutVat')}
                    isDisabled={isFalse(canEditPurchasePrice)}
                  />
                </NoPermissionTooltip>
                <HStack spacing={4}>
                  <Box width={18} minWidth={18} maxWidth={18}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        control={control}
                        options={vatRates}
                        name="expectedPurchasePrice.vatType"
                        type="choice"
                        label={i18n.t('general.labels.vat')}
                        placeholder={i18n.t('general.labels.select')}
                        data-testid={testIds.businessCase.buying('pricing-buying-vatType')}
                        isDisabled={isFalse(canEditPurchasePrice)}
                        isNotClearable
                      />
                    </NoPermissionTooltip>
                  </Box>
                  <Box flexGrow={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditPurchasePrice)}>
                      <FormField
                        control={control}
                        name="expectedPurchasePrice.priceWithVat.amount"
                        type="currency"
                        currency={currency}
                        decimalPlaces={2}
                        label={i18n.t('general.labels.buyingPrice')}
                        onChange={() => setLastTouchedBuyingPriceField('withVat')}
                        data-testid={testIds.businessCase.buying(
                          'pricing-buyingPrice-priceWithVat'
                        )}
                        isDisabled={isFalse(canEditPurchasePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </HStack>
              </Show>
            </Grid>
            <FormField
              name="updateReasonNote"
              type="textarea"
              control={control}
              label={i18n.t('general.labels.buyingPriceReason')}
              data-testid={testIds.businessCase.buying('pricing-reason')}
            />

            <Show when={canReadPurchasePrice}>
              <DataStatus isLoading={isCurrenciesLoading} isError={isCurrenciesError}>
                <Card
                  variant="inlineGrey"
                  isExpanded={isCurrencyCardOpen}
                  control={{
                    type: 'switch',
                    value: isCurrencyCardOpen,
                    onChange: () => setCurrencyCardOpen(!isCurrencyCardOpen),
                    'data-testid': testIds.businessCase.buying('vehicle-foreign-price'),
                  }}
                  title={i18n.t('page.vehicle.labels.foreignCurrencyPrices')}
                >
                  <Grid columns={2}>
                    {isNotNil(expectedPurchaseVatType) && (
                      <CalculatePrice
                        amount={
                          lastTouchedBuyingPriceField === 'withVat'
                            ? (foreignPuchasePriceWithVat ?? '')
                            : (foreignPuchasePriceWithoutVat ?? '')
                        }
                        countryCode={country}
                        type={lastTouchedBuyingPriceField}
                        vatCode={expectedPurchaseVatType}
                        onChange={handleCalculationChange(
                          formApi,
                          'purchasePriceInForeignCurrency'
                        )}
                      />
                    )}
                    <FormField
                      data-testid={testIds.businessCase.buying('purchaseCurrency')}
                      type="choice"
                      options={currencyOptions
                        ?.filter((option) => option.code !== tenantCurrency)
                        .map((option) => ({value: option.code, label: option.code}))}
                      control={control}
                      name="purchasePriceInForeignCurrency.foreignCurrencyCode"
                      label={i18n.t('page.vehicle.labels.buyingCurrency')}
                      onChange={(data) => handleChangeCurrencyCode(data, formApi)}
                    />
                    <FormField
                      control={control}
                      name="exchangeRate"
                      type="currency"
                      currency={
                        formApi.watch(
                          'purchasePriceInForeignCurrency.foreignCurrencyCode'
                        ) as CurrencyCodeType
                      }
                      label={i18n.t('entity.businessCase.labels.actualExchangeRate')}
                      data-testid={testIds.businessCase.buying('exchangeRate')}
                    />
                    <FormField
                      data-testid={testIds.businessCase.buying(
                        'currencyPurchasePriceAmountWithoutVat'
                      )}
                      type="currency"
                      currency={
                        formApi.watch(
                          'purchasePriceInForeignCurrency.foreignCurrencyCode'
                        ) as CurrencyCodeType
                      }
                      decimalPlaces={2}
                      control={control}
                      name="purchasePriceInForeignCurrency.priceWithoutVat"
                      label={i18n.t('page.vehicle.labels.buyingForeignPriceWithoutVat')}
                      onChange={() => {
                        setLastTouchedBuyingPriceField('withoutVat');
                        handleCalculatePriceFromForeignCurrency(formApi, 'WithoutVat');
                      }}
                    />
                    <FormField
                      data-testid={testIds.businessCase.buying(
                        'currencyPurchasePriceAmountWithVat'
                      )}
                      type="currency"
                      currency={
                        formApi.watch(
                          'purchasePriceInForeignCurrency.foreignCurrencyCode'
                        ) as CurrencyCodeType
                      }
                      decimalPlaces={2}
                      control={control}
                      name="purchasePriceInForeignCurrency.priceWithVat"
                      label={i18n.t('page.vehicle.labels.buyingForeignPriceWithVat')}
                      onChange={() => {
                        setLastTouchedBuyingPriceField('withVat');
                        handleCalculatePriceFromForeignCurrency(formApi, 'WithVat');
                      }}
                    />
                  </Grid>
                </Card>
              </DataStatus>
            </Show>

            <Heading size={4}>{i18n.t('page.businessCase.labels.pricing')}</Heading>
            <Grid columns={2}>
              <NoPermissionTooltip shouldShowTooltip={isFalsy(canEditMaxPurchasePrice)}>
                <FormField
                  name="maxPurchasePrice.amount"
                  label={i18n.t('entity.businessCase.labels.maxBuyingPrice')}
                  control={control}
                  type="currency"
                  currency={currency}
                  data-testid={testIds.businessCase.buying('pricing-maxBuyingPrice')}
                  isDisabled={isFalsy(canEditMaxPurchasePrice)}
                />
              </NoPermissionTooltip>
            </Grid>
            <Grid columns={2}>
              <Hide when={isVatDeductible}>
                <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                  <FormField
                    control={control}
                    name="expectedSellingPrice.priceNotDeductible.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.sellingPrice')}
                    onChange={() => setLastTouchedSellPriceField('withoutVat')}
                    data-testid={testIds.businessCase.buying('pricing-sellingPrice')}
                    isDisabled={isFalse(canEditSalePrice)}
                  />
                </NoPermissionTooltip>
              </Hide>
              <Show when={isVatDeductible}>
                {expectedSellingVatType && (
                  <CalculatePrice
                    amount={
                      lastTouchedSellPriceField === 'withVat'
                        ? sellingPriceWithVat
                        : sellingPriceWithoutVat
                    }
                    countryCode={country}
                    type={lastTouchedSellPriceField}
                    vatCode={expectedSellingVatType}
                    onChange={handleCalculationChange(formApi, 'expectedSellingPrice')}
                  />
                )}
                <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                  <FormField
                    control={control}
                    name="expectedSellingPrice.priceWithoutVat.amount"
                    type="currency"
                    currency={currency}
                    decimalPlaces={2}
                    label={i18n.t('general.labels.sellingPriceWithoutVat')}
                    onChange={() => setLastTouchedSellPriceField('withoutVat')}
                    data-testid={testIds.businessCase.buying(
                      'pricing-sellingPrice-priceWithoutVat'
                    )}
                    isDisabled={isFalse(canEditSalePrice)}
                  />
                </NoPermissionTooltip>
                <HStack spacing={4}>
                  <Box width={18} minWidth={18} maxWidth={18}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                      <FormField
                        control={control}
                        options={vatRates}
                        name="expectedSellingPrice.vatType"
                        type="choice"
                        label={i18n.t('entity.vehicle.labels.vat')}
                        placeholder={i18n.t('general.labels.select')}
                        data-testid={testIds.businessCase.buying('pricing-selling-vatType')}
                        isDisabled={isFalse(canEditPurchasePrice)}
                        isNotClearable
                      />
                    </NoPermissionTooltip>
                  </Box>
                  <Box flexGrow={1}>
                    <NoPermissionTooltip shouldShowTooltip={isFalse(canEditSalePrice)}>
                      <FormField
                        control={control}
                        name="expectedSellingPrice.priceWithVat.amount"
                        type="currency"
                        currency={currency}
                        decimalPlaces={2}
                        label={i18n.t('general.labels.sellingPrice')}
                        onChange={() => setLastTouchedSellPriceField('withVat')}
                        data-testid={testIds.businessCase.buying(
                          'pricing-sellingPrice-priceWithVat'
                        )}
                        isDisabled={isFalse(canEditSalePrice)}
                      />
                    </NoPermissionTooltip>
                  </Box>
                </HStack>
              </Show>
            </Grid>
            <Show when={shouldShowMaximumPriceError}>
              <Alert
                type="banner"
                variant="error"
                title={i18n.t('page.businessCase.labels.maximumPrice.overLimit')}
                data-testid={testIds.businessCase.buying('pricing-overLimitAlert')}
              />
            </Show>
            <ButtonGroup align="right">
              <Button
                onClick={props.toggleDetail}
                title={i18n.t('general.labels.dismiss')}
                variant="secondary"
                data-testid={testIds.businessCase.buying('pricing-dismissForm')}
              />
              <FormButton
                title={i18n.t('general.actions.save')}
                control={control}
                type="submit"
                data-testid={testIds.businessCase.buying('pricing-submitForm')}
              />
            </ButtonGroup>
          </VStack>
        );
      }}
    </Form>
  );
}
